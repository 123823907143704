<template>
  <div class="d-block">
    <div class="d-flex flex-sm-row flex-column justify-space-between pa-4 pa-md-6"
    :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : 'align-start'" style="gap: 1.5rem">
      <div class="d-flex flex-sm-row flex-column align-center" 
      :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : 'align-start'" style="gap: 1.5rem">
        <v-avatar class="blue" rounded size="40">
          <v-icon dark> mdi-stairs-down </v-icon>
        </v-avatar>

        <div class="d-flex flex-column">
          <small class="grey--text">TOTAL</small>
          <span class="font-weight-bold">
            {{ `Php ${money_format(parseFloat(total_commission))}` }}
          </span>
        </div>

        <div class="d-flex flex-column">
          <small class="grey--text">SELECTED</small>
          <span class="font-weight-bold">
            {{ `Php ${money_format(parseFloat(total_selected))}` }}
          </span>
        </div>

        <!-- <h3 class="d-flex align-center pa-0" style="gap: 1.5rem">
          <v-avatar class="blue" rounded size="40">
            <v-icon dark> mdi-hand-coin-outline </v-icon>
          </v-avatar>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="font-weight-bold">
                {{ `Php ${money_format(parseFloat(total_master_agent_sub_agent_commission))}` }}
              </span>
            </template>
            <span>Your commission total amount</span>
          </v-tooltip>
        </h3> -->
      </div>

      <div class="d-inline-flex align-center ms-auto" style="gap: 1rem">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="doGetMyCommission">
              <v-icon>
                mdi-cached
              </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>

        <v-text-field v-model="search" label="Search" single-line outlined dense clearable
          :disabled="records_loading || !records.length" autofocus placeholder="Search" hide-details="auto" />

        <v-btn icon @click="set_filter_dialog">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </div>
    </div>

    <v-divider></v-divider>

    <v-data-table v-model="selected" :headers="headers" :items="records" :items-per-page="5" :loading="records_loading"
      :hide-default-footer="records && records.length <= 5 ? true : false" loading-text="Loading, Please wait"
      :search="search" item-key="created_at" show-select>

      <template v-slot:[`item.bet_count`]="{ item }">
        {{ item.bet_count }}
      </template>

      <template v-slot:[`item.bet_amount`]="{ item }">
        {{ `Php ${money_format(parseFloat(item.bet_amount))}` }}
      </template>

      <template v-slot:[`item.commission_percentage`]="{ item }">
        {{ item.commission_percentage + "%" }}
      </template>

      <template v-slot:[`item.total_commission_amount`]="{ item }">
        <span class="font-weight-bold">
          {{ `Php ${money_format(parseFloat(item.total_commission_amount))}` }}
        </span>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <span class="text-uppercase">
          {{ date_format(item.created_at) }}
        </span>
      </template>
    </v-data-table>
    <div v-if="records">
      <div class="d-flex justify-end pa-4 pa-md-6">
        <v-btn :disabled="selected.length == 0" class="primary" depressed @click="confirmation_dialog = true">
          Request
        </v-btn>
      </div>
    </div>

    <DateFilter v-if="filter_dialog" :dates="dates" @set_close_date_filter_dialog="set_close_date_filter_dialog" />
    <MyCommissionConfirmation v-if="confirmation_dialog" :records="selected"
      @set_close_confirmation_dialog="set_close_confirmation_dialog" />
  </div>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import DateFilter from '@/components/app/approve_commissions/tab_items/DateFilter'
import MyCommissionConfirmation from '@/components/app/approve_commissions/tab_items/MyCommissionConfirmation'
import formatDate from "@/helpers/formatDatev2"

export default {
  components: { DateFilter, MyCommissionConfirmation },

  data: () => ({
    records: [],
    records_loading: false,
    search: "",
    selected: [],
    dates: {},
    filter_dialog: false,
    confirmation_dialog: false,
    date_from: null,
    date_to: null,
    headers: [
      { text: "Bet Count", value: "bet_count", class: "text-uppercase", align: "right" },
      {
        text: "Bet Amount",
        value: "bet_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Commission %",
        value: "commission_percentage",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Commission Amount",
        value: "total_commission_amount",
        class: "text-uppercase",
        align: "right",
      },
      { text: "Bet Date", value: "created_at", class: "text-uppercase" },
    ],
  }),

  computed: {
    total_commission() {
      if (this.records) {
        return this.records.reduce(
          (acc, curr) => acc + parseFloat(curr.total_commission_amount), 0
        );
      }

      return 0;
    },

    total_selected() {
      if (this.selected) {
        return this.selected.reduce(
          (acc, curr) => acc + parseFloat(curr.total_commission_amount), 0
        );
      }

      return 0;
    },
  },

  mounted() {
    this.doGetMyCommission()
  },

  methods: {
    async doGetMyCommission() {
      this.records_loading = true;
      this.records = []

      const form_data = new FormData();

      form_data.append("date_from", (this.date_from) ?? '');
      form_data.append("date_to", (this.date_to) ?? (this.date_from) ?? '');

      const res = await responseGet(
        new URLSearchParams(form_data),
        "commission/master_agent"
      );

      switch (res.status) {
        case 200:
          this.records = res.data.commissions
          break
        case 401:
          setTimeout(() => {
            localStorage.removeItem("userToken");
            this.$router.push({ name: "Home" });
            location.reload();
          }, 3000)
          break
      }

      this.records_loading = false;
    },

    set_filter_dialog() {
      this.dates = {
        'date_from': this.date_from,
        'date_to': this.date_to
      }

      this.filter_dialog = true
    },

    set_close_date_filter_dialog(data) {
      if (Object.keys(data).length) {
        this.date_from = data.date_from
        this.date_to = data.date_to

        this.doGetMyCommission()
      }

      this.filter_dialog = !this.filter_dialog
    },

    set_close_confirmation_dialog(status) {
      if (status) {
        this.selected = []
        this.doGetMyCommission()
      }
      this.confirmation_dialog = !this.confirmation_dialog
    },

    money_format(val) {
      return val.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    date_format(date) {
      return formatDate(date, "datetime");
    },
  }
}
</script>