<template>
  <v-card class="shadow">
    <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
      Token Movements
    </v-card-title>
    <!-- appData.account_details.investor_type_id == 1 ? 'height:1283px' : 'height:1109px' -->
    <!-- :style="$vuetify.breakpoint.mdAndUp ? appData.account_details.investor_type_id == 1 ? 'height:1283px' : 'height:1109px' : 'height:300px'" -->
    <v-card-text>
      <div v-if="!records_loading && appData">
        <div v-if="records.length" class="d-flex flex-column pb-1 pr-2" style="gap:.60rem; overflow-x:auto;">
          <div v-for="(record, index) in records" :key="index" class="rounded d-flex flex-column pa-4"
            style="border-width:1px; border-style:solid; border-color:#ECEFF1; gap:.30rem">
            <span class="d-block font-weight-bold py-0 ma-0" style="text-transform:uppercase;">{{ `${record.name} ` }}
              <small>{{ `( ${record.type} )`
              }}</small></span>
            <v-divider></v-divider>
            <span class="d-block font-weight-regular py-0 ma-0">{{ `Php ${money_format(parseFloat(record.token))}` }}</span>
            <span class="d-block font-weight-regular py-0 ma-0">{{ format_date(record.created_at) }}</span>
            <span v-if="record.processed_by" class="d-block font-weight-regular py-0 ma-0"
              style="text-transform:uppercase;">{{ record.processed_by }}</span>
          </div>
        </div>

        <div v-else>
          <div class="d-flex flex-column">
            <span class="font-weight-regular">
              <v-icon class="mr-1">
                mdi-database-off-outline
              </v-icon>
              
              No transaction available.
            </span>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="d-flex flex-column py-2" style="gap:.60rem">
          <div v-for="i in 4" :key="i">
            <v-skeleton-loader class="mx-1" type="text" light height="20px"></v-skeleton-loader>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDate from '@/helpers/formatDate'
import responseGet from '@/helpers/api_request_get'
import { mapState } from 'vuex'

export default {
  data: () => ({
    records: [],
    records_loading: false,
    colors: ['cyan', 'green', 'amber', 'purple', 'blue']
  }),

  mounted() {
    this.doGetMoneyMovements()
  },

  computed: {
    ...mapState(["appData", "appDataLoading"])
  },

  methods: {
    async doGetMoneyMovements() {
      this.records_loading = true

      const response = await responseGet('', 'money_movements')

      if (response.status == 200) {
        this.records = response.data.records
      } else if (response.status == 401) {
        localStorage.removeItem("userToken")
        this.$router.push({ name: "Home" })
        location.reload()
      }

      this.records_loading = false
    },

    get_random_index() {
      return Math.floor((Math.random() * 5));
    },

    format_date(date) {
      return formatDate(date)
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
  }
}
</script>