var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"d-flex justify-space-between text-h6 font-weight-bold"},[_vm._v(" Token Movements ")]),_c('div',{staticClass:"filter-container d-flex flex-column flex-sm-row align-center justify-space-between pa-4",staticStyle:{"border-bottom":"1px solid transparent"}},[_c('div',{staticClass:"input-wrapper"},[_c('v-select',{attrs:{"hide-details":"","dense":"","items":_vm.filter_option,"label":"Filter","outlined":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('div',{staticClass:"input-wrapper"},[(_vm.token_movements.length != 0)?_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.token_movements,"items-per-page":5,"search":_vm.search,"loading":_vm.loading_token_movements,"loading-text":"Loading ...","no-data-text":'No records found ' + _vm.no_data_text},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.processed_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.processed_by)+" ")]}},{key:"item.token",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money_format(parseFloat(item.token)))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")]}}],null,true)})],1),(_vm.dialog)?_c('FilterTokenMovementsDialog',{attrs:{"validation_error":_vm.validation_error},on:{"close_filter_dialog":_vm.close_filter_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }