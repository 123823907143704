<template>
  <v-card class="shadow">
    <v-card-title class="d-flex justify-space-between align-end text-h6 font-weight-bold">
      <span>Monthly Bets & Commissions</span>

      <v-btn text small class="text-capitalize font-weight-regular px-2 grey--text text--darken-2"
        @click="filter_dialog = true" :disabled="bets_and_commissions_loading" v-if="bets_and_commissions">
        {{ filter_label }}
        <v-icon small class="ps-1">mdi-chevron-down</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text v-if="!bets_and_commissions_loading">
      <div v-if="bets_and_commissions">
        <BetsAndCommissionMonthly :theChartData="bets_and_commissions" />
      </div>

      <div v-else>
        <div class="d-flex flex-column align-center">
          <v-icon>
            mdi-database-off-outline
          </v-icon>

          <span class="text-h6 font-weight-regular mt-2">
            No transaction available.
          </span>
        </div>
      </div>
    </v-card-text>

    <div v-else class="d-flex justify-center align-center pb-4" style="gap:.50rem; height: 200px;">
      <v-progress-circular :size="20" :width="3" color="grey" indeterminate></v-progress-circular>
      <span>Fetching information...</span>
    </div>

    <v-dialog ref="filter_dialog" v-model="filter_dialog" persistent width="290px">
      <v-date-picker v-model="year" :active-picker.sync="active_picker" full-width reactive no-title
        @click:year="saveFilter">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelFilter">
          Cancel
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-card>
</template>

<script>
import responseGet from "@/helpers/api_request_get"
import formatDatev2 from "@/helpers/formatDatev2"
import BetsAndCommissionMonthly from '@/components/app/dashboard/BetsAndCommissionMonthlyBarGraph'

export default {
  components: { BetsAndCommissionMonthly },

  data: () => ({
    year: formatDatev2((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 'format', 'yyyy'),
    bets_and_commissions: null,
    bets_and_commissions_loading: false,
    filter_label: null,
    filter_dialog: false,
    active_picker: null
  }),

  mounted() {
    this.getBetsAndCommission()
  },

  methods: {
    async getBetsAndCommission() {
      this.bets_and_commissions_loading = true

      const response = await responseGet(this.setQueryString(), 'dashboard/bets_and_commissions')

      if (response.status == 200) {
        this.bets_and_commissions = {
          'data_labels': response.data.labels,
          'data_bets_statistics': response.data.bets_statistics,
          'data_commissions_statistics': response.data.commission_statistics
        }

        this.filter_label = response.data.label
      } else if (response.status == 401) {
        localStorage.removeItem("userToken")
        this.$router.push({ name: "Home" })
        location.reload()
      }

      this.bets_and_commissions_loading = false
    },

    setQueryString() {
      const formData = new FormData()

      formData.append('type', 'Monthly')
      formData.append('val', this.year)

      const query_string = new URLSearchParams(formData)

      return query_string
    },

    cancelFilter() {
      this.filter_dialog = false
    },


    saveFilter() {
      const expYear = this.year.split("-")
      this.year = expYear[0]
      this.$refs.filter_dialog.save(this.year)
      this.getBetsAndCommission()
    },
  },

  watch: {
    filter_dialog(val) {
      val && setTimeout(() => (this.active_picker = 'YEAR'))
    }
  }
}
</script>