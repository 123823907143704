<template>
  <v-card class="shadow">
    <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
      <span>Sales Statistics</span>

      <v-btn text small class="text-capitalize font-weight-regular px-2 grey--text text--darken-2"
        @click="filter_dialog = true" :disabled="records_loading">
        {{ records.label }}
        <v-icon small class="ps-1">mdi-chevron-down</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div v-if="!records_loading">
        <SalesStatisticsLineChart :theChartData="records" />
      </div>

      <div v-else class="d-flex justify-center align-center" style="gap:.50rem; height: 258px;">
        <v-progress-circular :size="20" :width="3" color="grey" indeterminate></v-progress-circular>
        <span>Fetching information...</span>
      </div>
    </v-card-text>

    <v-dialog ref="filter_dialog" v-model="filter_dialog" :return-value.sync="month" persistent width="290px">
      <v-date-picker v-model="month" full-width type="month">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelFilter">
          Cancel
        </v-btn>

        <v-btn text color="primary" @click="saveFilter">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-card>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import formatDatev2 from "@/helpers/formatDatev2"
import SalesStatisticsLineChart from "@/components/app/dashboard/SalesStatisticsLineChart"

export default {
  components: { SalesStatisticsLineChart },

  data: () => ({
    records: [],
    records_loading: false,
    month: formatDatev2((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 'format', 'yyyy-MM'),
    bets_and_commissions: null,
    bets_and_commissions_loading: false,
    filter_label: null,
    filter_dialog: false
  }),

  mounted() {
    this.doGetSalesStatistics()
  },

  methods: {
    async doGetSalesStatistics() {
      this.records_loading = !this.records_loading

      const response = await responseGet(this.set_form_data(), 'sales/statistics')

      switch (response.status) {
        case 200:
          this.records = response.data
          break
        case 401:
          localStorage.removeItem("userToken")
          this.$router.push({ name: "Home" })
          location.reload()
          break
      }

      this.records_loading = !this.records_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('val', this.month)

      return new URLSearchParams(form_data)
    },

    cancelFilter() {
      this.filter_dialog = false
    },

    saveFilter() {
      this.$refs.filter_dialog.save(this.month)
      this.doGetSalesStatistics()
    },
  }
}
</script>