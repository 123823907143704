<template>
  <v-card class="shadow">
    <v-card-title class="d-flex justify-center text-h6 font-weight-bold">
      <span>SALES MANAGER LINK</span>
    </v-card-title>

    <v-card-text class="d-flex flex-column" style="gap: 0.75rem">
      <QRCodeVue :value="url_link" size="100" level="H" />

      <v-text-field
        ref="commission"
        v-model="sub_agent_commission"
        outlined
        dense
        readonly
        label="Commission"
        placeholder="0.00"
        hide-details="auto"
        suffix="%"
      >
        <template v-slot:append>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                class="mb-2"
                @click="set_commission_dialog"
              >
                <v-icon small color="#64B5F6"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template>
      </v-text-field>

      <v-text-field
        ref="url_link"
        v-model="url_link"
        outlined
        dense
        readonly
        label="Referral Link"
        hide-details="auto"
      >
        <template v-slot:append>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="copy_referral_link"
                class="mb-2"
              >
                <v-icon small :color="click_status ? 'green' : 'grey'">
                  {{ `mdi-${click_status ? "check" : "content-copy"}` }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ click_status ? "Copied!" : "Copy" }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </v-card-text>

    <EditCommission
      v-if="edit_commission_dialog"
      :commission_data="commission_data"
      @set_close_commission_dialog="set_close_commission_dialog"
    />
  </v-card>
</template>

<script>
import Hashids from "hashids";
import QRCodeVue from "qrcode.vue";
import { mapState } from "vuex";
import EditCommission from "@/components/app/referral_links/EditCommission";

export default {
  components: { QRCodeVue, EditCommission },

  props: {
    referral_link: {
      type: Array,
    },
  },

  data: () => ({
    url_link: "",
    click_status: false,
    sub_agent_commission: null,
    commission_data: {},
    edit_commission_dialog: false,
  }),

  mounted() {
    this.set_link();
  },

  computed: {
    ...mapState(["appData"]),
  },

  methods: {
    set_link() {
      if (this.appData) {
        const hashids = new Hashids("referral_code", 5); // pad to length 5
        // for players portal
        // const referral_code = `${hashids.encode(1)}/${hashids.encode(2)}/${hashids.encode(this.appData.account_details.id)}`

        // for partners portal
        // const referral_code = `${hashids.encode(2)}/${hashids.encode(this.appData.account_details.id)}`

        // this.url_link = `https://partners.gamexsports.com.ph/register/${referral_code}`

  const referral_code =
          this.referral_link.length == 0 || this.referral_link[0].link_setup == "Old"
            ? `${hashids.encode(2)}/${hashids.encode(this.appData.account_details.id)}`
            : this.referral_link[0].referral_link;

        this.url_link = `https://partners.gamexsports.com.ph/register/${referral_code}`;
        this.sub_agent_commission = this.appData.account_details.sub_commission;
      }
    },

    copy_referral_link() {
      if (!this.click_status) {
        this.click_status = true;
        let textToCopy = this.$refs.url_link.$el.querySelector("input");
        textToCopy.select();
        document.execCommand("copy");
      }
    },

    set_commission_dialog() {
      this.commission_data = {
        commission: this.sub_agent_commission,
        type: "Sales Manager",
        request_type: "sub_commission",
      };

      this.edit_commission_dialog = true;
    },

    set_close_commission_dialog() {
      this.edit_commission_dialog = false;
      this.$emit("refresh_link");
    },
  },

  watch: {
    click_status(val) {
      if (val) {
        setTimeout(() => {
          this.click_status = false;
        }, 2000);
      }
    },

    // appData(val) {
    //   if (val) this.set_link()
    // }
  },
};
</script>
