<template>
  <v-dialog v-model="dialog" max-width="1100">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        <span>{{ this.module_header }}</span>

        <v-btn plain icon @click="close_dialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-data-table :headers="get_headers()" :items="players" :items-per-page="$vuetify.breakpoint.mdAndUp ? 10 : 5"
          :search="search" :loading="players_loading" loading-text="Loading... Please wait" class="mb-1 mt-2"
          v-if="appData && !appDataLoading">

          <template v-slot:top>
            <v-text-field ref="search" hide-details="auto" v-model="search" label="Search" single-line outlined dense
              :disabled="players_loading" autofocus placeholder="Search" />
          </template>

          <template v-slot:[`item.action`]>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dense v-bind="attrs" v-on="on" icon small color="primary" class="mr-1">
                  <v-icon small> mdi-face-man </v-icon>
                </v-btn>
              </template>
              <span>View Sales Representative</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dense v-bind="attrs" v-on="on" icon small color="primary">
                  <v-icon small> mdi-face-man </v-icon>
                </v-btn>
              </template>
              <span>View Regular Player</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <v-list class="py-0 my-0" style="background:transparent">
              <v-list-item>
                <v-list-item-avatar size="42px">
                  <v-icon dark color="grey" size="42px">
                    mdi-account-circle
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>

          <template v-slot:[`item.mobile_number`]="{ item }">
            {{ `+63${item.mobile_number}` }}
          </template>

          <template v-slot:[`item.commission`]="{ item }">
            {{ `${item.commission}%` }}
          </template>

          <template v-slot:[`item.token`]="{ item }">
            <v-chip small
              :class="parseFloat(item.token) < 1000 ? 'red lighten-5 red--text text--lighten-1' : parseFloat(item.token) < 5000 ? 'orange lighten-5 orange--text text--lighten-1' : 'green lighten-5 green--text text--lighten-1'">
              {{ `Php ${money_format(parseFloat(item.token))}` }}
            </v-chip>
          </template>

          <template v-slot:[`item.sales_representative`]="{ item }">
            {{ `${number_format(parseInt(item.sales_representative))}` }}
          </template>

          <template v-slot:[`item.regular_player`]="{ item }">
            {{ `${number_format(parseInt(item.regular_player))}` }}
          </template>

          <template v-slot:[`item.account_verified`]="{ item }">
            <v-chip small class="font-weight-bold"
              :class="item.status == 'Verified' ? 'green lighten-5 green--text' : 'orange lighten-5 orange--text'">
              {{ item.account_verified }}
            </v-chip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip small class="font-weight-bold"
              :class="item.status == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.created_at) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import formatDate from '@/helpers/formatDate'
import responseGet from '@/helpers/api_request_get'

export default {
  props: {
    accounts_data: {
      type: Object
    }
  },

  data: () => ({
    players: [],
    search: '',
    players_loading: false,
    module_header: '',
  }),

  computed: {
    ...mapState(['appData', 'appDataLoading']),

    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  mounted() {
    if (this.appData) {
      this.doGetPlayers()
    }

    switch (parseInt(this.accounts_data.access_type)) {
      case 1:
        this.module_header = 'Sales Supervisor'
        break
      case 2:
        this.module_header = 'Sales Representative'
        break
      case 3:
        this.module_header = 'Regular Player'
        break
    }
  },

  methods: {
    async doGetPlayers() {
      this.players_loading = true
      let response = null

      switch (parseInt(this.accounts_data.access_type)) {
        case 1:
          response = await responseGet(this.set_form_data(), 'agents/sales_supervisor')
          break
        case 2:
          response = await responseGet(this.set_form_data(), 'agents/sales_representative')
          break
        case 3:
          response = await responseGet(this.set_form_data(), 'agents/regular_player')
          break
      }

      if (response.status == 200) {
        this.players = response.data.records
      } else if (response.status == 401) {
        localStorage.removeItem("userToken")
        this.$router.push({ name: "Home" })
        location.reload()
      }

      this.players_loading = false
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('investor_id', this.accounts_data.id)

      return new URLSearchParams(form_data)
    },

    close_dialog() {
      this.$emit("set_close_sub_account_dialog")
    },

    format_date(date) {
      return formatDate(date)
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    get_headers() {
      let headers = []

      switch (parseInt(this.accounts_data.access_type)) {
        case 1:
          headers = [
            {
              text: "Action",
              sortable: false,
              value: "action",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Name",
              value: "name",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Mobile Number",
              value: "mobile_number",
              class: "text-uppercase",
            },
            {
              text: "Commission",
              value: "commission",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Token",
              value: "token",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Representative",
              value: "sales_representative",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Player",
              value: "regular_player",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Status",
              value: "status",
              class: "text-uppercase"
            }
          ]
          break
        case 2:
          headers = [
            {
              text: "Name",
              value: "name",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Mobile Number",
              value: "mobile_number",
              class: "text-uppercase",
            },
            {
              text: "Commission",
              value: "commission",
              class: "text-uppercase",
            },
            {
              text: "Account Status",
              value: "account_verified",
              class: "text-uppercase"
            },
            {
              text: "Status",
              value: "status",
              class: "text-uppercase"
            },
            {
              text: "Registered Date",
              value: "created_at",
              class: "text-uppercase"
            },
          ]
          break
        case 3:
          headers = [
            {
              text: "Name",
              value: "name",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Mobile Number",
              value: "mobile_number",
              class: "text-uppercase",
            },
            {
              text: "Account Status",
              value: "account_verified",
              class: "text-uppercase"
            },
            {
              text: "Status",
              value: "status",
              class: "text-uppercase"
            },
            {
              text: "Registered Date",
              value: "created_at",
              class: "text-uppercase"
            },
          ]
          break
      }

      return headers
    }
  },

  watch: {
    appData(val) {
      if (val) {
        this.doGetPlayers()
      }
    },
  }
}
</script>