<template>
  <v-dialog persistent :fullscreen="!$vuetify.breakpoint.mdAndUp" v-model="dialog" max-width="400">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        <span>Add Token</span>

        <v-btn plain icon @click="close_dialog" :disabled="add_status || add_token_loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" class="d-flex flex-column" :disabled="add_status || add_token_loading"
          @submit.prevent="handle_submit" style="gap:1rem">

          <v-alert v-if="alert_dialog" dense text :color="add_status ? 'success' : 'error'" class="my-0">
            {{ alert_message }}
          </v-alert>

          <v-simple-table v-if="appData" class="mb-4">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td style="width: 30%;">Agent</td>
                  <td class="text-end">{{ account_details.name }}</td>
                </tr>

                <tr>
                  <td style="width: 30%;">Available Token:</td>
                  <td class="text-end">
                    <span style="font-size:18px"
                      :class="appData.account_details.wallet == '0' ? 'red--text text--lighten-1' : 'green--text text--lighten-1'">
                      {{ `Php ${money_format(parseFloat(appData.account_details.wallet))}` }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-text-field ref="token" type="number" label="Token" placeholder="0.00" v-model.lazy="token" outlined dense
            autofocus :rules="submit_status ? [rules.required, is_wallet_sufficient, is_less_than_minimum] : []"
            hide-details="auto" />

          <v-text-field ref="password" v-model="password" :rules="submit_status ? [rules.required, rules.minimum(6)] : []"
            :append-icon="password_visibility ? 'mdi-eye' : 'mdi-eye-off'"
            :type="password_visibility ? 'text' : 'password'" label="Your password" outlined dense
            @click:append="password_visibility = !password_visibility" hide-details="auto" />

          <div class="d-flex justify-end" style="gap:.30rem">
            <v-btn text depressed color="grey" class="px-2" @click="close_dialog"
              :disabled="add_status || add_token_loading">
              Cancel
            </v-btn>

            <v-btn depressed color="primary" type="submit" :loading="add_token_loading" :disabled="add_status"
              class="px-2">
              Add
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import form_rules from '@/helpers/formRules'
import responsePost from '@/helpers/api_request_post'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    account_details: Object
  },

  data: () => ({
    rules: form_rules,
    token: 100,
    password: '',
    password_visibility: false,
    submit_status: false,
    add_token_loading: false,
    add_status: false,
    alert_dialog: false,
    alert_message: ''
  }),

  computed: {
    ...mapState(["appData", "appDataLoading"]),

    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    ...mapActions(["getAppdata"]),

    async handle_submit() {
      this.submit_status = true

      await new Promise(r => setTimeout(r, 200));

      if (this.$refs.form.validate()) {
        this.add_token_loading = true

        const response = await responsePost(this.set_form_data(), 'agents/add_token')

        this.alert_dialog = true

        switch (response.status) {
          case 200: {
            this.add_status = true
            this.alert_message = 'Successfully added.'
          }
            break
          case 401: {
            this.alert_message = 'You are not authorized, you will be rederict to login.'

            setTimeout(() => {
              localStorage.removeItem("userToken")
              this.$router.push({ name: "Home" })
              location.reload()
            }, 2000)
          }
            break
          case 403: {
            this.alert_message = 'Unathorized password.'
            this.$refs.password.focus()
          }
            break
          case 409:
            this.alert_message = 'Insufficient balance.'
            this.$refs.token.focus()
            break
          default:
            this.alert_message = 'Something went wrong.'
            break
        }

        this.add_token_loading = false
      } else this.$refs.token.focus()
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('account_id', this.account_details.id)
      form_data.append('token', this.token)
      form_data.append('type', this.account_details.type != 'Sales Representative' ? 'Agent' : 'Sales Representative')
      form_data.append('password', this.password)

      return form_data
    },

    is_less_than_minimum(val) {
      if (val) {
        if (parseInt(val) < 1) return 'The token must not be less than 1.'
        return true
      }

      return true
    },

    is_wallet_sufficient(val) {
      if (val) {
        if (parseInt(val) > parseInt(this.appData.account_details.wallet)) return 'Insufficient token.'
        return true
      }

      return true
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },

    close_dialog() {
      this.$emit('set_close_add_token_dialog', {})
    },

    async get_app_data() {
      await this.getAppdata()
    }
  },

  watch: {
    add_status(val) {
      if (val) {
        setTimeout(() => {
          this.get_app_data()

          const investor_data = {
            'investor_id': this.account_details.id,
            'token': this.token
          }

          this.$emit('set_close_add_token_dialog', investor_data)
        }, 3000)
      }
    }
  }
}
</script>
