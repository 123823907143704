<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        <span>Filter</span>

        <v-btn plain icon @click="close_dialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" @submit.prevent="do_filter" class="d-flex flex-column" style="gap:1rem">

          <v-select :items="statuses" v-model="status" label="Status" hide-details="auto" dense outlined />

          <v-menu v-model="date_from_menu" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date_from" label="Date From" readonly outlined dense v-bind="attrs" v-on="on"
                @keyup="date_from_menu = true" @keydown="date_from_menu = false" hide-details="auto"
                clearable></v-text-field>
            </template>

            <v-date-picker v-model="date_from" @input="date_from_menu = false"></v-date-picker>
          </v-menu>

          <v-menu v-model="date_to_menu" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date_to" label="Date To" readonly outlined dense v-bind="attrs" v-on="on"
                @keyup="date_to_menu = true" @keydown="date_to_menu = false" hide-details="auto" clearable></v-text-field>
            </template>

            <v-date-picker v-model="date_to" @input="date_to_menu = false"></v-date-picker>
          </v-menu>

          <div class="d-flex justify-end" style="width: 100%; gap:.30rem">
            <v-btn depressed text color="grey" class="px-2" @click="close_dialog">
              Cancel
            </v-btn>

            <v-btn depressed type="submit" color="primary" class="px-2">
              Filter
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    filter_dialog_data: {
      type: Object
    }
  },

  data: () => ({
    date_from: null,
    date_from_menu: false,
    date_to: null,
    date_to_menu: false,
    statuses: ['All', 'Requested', 'Approved', 'Settled'],
    status: 'Requested'
  }),

  mounted() {
    this.date_from = this.filter_dialog_data.date_from
    this.date_to = this.filter_dialog_data.date_to
    this.status = this.filter_dialog_data.status
  },

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    close_dialog() {
      this.$emit("set_close_filter_dialog", {})
    },

    do_filter() {
      this.$emit("set_close_filter_dialog", {
        date_from: this.date_from,
        date_to: this.date_to,
        status: this.status
      })
    }
  }
}
</script>