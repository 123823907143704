var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":!_vm.$vuetify.breakpoint.mdAndUp,"persistent":_vm.records_loading,"max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"d-flex justify-space-between text-h6 font-weight-regular"},[_c('span',[_vm._v("History")]),_c('v-btn',{attrs:{"icon":"","plain":"","disabled":_vm.records_loading},on:{"click":_vm.close_dialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pb-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":5,"loading":_vm.records_loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.set_logs_dialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,true)},[_c('span',[_vm._v("Logs")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.status == 'Requested' ? 'blue-grey lighten-5 blue-grey--text' : item.status == 'Verified' ? 'orange lighten-5 orange--text' : 'green lighten-5 green--text'},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(("Php " + (_vm.money_format(parseFloat(item.amount))))))])]}},{key:"item.requested_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.requested_date))+" ")]}}],null,true)})],1)],1),(_vm.logs_dialog)?_c('Logs',{attrs:{"data":_vm.logs_data},on:{"set_close_logs_dialog":_vm.set_close_logs_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }