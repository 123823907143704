<template>
  <v-card class="shadow">
    <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular">
      <span>My Commissions</span>

      <v-btn text small class="text-capitalize font-weight-regular px-2 grey--text text--darken-2"
        @click="filter_dialog = true" :disabled="records_loading">
        {{ filter_label }}
        <v-icon small class="ps-1">mdi-chevron-down</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="d-flex flex-column" style="gap:2rem">
      <div class="d-flex flex-sm-row flex-column" style="gap:1rem">
        <div class="d-flex flex-column shadow rounded blue-grey darken-4 white--text"
          style="border: 1px solid #ECEFF1; padding: 1rem; gap:1rem; width: 100%">
          <v-avatar rounded style="background: #ECEFF1;">
            <v-icon color="blue-grey">
              mdi-tag-outline
            </v-icon>
          </v-avatar>

          <span>PENDING</span>
          <v-divider class="white" />
          <span v-if="records_loading" style="font-size:18px">Php 0</span>
          <span v-else style="font-size:18px">{{ `Php ${money_format(parseFloat(commissions.total))}` }}</span>
        </div>

        <div class="d-flex flex-column shadow rounded green darken-4 white--text"
          style="border: 1px solid #ECEFF1; padding: 1rem; gap:1rem; width: 100%">
          <v-avatar rounded style="background: #ECEFF1;">
            <v-icon color="green">
              mdi-tag-check-outline
            </v-icon>
          </v-avatar>

          <span>PAID</span>
          <v-divider class="white" />
          <span v-if="records_loading" style="font-size:18px">Php 0</span>
          <span v-else style="font-size:18px">{{ `Php ${money_format(parseFloat(commissions.paid))}` }}</span>
        </div>

        <div class="d-flex flex-column shadow rounded red darken-4 white--text"
          style="border: 1px solid #ECEFF1; padding: 1rem; gap:1rem; width: 100%">
          <v-avatar rounded style="background: #ECEFF1;">
            <v-icon color="red">
              mdi-tag-remove-outline
            </v-icon>
          </v-avatar>

          <span>UNPAID</span>
          <v-divider class="white" />
          <span v-if="records_loading" style="font-size:18px">Php 0</span>
          <span v-else style="font-size:18px">{{ `Php ${money_format(parseFloat(commissions.un_paid))}` }}</span>
        </div>
      </div>

      <div class="d-flex flex-sm-row flex-column" style="gap:1rem">
        <div class="d-flex flex-column shadow rounded"
          style="border: 1px solid #ECEFF1; padding: 1rem; gap:1rem; width: 100%">
          <v-avatar rounded style="background: #ECEFF1;">
            <v-icon color="blue-grey">
              mdi-send
            </v-icon>
          </v-avatar>

          <span>REQUESTED</span>
          <v-divider />
          <span v-if="records_loading" style="font-size:18px">Php 0</span>
          <span v-else style="font-size:18px">{{ `Php ${money_format(parseFloat(requests.requested))}` }}</span>
        </div>

        <div class="d-flex flex-column shadow rounded"
          style="border: 1px solid #FFF3E0; padding: 1rem; gap:1rem; width: 100%">
          <v-avatar rounded style="background: #ECEFF1;">
            <v-icon color="orange">
              mdi-shield-check-outline
            </v-icon>
          </v-avatar>

          <span>VERIFIED</span>
          <v-divider />
          <span v-if="records_loading" style="font-size:18px">Php 0</span>
          <span v-else style="font-size:18px">{{ `Php ${money_format(parseFloat(requests.verified))}` }}</span>
        </div>

        <div class="d-flex flex-column shadow rounded"
          style="border: 1px solid #E8F5E9; padding: 1rem; gap:1rem; width: 100%">
          <v-avatar rounded style="background: #ECEFF1;">
            <v-icon color="green">
              mdi-check-outline
            </v-icon>
          </v-avatar>

          <span>APPROVED</span>
          <v-divider />
          <span v-if="records_loading" style="font-size:18px">Php 0</span>
          <span v-else style="font-size:18px">{{ `Php ${money_format(parseFloat(requests.approved))}` }}</span>
        </div>
      </div>
    </v-card-text>

    <v-dialog ref="filter_dialog" v-model="filter_dialog" :return-value.sync="month" persistent width="290px">
      <v-date-picker v-model="month" full-width type="month">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelFilter">
          Cancel
        </v-btn>

        <v-btn text color="primary" @click="saveFilter">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-card>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import formatDatev2 from "@/helpers/formatDatev2"

export default {
  data: () => ({
    commissions: [],
    requests: [],
    records_loading: false,
    month: formatDatev2((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 'format', 'yyyy-MM'),
    date_from: '2023-04-01',
    date_to: '2023-04-30',
    filter_label: null,
    filter_dialog: false
  }),

  mounted() {
    this.doGetMyCommission()
  },

  methods: {
    async doGetMyCommission() {
      this.records_loading = !this.records_loading

      const response = await responseGet(this.set_form_data(), 'dashboard/my_commissions')

      switch (response.status) {
        case 200:
          this.filter_label = response.data.label
          this.commissions = response.data.commissions
          this.requests = response.data.requests
          break
        case 401:
          localStorage.removeItem("userToken")
          this.$router.push({ name: "Home" })
          location.reload()
          break
      }

      this.records_loading = !this.records_loading
    },

    set_form_data() {
      const form_data = new FormData()
      form_data.append('val', this.month)

      return new URLSearchParams(form_data)
    },

    cancelFilter() {
      this.filter_dialog = false
    },

    saveFilter() {
      this.$refs.filter_dialog.save(this.month)
      this.doGetMyCommission()
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
  }
}
</script>