<template>
  <v-dialog persistent v-model="dialog" max-width="500">
    <v-card>
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        <span>Filter</span>

        <v-btn plain icon @click="close_dialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" class="d-flex flex-column" style="gap:.75rem" @submit.prevent="close_dialog()">
          <div class="d-flex" style="gap: 0.75rem">
            <div class="d-flex flex-column" style="width: 100%">
              <v-menu v-model="date_from_menu" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date_from" label="Date From" readonly outlined dense v-bind="attrs" v-on="on"
                    @keyup="date_from_menu = true" @keydown="date_from_menu = false" hide-details="auto"
                    clearable></v-text-field>
                </template>

                <v-date-picker v-model="date_from" @input="date_from_menu = false"></v-date-picker>
              </v-menu>

              <div class="mt-1 ms-1" style="font-size: 10px; color: red">
                {{ validation_error.date_from }}
              </div>
            </div>

            <div class="d-flex flex-column" style="width: 100%">
              <v-menu v-model="date_to_menu" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date_to" label="Date To" readonly outlined dense v-bind="attrs" v-on="on"
                    @keyup="date_to_menu = true" @keydown="date_to_menu = false" hide-details="auto"
                    clearable></v-text-field>
                </template>

                <v-date-picker v-model="date_to" @input="date_to_menu = false"></v-date-picker>
              </v-menu>

              <div class="mt-1 ms-1" style="font-size: 10px; color: red">
                {{ validation_error.date_to }}
              </div>
            </div>
          </div>

          <div class="d-flex justify-end" style="gap:.30rem">
            <v-btn text color="grey" @click="close_dialog">Cancel</v-btn>
            <v-btn type="submit" depressed color="primary" class="px-2">Apply</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    date_from: null,
    date_from_menu: false,
    date_to: null,
    date_to_menu: false,
  }),
  mounted() {
    this.date_from = this.$route.query.from != null ? this.$route.query.from : null;
    this.date_to = this.$route.query.to != null ? this.$route.query.to : null;
  },

  props: ["validation_error"],

  name: "FilterTokenMovementsDialog",
  
  methods: {
    close_dialog() {
      this.$emit("close_filter_dialog", [this.date_from, this.date_to]);
    },
  },
  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_dialog();
      },
    },
  },
};
</script>
