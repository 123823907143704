<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" v-model="dialog" max-width="400">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        <div class="d-flex flex-column">
          <span>Update Commission</span>
          <span class="text-overline grey--text" dense>{{ commission_data.type }}</span>
        </div>

        <v-btn icon @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" class="d-flex flex-column" style="gap:.75rem" :disabled="update_loading"
          @submit.prevent="handle_submit">

          <v-alert dense class="red lighten-5 red--text subtitle-1" v-if="alert_status">
            {{ alert_message }}
          </v-alert>

          <v-alert hide-details="true" class="mb-0" outlined type="info" text>
            This will be applied for those new registrant.
          </v-alert>

          <v-alert outlined type="warning" class="mb-0" text>
            Update of commission will not affect registered account. If you wish to change account commission, you can
            do that in agent list module.
          </v-alert>

          <v-text-field v-model="commission" label="Commission" placeholder="0.00" outlined clearable dense
            hide-details="auto" suffix="%" autofocus :rules="[is_greater_than_default]" />

          <div class="d-flex justify-end" style="gap:.30rem">
            <v-btn text color="grey" depressed class="px-2" @click="close_dialog">
              Cancel
            </v-btn>

            <v-btn dark color="primary" depressed class="px-2" type="submit" :loading="update_loading">
              Update
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import responsePost from '@/helpers/api_request_post'

export default {
  props: {
    commission_data: {
      type: Object
    }
  },

  data: () => ({
    commission: 0,
    update_loading: false,
    alert_status: false,
    alert_message: 'Something went wrong!'
  }),

  mounted() {
    this.commission = this.commission_data.commission
  },

  computed: {
    ...mapState(["appData"]),

    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    ...mapActions(["getAppdata"]),

    async handle_submit() {
      this.alert_status = false
      this.update_loading = true

      const response = await responsePost(this.set_form_data(), 'update_commission')

      if (response.status == '200') {
        await this.getAppdata()

        this.$emit("set_close_commission_dialog", true)
      } else if (response.status == 401) {
        localStorage.removeItem("userToken")
        this.$router.push({ name: "Home" })
        location.reload()
      } else this.alert_status = true

      this.update_loading = false
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('type', this.commission_data.request_type)
      form_data.append('commission', this.commission)

      return form_data
    },

    // is_less_than_one() {
    //     return parseFloat(this.commission) >= parseFloat(1) ? true : 'Commission must not less than 1.00%'
    // },

    is_greater_than_default() {
      if (this.appData) {
        return parseFloat(this.commission) <= parseFloat(this.appData.account_details.base_commission) ? true : `Commission must not greater than ${this.money_format(parseFloat(this.commission_data.commission))}`
      } return true
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    close_dialog() {
      this.$emit("set_close_commission_dialog", false)
    }
  }
}
</script>