import Vue from 'vue'
import Vuex from 'vuex'
import jwtdecode from '../helpers/jwtdecode'
import logout from './logout'

Vue.use(Vuex)

const url = process.env.VUE_APP_API_URL + "app_data"

export default new Vuex.Store({
  state: {
    userData: jwtdecode(localStorage.getItem('userToken')) || null,
    userToken: localStorage.getItem('userToken') || null,
    appData: null,
    actualPoints: null
  },
  getters: {
    isAuthenticated: state => !!state.userData,
  },
  mutations: {
    setUserData (state, val) {
      state.userData = val
    },
    setUserToken (state, val) {
      state.userToken = val
    },
    setAppData (state, val) {
      state.appData = val
    },
    setActualPoints (state, val) {
      state.actualPoints = val
    },
    setAppDataLoading (state, val) {
      state.appDataLoading = val
    },
  },
  actions: {
    async getAppdata({ commit, state }) {
      commit("setAppDataLoading", true)
      
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${state.userToken}`,
          }
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setAppData", res)
          commit("setActualPoints", res.account_details.wallet)
          // commit("setAccountStatus", res.account_details.account_verified)
        }
      } catch(error) {
        // 
      } finally {
        commit("setAppDataLoading", false)
      }
    }
  },
  modules: {
    // login,
    // forgot,
    // reset_password,
    
    // Dashboard
    // app_dashboard,

    // Agents
    // app_agents_list,
    // app_agents_remittance,
    // app_agents_withdrawals,

    // Wallet
    // app_add_points,

    // Monitoring
    // paidCommission,

    // Reports
    // app_reports_player_bets,
    // app_reports_bets_progressive,
    // app_reports_game_bets,
    // app_reports_players_cash_in,

    // account,
    logout
  }
})
