import { format } from 'date-fns'
import { getWeekOfMonth } from 'date-fns'

const getOrdinalNum = (n) => n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '')

const formatDatev2 = (date, type = 'datetime', customFormat = '') => {
  if (date) {
    if (type=='datetime') {
      return format(new Date(date), 'MMM dd, yyyy hh:mm a')
    }

    if (type=='date') {
      return format(new Date(date), 'MMM dd, yyyy')
    }

    if (type=='monthweek') {
      return format(new Date(date), 'MMM, yyyy') + ' (' + getOrdinalNum(getWeekOfMonth(new Date(date), { weekStartsOn: 2 })) + ' week)'
    }

    if (type=='monthyear') {
      return format(new Date(date), 'MMM, yyyy')
    }
    
    if (type=='format') {
      return format(new Date(date), customFormat)
    }
  } else {
    return '-'
  }
}

export default formatDatev2