<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" v-model="dialog" max-width="900">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between texht-h6 font-weight-regular">
        <span>{{ `Logs — Php ${money_format(parseFloat(data.amount))}` }}</span>

        <v-btn icon plain @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table :headers="headers" :items="data.logs" :items-per-page="5" loading-text="Loading... Please wait">
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="item.status == 'Requested' ? 'blue-grey lighten-5 blue-grey--text' : item.status == 'Verified' ? 'orange lighten-5 orange--text' : 'green lighten-5 green--text'">
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.process_date`]="{ item }">
            {{ format_date(item.process_date) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import formatDate from '@/helpers/formatDate'

export default {
  props: {
    data: {
      type: Object
    }
  },

  data: () => ({
    headers: [
      {
        text: "Name",
        value: "name",
        class: "text-uppercase",
      },
      {
        text: "Mode of Payment",
        value: "mode_of_payment",
        class: "text-uppercase",
      },
      {
        text: "Reference",
        value: "reference",
        class: "text-uppercase",
      },
      {
        text: "Remarks",
        value: "remarks",
        class: "text-uppercase",
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase",
      },
      {
        text: "Processed Date",
        value: "process_date",
        class: "text-uppercase",
      }
    ]
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    format_date(date) {
      return formatDate(date)
    },

    close_dialog() {
      this.$emit("set_close_logs_dialog")
    }
  }
}
</script>