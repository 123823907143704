import store from "../store";
const url = process.env.VUE_APP_API_URL;

const request_post = async (payload, end_point) => {
  try {
    let response_data = {};
    let response_status = 0;

    let user_token = "";

    switch (end_point) {
      case "login":
      case "forgot":
      case "reset":
      case "register":
        user_token =
          "Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==";
        break;
      default:
        user_token = `Bearer ${store.state.userToken}`;
        break;
    }

    // if (end_point == "login")
    //   user_token =
    //     "Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==";
    // else user_token = `Bearer ${store.state.userToken}`;

    const response = await fetch(`${url}${end_point}`, {
      method: "POST",
      headers: {
        Authorization: user_token,
      },
      body: payload,
    });

    const res = await response.json();
    response_status = response.status;
    response_data = {
      status: response_status,
      data: res,
    };

    return response_data;
  } catch (error) {
    return error;
  }
};

export default request_post;
