<template>
  <v-container fluid>
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        Token Movements
      </v-card-title>
      
      <div
        class="filter-container d-flex flex-column flex-sm-row align-center justify-space-between pa-4"
        style="border-bottom: 1px solid transparent"
      >
        <div class="input-wrapper">
          <v-select
            v-model="filter"
            hide-details
            dense
            :items="filter_option"
            label="Filter"
            outlined
          ></v-select>
        </div>
        <div class="input-wrapper">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
            class="pa-0 ma-0"
            v-if="token_movements.length != 0"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="token_movements"
        :items-per-page="5"
        :search="search"
        :loading="loading_token_movements"
        loading-text="Loading ..."
        :no-data-text="'No records found ' + no_data_text"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:[`item.processed_by`]="{ item }">
          {{ item.processed_by }}
        </template>
        <template v-slot:[`item.token`]="{ item }">
          {{ money_format(parseFloat(item.token)) }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ format_date(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>

    <FilterTokenMovementsDialog
      v-if="dialog"
      :validation_error="validation_error"
      @close_filter_dialog="close_filter_dialog"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import formatDate from "@/helpers/formatDatev2";
import FilterTokenMovementsDialog from "@/components/app/monitoring/FilterTokenMovementsDialog.vue";

export default {
  data: () => ({
    filter: "This Week",
    filter_option: ["This Day", "This Week", "This Month", "Custom"],
    headers: [
      { text: "Transfer To", value: "transfer_to", class: "text-uppercase" },
      { text: "Processed By", value: "processed_by", class: "text-uppercase" },
      { text: "Added Token", value: "token", class: "text-uppercase" },
      { text: "Added At", value: "created_at", class: "text-uppercase" },
    ],
    no_data_text: null,
    token_movements: [],
    loading_token_movements: false,
    search: null,
    dialog: false,
    date_from: null,
    date_to: null,
    validation_error: [],
  }),

  mounted() {
    this.set_dates();
    this.filter =
      this.$route.query.filter_by != null ? this.$route.query.filter_by : this.filter;
    this.date_from =
      this.$route.query.from != null ? this.$route.query.from : this.date_from;
    this.date_to = this.$route.query.to != null ? this.$route.query.to : this.date_to;
    this.load_token_movements();
  },

  name: "TokenMovements",
  components: { FilterTokenMovementsDialog },
  methods: {
    async load_token_movements() {
      this.token_movements = [];
      this.validation_error = [];
      this.loading_token_movements = true;

      let url = process.env.VUE_APP_API_URL;
      let token = localStorage.getItem("userToken");

      await axios
        .get(url + "investors/token_movements", {
          params: {
            date_from: this.date_from,
            date_to: this.date_to,
          },
          headers: { Authorization: `Bearer ` + token },
        })
        .then((res) => {
          this.token_movements = res.data.records;
        })
        .catch((err) => {
          this.validation_error = err.response.data.messages;
          this.dialog = true;
        });

      this.$router.replace({
        query: {
          filter_by: this.filter || undefined,
          from: this.date_from || undefined,
          to: this.date_to || undefined,
        },
      });

      this.loading_token_movements = false;
    },
    set_dates() {
      var current_date = moment().format("YYYY-MM-DD");

      var first_day_of_current_week = moment().startOf("week").format("YYYY-MM-DD");
      var last_day_of_current_week = moment().endOf("week").format("YYYY-MM-DD");

      var first_day_of_current_month = moment().startOf("month").format("YYYY-MM-DD");
      var last_day_of_current_month = moment().endOf("month").format("YYYY-MM-DD");

      switch (this.filter) {
        case "This Day":
          this.date_from = current_date;
          this.date_to = current_date;
          this.no_data_text = "on this day";
          break;
        case "This Week":
          this.date_from = first_day_of_current_week;
          this.date_to = last_day_of_current_week;
          this.no_data_text = "on this week";
          break;
        case "This Month":
          this.date_from = first_day_of_current_month;
          this.date_to = last_day_of_current_month;
          this.no_data_text = "on this month";
          break;
        case "Custom":
          this.date_from = null;
          this.date_to = null;
          this.dialog = true;
          this.no_data_text = "on this month";
          break;
      }
    },
    format_date(date) {
      return formatDate(date, "datetime");
    },
    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    close_filter_dialog(dates) {
      this.dialog = false;
      this.date_from = dates[0];
      this.date_to = dates[1];
      this.load_token_movements();
    },
  },
  watch: {
    filter() {
      this.set_dates();
      if (this.filter != "Custom") {
        this.load_token_movements();
      }
    },
  },
};
</script>

<style scoped>
.filter-container {
  gap: 1rem;
}

.filter-container .input-wrapper {
  display: inline-flex;
}

@media (max-width: 768px) {
  .filter-container {
    gap: 0.5rem;
  }

  .filter-container .input-wrapper {
    display: flex;
    width: 100%;
  }
}
</style>
