<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
    :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartJSPluginDatalabels)


export default {
  name: 'BarChart',

  components: {
    Bar
  },

  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 230
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chart_data: {
      required: false
    }
  },

  data() {
    return {
      breakpoint: this.$vuetify.breakpoint,
      chartData: {
        labels: this.chart_data.labels,
        datasets: [
          {
            label: "Accounts",
            backgroundColor: [
              "#1E88E5",
              "#00ACC1",
              "#26A69A",
            ],
            pointBackgroundColor: [
              "#42A5F5",
              "#26C6DA",
              "#4DB6AC",
            ],
            borderColor: [
              "#42A5F5",
              "#26C6DA",
              "#4DB6AC",
            ],
            data: this.chart_data.data_sets,
            borderWidth: [1, 1, 1, 1],
            borderRadius: [3, 3, 3, 3],
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: this.$vuetify.breakpoint.mdAndUp ? 'y' : 'x',
        plugins: {
          datalabels: {
            display: this.$vuetify.breakpoint.mdAndUp,
            color: "white",
            alight: "start",
            font: {
              weight: "light",
              size: 16
            }
          },
          legends: {
            position: 'top',
          }
        },
      }
    }
  },
}
</script>
