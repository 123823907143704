<template>
  <v-dialog scrollable v-model="dialog" max-width="1100">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold pa-2">
        <span>Downline Commission</span>

        <v-btn icon @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="d-flex flex-column pa-2" style="gap:.60rem">
        <v-row v-if="records" no-gutters>
          <v-col cols="12" md="4">
            <v-card class="shadow px-1" style="width:100%">
              <v-card-title class="d-flex justify-center text-h6 font-weight-regular py-2">
                <span class="text-overline">TOTAL COMMISSION</span>
              </v-card-title>

              <v-divider class="my-1" />

              <v-card-text class="d-flex justify-center px-2 py-4">
                <span style="font-size: 20px;">{{ `Php ${money_format(parseFloat(total_commission_amount))}` }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- <div v-if="records" class="d-flex" :class="$vuetify.breakpoint.mdAndUp ? 'flex-row' : 'flex-column'"
          style="gap:.60rem">
          <v-card class="shadow px-1" style="width:100%">
            <v-card-title class="d-flex justify-center text-h6 font-weight-regular py-2">
              <span class="text-overline">TOTAL BETS</span>
            </v-card-title>

            <v-divider class="my-1" />

            <v-card-text class="d-flex justify-center pa-2">
              <span style="font-size: 20px;">{{ number_format(parseInt(total_bets)) }}</span>
            </v-card-text>
          </v-card>

          <v-card class="shadow px-1" style="width:100%">
            <v-card-title class="d-flex justify-center text-h6 font-weight-regular py-2">
              <span class="text-overline">TOTAL BET AMOUNT</span>
            </v-card-title>

            <v-divider class="my-1" />

            <v-card-text class="d-flex justify-center pa-2">
              <span style="font-size: 20px;">{{ `₱${money_format(parseFloat(total_bet_amount))}` }}</span>
            </v-card-text>
          </v-card>

          <v-card class="shadow px-1" style="width:100%">
            <v-card-title class="d-flex justify-center text-h6 font-weight-regular py-2">
              <span class="text-overline">TOTAL COMMISSION %</span>
            </v-card-title>

            <v-divider class="my-1" />

            <v-card-text class="d-flex justify-center pa-2">
              <span style="font-size: 20px;">{{ money_format(parseFloat(total_commission_percentage)) }}</span>
            </v-card-text>
          </v-card>

          <v-card class="shadow px-1" style="width:100%">
            <v-card-title class="d-flex justify-center text-h6 font-weight-regular py-2">
              <span class="text-overline">TOTAL COMMISSION</span>
            </v-card-title>

            <v-divider class="my-1" />

            <v-card-text class="d-flex justify-center pa-2">
              <span style="font-size: 20px;">{{ `₱${money_format(parseFloat(total_commission_amount))}` }}</span>
            </v-card-text>
          </v-card>
        </div> -->

        <v-data-table :headers="headers" :items="records" :items-per-page="$vuetify.breakpoint.mdAndUp ? 10 : 5"
          :search="search" :loading="records_loading" :item-key="`${records.id}-${records.type}`"
          loading-text="Loading... Please wait">

          <template v-slot:top>
            <v-text-field ref="search" v-model="search" label="Search" single-line outlined dense
              :disabled="records_loading" autofocus placeholder="Search" />
          </template>

          <template v-slot:[`item.mobile_number`]="{ item }">
            {{ `+63${item.mobile_number}` }}
          </template>

          <template v-slot:[`item.bets`]="{ item }">
            {{ number_format(parseInt(item.bets)) }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            {{ `Php ${money_format(parseFloat(item.amount))}` }}
          </template>

          <template v-slot:[`item.commission_percent`]="{ item }">
            {{ `${money_format(parseFloat(item.commission_percent))}%` }}
          </template>

          <template v-slot:[`item.commission_amount`]="{ item }">
            {{ `Php ${money_format(parseFloat(item.commission_amount))}` }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip small class="font-weight-bold"
              :class="item.status == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.created_at) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import responseGet from '@/helpers/api_request_get'

export default {
  data: () => ({
    search: '',
    records: [],
    records_loading: false,
    headers: [
      {
        text: "Agents",
        value: "agent",
        class: "text-uppercase",
        align: "start"
      },
      {
        text: "Players",
        value: "player",
        class: "text-uppercase",
        align: "center"
      },
      {
        text: "Type",
        value: "type",
        class: "text-uppercase",
      },
      {
        text: "Mobile Number",
        value: "mobile_number",
        class: "text-uppercase",
      },
      {
        text: "Bets",
        value: "bets",
        class: "text-uppercase",
        align: 'right'
      },
      {
        text: "Bet Amount",
        value: "amount",
        class: "text-uppercase",
        align: 'right'
      },
      {
        text: "Commission %",
        value: "commission_percent",
        class: "text-uppercase",
        align: 'right'
      },
      {
        text: "Commission",
        value: "commission_amount",
        class: "text-uppercase",
        align: 'right'
      },
    ]
  }),

  mounted() {
    this.doGetDownlineCommission()
  },

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    },

    // total_bets() {
    //   if (this.records) {
    //     return this.records.reduce((total, obj) => parseFloat(obj.bets) + total, 0)
    //   } else {
    //     return 0
    //   }
    // },

    // total_bet_amount() {
    //   if (this.records) {
    //     return this.records.reduce((total, obj) => parseFloat(obj.amount) + total, 0)
    //   } else {
    //     return 0
    //   }
    // },

    // total_commission_percentage() {
    //   if (this.records) {
    //     return this.records.reduce((total, obj) => parseFloat(obj.commission_percent) + total, 0)
    //   } else {
    //     return 0
    //   }
    // },

    total_commission_amount() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.commission_amount) + total, 0)
      } else {
        return 0
      }
    },
  },

  methods: {
    async doGetDownlineCommission() {
      this.records_loading = !this.records_loading

      const response = await responseGet('', 'downline/commission')

      if (response.status == 200) {
        this.records = response.data.records
      }

      this.records_loading = !this.records_loading
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    close_dialog() {
      this.$emit('set_commission_dialog')
    }
  },
}
</script>