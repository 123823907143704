<template>
  <v-container fluid>
    <v-card class="shadow">
      <v-card-title class="text-h6 font-weight-regular">
        <span>My Commissions</span>
      </v-card-title>

      <v-card-text class="d-flex flex-column" style="gap:1rem">
        <div class="d-flex flex-sm-row flex-column justify-space-between" style="gap:.75rem">
          <div class="d-flex flex-sm-row flex-column justify-end"
            :style="$vuetify.breakpoint.mdAndUp ? 'gap:1rem' : 'gap:.60rem'">
            <div class="d-flex flex-sm-row flex-column" style="gap:.30rem">
              <v-btn depressed color="primary" :disabled="!selected.length" @click="set_confirmation_dialog">
                Request <v-icon class="ml-1">mdi-hand-coin-outline</v-icon>
              </v-btn>

              <v-btn depressed outlined color="grey" @click="history_dialog = !history_dialog">
                History <v-icon class="ml-1">mdi-history</v-icon>
              </v-btn>
            </div>

            <div class="d-flex flex-sm-row flex-column" style="gap:.60rem">
              <div class="d-flex" style="gap:1rem">
                <v-avatar class="blue" rounded size="40">
                  <v-icon dark> mdi-set-all </v-icon>
                </v-avatar>

                <div class="d-flex flex-column">
                  <small class="grey--text">TOTAL</small>
                  <span class="font-weight-bold">
                    {{ `Php ${money_format(parseFloat(total_commission))}` }}
                  </span>
                </div>
              </div>

              <div class="d-flex" style="gap:1rem">
                <v-avatar class="blue" rounded size="40">
                  <v-icon dark> mdi-check-all </v-icon>
                </v-avatar>

                <div class="d-flex flex-column">
                  <small class="grey--text">SELECTED</small>
                  <span class="font-weight-bold">
                    {{ `Php ${money_format(parseFloat(total_selected))}` }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-end" style="gap:.30rem">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="grey lighten-5" icon :disabled="records_loading" @click="doGetMyCommissions" v-bind="attrs"
                  v-on="on">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="grey lighten-5" icon :disabled="records_loading" @click="set_filter_dialog" v-bind="attrs"
                  v-on="on">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </div>
        </div>

        <v-data-table v-model="selected" :show-select="status == 'Pending'" :headers="headers" :items="records"
          :items-per-page="-1" :hide-default-footer="true" :search="search" :loading="records_loading"
          loading-text="Loading... Please wait" item-key="created_at" style="overflow-y: auto; max-height: 100vh;">

          <template v-slot:[`item.bets`]="{ item }">
            <a @click.prevent="set_raw_commission_dialog(item)" style="font-size: 13px;">
              {{ `View (${number_format(parseInt(item.bets))})` }}
            </a>
          </template>

          <template v-slot:[`item.bet_amount`]="{ item }">
            {{ `Php ${money_format(parseFloat(item.bet_amount))}` }}
          </template>

          <template v-slot:[`item.commission_percent`]="{ item }">
            {{ `${money_format(parseFloat(item.commission_percent))}%` }}
          </template>

          <template v-slot:[`item.commission`]="{ item }">
            <v-chip class="font-weight-bold green lighten-5 green--text">
              {{ `Php ${money_format(parseFloat(item.commission))}` }}
            </v-chip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip class="font-weight-bold" :class="item.status == 'Pending' ? 'blue-grey lighten-5 blue-grey--text' : item.status == 'Requested' ? 'teal lighten-5 teal--text' :
              item.status == 'Verified' ? 'blue lighten-5 blue--text' : 'green lighten-5 green--text'">
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.created_at) }}
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>

    <DateFilter v-if="filter_dialog" :dates="dates" @set_close_date_filter_dialog="set_close_date_filter_dialog" />
    <RequestConfirmation v-if="confirmation_dialog" :data="confirmation_data || status != 'Pending'"
      @set_close_confirmation_dialog="set_close_confirmation_dialog" />
    <History v-if="history_dialog" @set_close_history_dialog="set_close_history_dialog" />
    <RawCommission v-if="raw_commission_dialog" :data="raw_commission_data"
      @set_close_raw_commission_dialog="set_close_raw_commission_dialog" />
  </v-container>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import DateFilter from '@/components/app/commissions/DateFilter'
import RequestConfirmation from '@/components/app/commissions/RequestConfirmation'
import History from '@/components/app/commissions/History'
import formatDate from '@/helpers/formatDate'
import RawCommission from '@/components/app/commissions/RawCommission.vue'

export default {
  components: { DateFilter, RequestConfirmation, History, RawCommission },

  data: () => ({
    search: '',
    records: [],
    records_loading: false,
    dates: {},
    filter_dialog: false,
    date_from: null,
    date_to: null,
    status: 'Pending',
    selected: [],
    confirmation_dialog: false,
    confirmation_data: {},
    raw_commission_dialog: false,
    raw_commission_data: {},
    history_dialog: false,
    headers: [
      {
        text: "Bets",
        value: "bets",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Commission",
        value: "commission",
        class: "text-uppercase",
        align: "right"
      },
      {
        text: "Commission %",
        value: "commission_percent",
        class: "text-uppercase",
        align: "right"
      },
      {
        text: "Bet Amount",
        value: "bet_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase",
      },
      {
        text: "Bet Date",
        value: "created_at",
        class: "text-uppercase",
      },
    ]
  }),

  computed: {
    total_commission() {
      if (this.records)
        return this.records.reduce((acc, curr) => acc + parseFloat(curr.commission), 0);

      return 0;
    },

    total_selected() {
      if (this.selected)
        return this.selected.reduce((acc, curr) => acc + parseFloat(curr.commission), 0);

      return 0;
    },
  },

  mounted() {
    this.check_query_string()
    this.doGetMyCommissions()
  },

  methods: {
    async doGetMyCommissions() {
      this.records = []
      this.records_loading = !this.records_loading

      const response = await responseGet(this.set_form_data(), 'my_commission')

      switch (response.status) {
        case 200:
          this.records = response.data.records
          break;
      }

      this.records_loading = !this.records_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append("date_from", (this.date_from) ?? '');
      form_data.append("date_to", (this.date_to) ?? (this.date_from) ?? '');
      form_data.append("status", this.status)

      return new URLSearchParams(form_data)
    },

    set_confirmation_dialog() {
      this.confirmation_data = {
        'date_from': (this.date_from) ?? '',
        'date_to': (this.date_to) ?? '',
        'commission': this.money_format(parseFloat(this.total_selected)),
        'selected': this.selected
      }

      this.confirmation_dialog = !this.confirmation_dialog
    },

    set_close_confirmation_dialog(status) {
      if (status) {
        this.selected = []
        this.doGetMyCommissions()
      }

      this.confirmation_dialog = !this.confirmation_dialog
    },

    set_raw_commission_dialog(item) {
      this.raw_commission_data = {
        'bet_date': item.created_at,
        'status': item.status
      }

      this.raw_commission_dialog = !this.raw_commission_dialog
    },

    set_close_raw_commission_dialog() {
      this.raw_commission_dialog = !this.raw_commission_dialog
    },

    set_filter_dialog() {
      this.dates = {
        'date_from': this.date_from,
        'date_to': this.date_to,
        'status': this.status
      }

      this.filter_dialog = true
    },

    set_close_date_filter_dialog(data) {
      if (Object.keys(data).length) {
        this.date_from = data.date_from
        this.date_to = data.date_to
        this.status = data.status

        if (this.status != 'Pending') this.selected = []

        this.doGetMyCommissions()
        this.set_query_string()
      }

      this.filter_dialog = !this.filter_dialog
    },

    set_close_history_dialog() {
      this.history_dialog = !this.history_dialog
    },

    check_query_string() {
      const urlQuery = this.$route.query;

      if (Object.keys(urlQuery).length) {
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
          this.date_from = urlQuery.date_from;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
          this.date_to = urlQuery.date_to;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "status"))
          this.status = urlQuery.status;
      }
    },

    set_query_string() {
      const urlQuery = this.$route.query;
      this.query_filters = {};

      if (urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to ||
        urlQuery.status != this.status
      ) {
        if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
        if (this.date_to != null) this.query_filters["date_to"] = this.date_to;
        if (this.status != null) this.query_filters["status"] = this.status;

        this.$router.replace({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    format_date(date) {
      return formatDate(date, 'custom_date')
    }
  }
}
</script>