<template>
  <div style="min-height: 100vh">
    <div class="pa-4 pa-md-6">
      <v-snackbar v-model="commission_status_alert">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="commission_status_alert = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-card class="shadow mb-4" style="overflow: hidden">
        <v-tabs v-model="tab" next-icon="mdi-arrow-right" prev-icon="mdi-arrow-left" show-arrows>
          <v-tab v-if="appData && appData.account_details.investor_type_id != 1" @click="filter_type = 1" href="#tab-1"
            class="pa-4 pa-md-6" style="white-space: nowrap">
            <v-badge color="red" inline :value="my_commission ? my_commission : 0"
              :content="my_commission ? my_commission : '0'">
              My Commission
            </v-badge>
          </v-tab>

          <v-tab @click="filter_type = 2" href="#tab-2" class="pa-4 pa-md-6" style="white-space: nowrap">
            <v-badge color="red" inline :value="my_downline_commission ? my_downline_commission : 0"
              :content="my_downline_commission ? my_downline_commission : '0'">
              My Downline Commissions
            </v-badge>
          </v-tab>

          <v-tab v-if="appData && appData.account_details.investor_type_id != 1" @click="filter_type = 3" href="#tab-3"
            class="pa-4 pa-md-6" style="max-width: unset !important; white-space: nowrap">
            <span v-if="pending_for_approval">
              <span v-if="superior_investor_type == '1'">
                MA - Pending for Approval
              </span>

              <span v-else-if="superior_investor_type == '2'">
                SM - Pending for Approval
              </span>

              <span v-else-if="superior_investor_type == '3'">
                SS - Pending for Approval
              </span>
            </span>

            <span v-else>Pending for Approval</span>
          </v-tab>

          <v-tab @click="filter_type = 4" href="#tab-4" class="pa-4 pa-md-6" style="white-space: nowrap">
            Transaction Logs
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1" v-if="appData && appData.account_details.investor_type_id != 1">
            <MyCommission @update_my_commission_badge="update_my_commission_badge" />
          </v-tab-item>

          <v-tab-item value="tab-2">
            <MyDownlineCommission @update_my_downline_commission_badge="update_my_downline_commission_badge" />
            <!-- <div class="d-block">
              <div class="d-flex align-center justify-space-between pa-4 pa-md-6" style="gap: 1.5rem">
                <div class="d-flex align-center" style="gap: 1.5rem">
                  <h3 class="d-flex align-center pa-0" style="gap: 1.5rem">
                    <v-avatar class="blue" rounded size="40">
                      <v-icon dark> mdi-stairs-down </v-icon>
                    </v-avatar>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="font-weight-bold">
                          {{ "₱" + money_format(parseFloat(total_sub_agent_commission)) }}
                        </span>
                      </template>
                      <span>Your downline commission total amount</span>
                    </v-tooltip>
                  </h3>
                </div>
                <div class="d-inline-flex align-center ms-auto" style="gap: 1rem">
                  <v-text-field v-if="sub_agent_commission && sub_agent_commission.length != 0"
                    v-model="sub_agent_commission_search" label="Search" single-line outlined dense clearable
                    :disabled="sub_agent_commission_loading" autofocus placeholder="Search" hide-details="auto" />
                  <v-btn icon @click="filter_dialog = true">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </div>
              </div>

              <v-divider></v-divider>

              <v-data-table v-model="sub_agent_commission_selected" :headers="sub_agent_commission_headers"
                :items="sub_agent_commission" :items-per-page="5" :loading="sub_agent_commission_loading"
                :hide-default-footer="sub_agent_commission.length <= 5 ? true : false" loading-text="Loading, Please wait"
                :search="sub_agent_commission_search" item-key="id" show-select>
                <template v-slot:[`item.name`]="{ item }">
                  {{ item.name }}
                </template>

                <template v-slot:[`item.amount`]="{ item }">
                  <span class="font-weight-bold">
                    {{ "₱" + money_format(parseFloat(item.amount)) }}
                  </span>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-chip small style="line-height: 0.8; padding: 4px 10px !important" :class="item.status == 'Settled'
                      ? 'green lighten-5 green--text'
                      : 'orange lighten-5 orange--text'
                    ">
                    {{ item.status }}
                  </v-chip>
                </template>

                <template v-slot:[`item.requested_at`]="{ item }">
                  <span class="text-uppercase">
                    {{ date_format(item.requested_at) }}
                  </span>
                </template>
              </v-data-table>
              <div v-if="sub_agent_commission && sub_agent_commission.length != 0">
                <v-divider></v-divider>
                <div class="d-flex justify-end pa-4 pa-md-6">
                  <v-btn :disabled="sub_agent_commission_selected.length == 0" class="primary" depressed
                    @click="sub_agent_dialog = true">
                    Submit
                  </v-btn>
                </div>
              </div>
            </div> -->
          </v-tab-item>

          <v-tab-item value="tab-3" v-if="appData && appData.account_details.investor_type_id != 1">
            <!-- update_pending_for_approval -->
            <PendingForApproval @update_pending_for_approval="update_pending_for_approval" />
            <!-- <div class="d-block">
              <div class="d-flex align-center justify-space-between pa-4 pa-md-6" style="gap: 1.5rem">
                <div class="d-flex align-center" style="gap: 1.5rem">
                  <h3 class="d-flex align-center pa-0" style="gap: 1.5rem">
                    <v-avatar class="blue" rounded size="40">
                      <v-icon dark> mdi-account-clock </v-icon>
                    </v-avatar>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="font-weight-bold">
                          {{
                            "₱" +
                            money_format(
                              parseFloat(total_pending_for_approval_commission)
                            )
                          }}
                        </span>
                      </template>
                      <span>Your commission pending for approval</span>
                    </v-tooltip>
                  </h3>
                </div>
                <div class="d-inline-flex align-center ms-auto" style="gap: 1rem">
                  <v-text-field v-if="pending_for_approval_commission &&
                      pending_for_approval_commission.length != 0
                      " v-model="pending_for_approval_commission_search" label="Search" single-line outlined dense
                    clearable :disabled="pending_for_approval_commission_loading" autofocus placeholder="Search"
                    hide-details="auto" />
                  <v-btn icon @click="filter_dialog = true">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </div>
              </div>

              <v-divider></v-divider>

              <v-data-table :headers="pending_for_approval_commission_headers" :items="pending_for_approval_commission"
                :items-per-page="5" :loading="pending_for_approval_commission_loading" :hide-default-footer="pending_for_approval_commission.length <= 5 ? true : false
                    " loading-text="Loading, Please wait" :search="pending_for_approval_commission_search">
                <template v-slot:[`item.name`]="{ item }">
                  {{ item.name }}
                </template>

                <template v-slot:[`item.type`]="{ item }">
                  <v-chip :class="item.type == 'Agent'
                      ? 'blue lighten-5 primary--text'
                      : 'green lighten-5 green--text'
                    " small>
                    {{ item.type }}
                  </v-chip>
                </template>

                <template v-slot:[`item.amount`]="{ item }">
                  <span class="font-weight-bold">
                    {{ "₱" + money_format(parseFloat(item.amount)) }}
                  </span>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-chip small style="line-height: 0.8; padding: 4px 10px !important" :class="item.status == 'Settled'
                      ? 'green lighten-5 green--text'
                      : 'orange lighten-5 orange--text'
                    ">
                    {{ item.status }}
                  </v-chip>
                </template>

                <template v-slot:[`item.requested_at`]="{ item }">
                  <span class="text-uppercase">
                    {{ date_format(item.requested_at) }}
                  </span>
                </template>
              </v-data-table>
            </div> -->
          </v-tab-item>

          <v-tab-item value="tab-4">
            <TransactionLogs />
            <!-- <div class="d-block">
              <div class="d-flex align-center justify-space-between pa-4 pa-md-6" style="gap: 1.5rem">
                <v-avatar class="blue" rounded size="40">
                  <v-icon dark> mdi-history </v-icon>
                </v-avatar>
                <div class="d-inline-flex align-center ms-auto" style="gap: 1rem">
                  <div class="d-flex" style="gap: 1rem">
                    <v-select style="max-width: 219.2px" v-model="commission_status" :items="commission_status_items"
                      outlined status dense :hide-details="true" label="Status"></v-select>
                    <v-text-field v-if="history_commission && history_commission.length != 0"
                      v-model="history_commission_search" label="Search" single-line outlined dense clearable
                      :disabled="history_commission_loading" autofocus placeholder="Search" hide-details="auto" />
                  </div>
                  <v-btn icon @click="filter_dialog = true">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </div>
              </div>

              <v-divider></v-divider>

              <v-data-table :headers="set_history_commission_headers()" :items="history_commission" :items-per-page="5"
                :loading="history_commission_loading" :hide-default-footer="history_commission.length <= 5 ? true : false"
                loading-text="Loading, Please wait" :search="history_commission_search">
                <template v-slot:[`item.name`]="{ item }">
                  {{ item.name }}
                </template>

                <template v-slot:[`item.type`]="{ item }">
                  <v-chip :class="item.type == 'Agent'
                      ? 'blue lighten-5 primary--text'
                      : 'green lighten-5 green--text'
                    " small>
                    {{ item.type }}
                  </v-chip>
                </template>

                <template v-slot:[`item.amount`]="{ item }">
                  <span class="font-weight-bold">
                    {{ "₱" + money_format(parseFloat(item.amount)) }}
                  </span>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-chip small style="line-height: 0.8; padding: 4px 10px !important" :class="item.status == 'Settled'
                      ? 'green lighten-5 green--text'
                      : 'orange lighten-5 orange--text'
                    ">
                    {{ item.status }}
                  </v-chip>
                </template>

                <template v-slot:[`item.requested_at`]="{ item }">
                  <span class="text-uppercase">
                    {{ date_format(item.requested_at) }}
                  </span>
                </template>

                <template v-slot:[`item.settled_at`]="{ item }">
                  <span class="text-uppercase">
                    {{ date_format(item.settled_at) }}
                  </span>
                </template>
              </v-data-table>
            </div> -->
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>

    <!-- Sub Agent Confirm Dialog -->
    <!-- <v-row justify="center">
      <v-dialog v-model="sub_agent_dialog" :persistent="sub_agent_dialog_loading" max-width="500">
        <v-card class="shadow">
          <div class="pa-4 pa-md-6">
            <h3 class="font-weight-bold mb-2">Please Confirm</h3>
            <div class="grey--text mb-3 mb-md-5">
              You're about to approve this
              {{
                sub_agent_commission_selected && sub_agent_commission_selected.length > 1
                ? "requests"
                : "request"
              }}, do you really want to proceed?
            </div>
            <div class="rounded overflow-hidden" style="border: 1px solid rgba(0, 0, 0, 0.12)">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">Requested By</th>
                      <th class="text-uppercase text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in sub_agent_commission_selected" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td class="text-right">
                        {{ "₱" + money_format(parseFloat(item.amount)) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
          <div class="d-flex justify-end px-3 px-md-6 pb-4 pb-md-6" style="gap: 0.5rem">
            <v-btn :disabled="sub_agent_dialog_loading" plain @click="sub_agent_dialog = false">
              No
            </v-btn>
            <v-btn :loading="sub_agent_dialog_loading" color="primary" depressed @click="request_sub_agent_commission()">
              {{ "Yes — ₱" + money_format(parseFloat(total_sub_agent_commission)) }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-row> -->

    <!-- Filter Dialog -->
    <v-row justify="center">
      <v-dialog v-model="filter_dialog" :persistent="filter_dialog_loading" max-width="500">
        <v-card class="shadow">
          <div class="pa-4 pa-md-6">
            <h3 class="font-weight-bold mb-4 mb-md-6">
              Filter By
              <span v-if="appData && appData.account_details.investor_type_id != 1">
                <span v-if="filter_type == 1"> Bet Date </span>
                <span v-else-if="filter_type == 2 || filter_type == 3 || filter_type == 4">Date Requested</span>
              </span>
              <span v-else> Date Requested </span>
            </h3>

            <v-form ref="form" @submit.prevent="filter_requests()" :disabled="filter_dialog_loading">
              <div class="d-flex flex-column" style="gap: 1rem">
                <v-menu v-model="date_from_menu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :rules="[rules.required]" v-model="date_from" label="Date From" clearable readonly
                      outlined dense hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="date_from" @input="date_from_menu = false"></v-date-picker>
                </v-menu>
                <v-menu v-model="date_to_menu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :rules="[rules.required]" v-model="date_to" label="Date To" clearable readonly outlined
                      dense hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="date_to" @input="date_to_menu = false"></v-date-picker>
                </v-menu>
              </div>
              <div class="d-flex justify-end mt-4 mt-md-6" style="gap: 0.5rem">
                <v-btn :disabled="filter_dialog_loading" plain @click="filter_dialog = false">
                  Cancel
                </v-btn>
                <v-btn :loading="filter_dialog_loading" color="primary" depressed type="submit">
                  Filter
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import responseGet from "@/helpers/api_request_get";
// import responsePost from "@/helpers/api_request_post";
import formatDate from "@/helpers/formatDatev2";
import formRules from "@/helpers/formRules";
import MyCommission from "@/components/app/approve_commissions/tab_items/MyCommission"
import MyDownlineCommission from "@/components/app/approve_commissions/tab_items/MyDownlineCommission"
import PendingForApproval from "@/components/app/approve_commissions/tab_items/PendingRequest"
import TransactionLogs from "@/components/app/approve_commissions/tab_items/TransactionLogs"

export default {
  components: { MyCommission, MyDownlineCommission, PendingForApproval, TransactionLogs },

  data: () => ({
    rules: formRules,
    tab: 1,

    filter_type: 1,
    filter_dialog: false,
    filter_dialog_loading: false,

    date_from_menu: false,
    date_from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date_to_menu: false,
    date_to: "",

    my_commission: 0,
    my_downline_commission: 0,
    pending_for_approval: 0,
    superior_investor_type: "",

    // master_agent_sub_agent_commission: 0,

    // sub_agent_commission: [],
    // sub_agent_commission_loading: false,
    // sub_agent_commission_search: "",
    // sub_agent_commission_selected: [],
    // sub_agent_commission_selected_id: [],

    // pending_for_approval_commission: [],
    // pending_for_approval_commission_loading: false,
    // pending_for_approval_commission_search: "",

    history_commission: [],
    history_commission_loading: false,
    history_commission_search: "",

    commission_status: "Approved",
    commission_status_items: ["Approved", "Requested"],

    master_agent_sub_agent_dialog: false,
    master_agent_sub_agent_dialog_loading: false,

    sub_agent_dialog: false,
    sub_agent_dialog_loading: false,

    requestor_name: "",
    request_id: "",

    superior_investor: "",

    message: "",

    commission_status_alert: false,
  }),

  mounted() {
    // this.get_sub_agent_commission();
    // this.get_commission_pending_for_approval();
    // this.get_history_commission();
  },

  methods: {
    // Pag approve ng commission ng mga downline
    // async request_master_agent_sub_agent_commission() {
    //   if (this.master_agent_sub_agent_commission_selected.length != 0) {
    //     this.master_agent_sub_agent_dialog_loading = true;

    //     this.master_agent_sub_agent_commission_selected.forEach((element) => {
    //       this.master_agent_sub_agent_commission_selected_date.push(
    //         element.formatted_created_at
    //       );
    //     });

    //     const form_data = new FormData();

    //     form_data.append(
    //       "dates",
    //       JSON.stringify(this.master_agent_sub_agent_commission_selected_date)
    //     );
    //     form_data.append("total_amount", this.total_master_agent_sub_agent_commission);

    //     const res = await responsePost(
    //       form_data,
    //       "commission/master_agent/update_commission"
    //     );

    //     switch (res.status) {
    //       case 200:
    //         this.master_agent_sub_agent_dialog = false;
    //         this.get_master_agent_sub_agent_commission();
    //         this.get_commission_pending_for_approval();
    //         this.get_history_commission();
    //         this.message = "Your request has been successfully requested.";
    //         this.commission_status_alert = true;
    //         break;
    //       case 409:
    //         this.message = "Your request has failed.";
    //         this.commission_status_alert = true;

    //         break;
    //       case 401:
    //         this.message = "Your request is unauthorized, you will be logout.";
    //         this.commission_status_alert = true;

    //         setTimeout(() => {
    //           localStorage.removeItem("userToken");
    //           this.$router.push({ name: "Home" });
    //           location.reload();
    //         }, 3000);

    //         break;
    //     }

    //     this.master_agent_sub_agent_commission_selected = [];
    //     this.master_agent_sub_agent_commission_selected_date = [];
    //     this.master_agent_sub_agent_dialog_loading = false;
    //   }
    // },

    // Pag request ng commission ng mga downlines 
    // async request_sub_agent_commission() {
    //   if (this.sub_agent_commission_selected.length != 0) {
    //     this.sub_agent_dialog_loading = true;

    //     this.sub_agent_commission_selected.forEach((element) => {
    //       this.sub_agent_commission_selected_id.push(element.id);
    //     });

    //     const form_data = new FormData();

    //     form_data.append(
    //       "request_ids",
    //       JSON.stringify(this.sub_agent_commission_selected_id)
    //     );
    //     form_data.append("total_amount", this.total_sub_agent_commission);

    //     const res = await responsePost(
    //       form_data,
    //       "commissions/update_commission_downline"
    //     );

    //     switch (res.status) {
    //       case 200:
    //         this.sub_agent_dialog = false;
    //         this.get_sub_agent_commission();
    //         this.get_commission_pending_for_approval();
    //         this.get_history_commission();
    //         this.message = "Your request has been successfully approved.";
    //         this.commission_status_alert = true;
    //         break;
    //       case 409:
    //         this.message = "Your request has failed.";
    //         this.commission_status_alert = true;

    //         break;
    //       case 401:
    //         this.message = "Your request is unauthorized, you will be logout.";
    //         this.commission_status_alert = true;

    //         setTimeout(() => {
    //           localStorage.removeItem("userToken");
    //           this.$router.push({ name: "Home" });
    //           location.reload();
    //         }, 3000);

    //         break;
    //     }

    //     this.sub_agent_commission_selected = [];
    //     this.sub_agent_commission_selected_id = [];
    //     this.sub_agent_dialog_loading = false;
    //   }
    // },

    // Pag kuha ng mga pending for approval ng mga downlines
    // async get_commission_pending_for_approval() {
    //   this.pending_for_approval_commission_loading = true;

    //   const form_data = new FormData();

    //   form_data.append("date_from", this.date_from);
    //   form_data.append("date_to", this.date_to);

    //   const res = await responseGet(
    //     new URLSearchParams(form_data),
    //     "commissions/commission_pending_for_approval"
    //   );

    //   if (res.status == 200) {
    //     this.pending_for_approval_commission = res.data.data.pending;
    //     this.superior_investor = res.data.data.superior_investor;
    //     this.superior_investor_type = res.data.data.superior_investor_type_id;
    //   }

    //   this.pending_for_approval_commission_loading = false;
    // },

    // pag kuha ng mga transaction logs
    // async get_history_commission() {
    //   this.history_commission_loading = true;

    //   const form_data = new FormData();

    //   form_data.append("commission_status", this.commission_status);
    //   form_data.append("date_from", this.date_from);
    //   form_data.append("date_to", this.date_to);

    //   const res = await responseGet(
    //     new URLSearchParams(form_data),
    //     "commissions/commission_history"
    //   );

    //   if (res.status == 200) {
    //     this.history_commission = res.data.data;
    //   }

    //   this.history_commission_loading = false;
    // },
    // set_history_commission_headers() {
    //   return [
    //     {
    //       text: this.commission_status == "Approved" ? "Approved By" : "Requested By",
    //       value: "name",
    //       class: "text-uppercase",
    //     },
    //     { text: "Type", value: "type", class: "text-uppercase" },
    //     {
    //       text: "Commission Amount",
    //       value: "amount",
    //       class: "text-uppercase",
    //       align: "right",
    //     },
    //     { text: "Date Requested", value: "requested_at", class: "text-uppercase" },
    //     { text: "Date Settled", value: "settled_at", class: "text-uppercase" },
    //   ];
    // },

    filter_requests() {
      if (this.$refs.form.validate()) {
        this.filter_dialog = false;

        switch (this.filter_type) {
          case 1:
            this.get_master_agent_sub_agent_commission();
            break;
          case 2:
            this.get_sub_agent_commission();
            break;
          case 3:
            this.get_commission_pending_for_approval();
            break;
          case 4:
            this.get_history_commission();
            break;
        }
      }
    },
    compare_date_from_and_date_to() {
      const date_from = new Date(this.date_from);
      const date_to = new Date(this.date_to);

      if (date_to.getTime() < date_from.getTime()) {
        this.date_to = "";
      }
    },

    update_my_commission_badge(val) {
      this.my_commission = val
    },

    update_my_downline_commission_badge(val) {
      this.my_downline_commission = val
    },

    update_pending_for_approval(data) {
      this.pending_for_approval = data.pending_for_approval
      this.superior_investor_type = data.superior_investor_type
    },

    money_format(val) {
      return val.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    date_format(date) {
      return formatDate(date, "datetime");
    },
  },
  computed: {
    ...mapState(["userData", "appData"]),

    // total_master_agent_sub_agent_commission() {
    //   if (this.master_agent_sub_agent_commission_selected) {
    //     const sum = this.master_agent_sub_agent_commission_selected.reduce(
    //       (acc, curr) => acc + parseFloat(curr.total_commission_amount),
    //       0
    //     );

    //     return sum;
    //   }

    //   return 0;
    // },
    // total_pending_for_approval_commission() {
    //   if (this.pending_for_approval_commission) {
    //     const sum = this.pending_for_approval_commission.reduce(
    //       (acc, curr) => acc + parseFloat(curr.amount),
    //       0
    //     );

    //     return sum;
    //   }

    //   return 0;
    // },
  },
  watch: {
    date_from() {
      this.compare_date_from_and_date_to();
    },
    date_to() {
      this.compare_date_from_and_date_to();
    },
    commission_status() {
      this.get_history_commission();
    },
  },
};
</script>

<!-- <style scoped>
.settled-commission-wrapper {
  max-height: unset;
}

@media (min-width: 960px) {
  .settled-commission-wrapper {
    max-height: calc(100vh - 24px - 24px - 48px - 68px - 1px);
    overflow: auto;
  }
}

.settled-commission-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--dark .settled-commission-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.settled-commission-item:last-of-type {
  border-bottom: none !important;
}
</style> -->
