<template>
  <v-card class="shadow">
    <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
      Accounts
    </v-card-title>

    <v-card-text v-if="!accounts_loading">
      <AccountBarGraph v-if="Object.keys(accounts_bar_data).length" :chart_data="accounts_bar_data" />
      <div v-else>
        <div class="d-flex flex-column align-center">
          <v-icon>
            mdi-database-off-outline
          </v-icon>

          <span class="text-h6 font-weight-regular mt-2">
            No account available.
          </span>
        </div>
      </div>
    </v-card-text>

    <div v-else class="d-flex justify-center align-center pb-4" style="gap:.50rem; height: 230px;">
      <v-progress-circular :size="20" :width="3" color="grey" indeterminate></v-progress-circular>
      <span>Fetching information...</span>
    </div>
  </v-card>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import AccountBarGraph from '@/components/app/dashboard/AccountsBarGraph'
import { mapState } from 'vuex'

export default {
  components: { AccountBarGraph },

  data: () => ({
    accounts: [],
    accounts_loading: false,
    accounts_bar_data: {}
  }),

  computed: {
    ...mapState(["appData"])
  },

  mounted() {
    if (this.appData) {
      this.doGetAccounts()
    }
  },

  methods: {
    async doGetAccounts() {
      this.accounts_loading = true
      this.accounts = []

      const response = await responseGet(this.set_form_data(), 'accounts')

      if (response.status == 200) {
        this.accounts = response.data.records

        let labels = []
        let data_sets = []

        this.accounts.forEach((element) => {
          labels.push(element.type)
          data_sets.push(parseInt(element.count))
        });

        if (labels.length && data_sets.length) {
          this.accounts_bar_data = {
            labels: labels,
            data_sets: data_sets
          }
        }
      }

      this.accounts_loading = false
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('investor_type_id', this.appData.account_details.investor_type_id)

      return new URLSearchParams(form_data)
    }
  },

  watch: {
    appData(val) {
      if (val) {
        this.doGetAccounts()
      }
    }
  }
}
</script>