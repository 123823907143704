var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"d-flex justify-space-between text-h6 font-weight-bold"},[_vm._v(" Approve Payouts ")]),_c('v-card-text',{staticClass:"d-flex flex-column",staticStyle:{"gap":"0.6rem"}},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"shadow text-center"},[_c('span',{staticClass:"text-overline"},[_vm._v("Total For Approval")]),_c('v-divider',{staticClass:"mx-2"}),_c('v-card-text',{staticClass:"py-4"},[_c('span',{staticStyle:{"font-size":"22px"}},[_vm._v(_vm._s(("Php " + (_vm.money_format(parseFloat(_vm.total_for_approval))))))])])],1)],1)],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.payouts,"items-per-page":_vm.$vuetify.breakpoint.mdAndUp ? 10 : 5,"search":_vm.search,"loading":_vm.payouts_loading || _vm.appDataLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"gap":"0.3rem"}},[_c('v-text-field',{ref:"search",attrs:{"label":"Search","single-line":"","outlined":"","dense":"","disabled":_vm.payouts_loading,"autofocus":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0 grey lighten-4",attrs:{"icon":"","color":_vm.filter_status ? 'primary' : '#424242'},on:{"click":function($event){return _vm.set_filter_dialog()}}},on),[_c('v-icon',[_vm._v(_vm._s(("mdi-" + (_vm.filter_status ? "filter-check-outline" : "filter"))))])],1)]}}])},[_vm._v(" Filter ")])],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"dense":"","icon":"","small":"","color":"primary","disabled":item.status == 'Approved'},on:{"click":function($event){return _vm.set_confirmation_dialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-tag-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Tag as approve")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-list',{staticClass:"py-0 my-0",staticStyle:{"background":"transparent"}},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":"42px"}},[_c('v-icon',{attrs:{"dark":"","color":"grey","size":"42px"}},[_vm._v(" mdi-account-circle ")])],1),_c('v-list-item-content',{staticClass:"text-left"},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1)],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.amount)))))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",class:item.status == 'Approved'
                ? 'green lighten-5 green--text'
                : 'orange lighten-5 orange--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")]}}],null,true)})],1)],1),(_vm.filter_dialog)?_c('FilterDate',{attrs:{"filter_dialog_data":_vm.filter_dialog_data},on:{"set_close_filter_dialog":_vm.set_close_filter_dialog}}):_vm._e(),(_vm.confirmation_dialog)?_c('Confirmation',{attrs:{"confirmation_data":_vm.confirmation_data},on:{"set_close_confirmation_dialog":_vm.set_close_confirmation_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }