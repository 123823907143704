<template>
  <v-dialog persistent v-model="dialog" max-width="350">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        <span>Confirm</span>

        <v-btn plain icon @click="close_dialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <v-form @submit.prevent="do_approve" class="d-flex flex-column" style="gap:1rem" :disabled="payouts_loading">
          <div class="d-flex flex-column text-center" style="gap:.5rem">
            <span>Confirmation of releasing payout.</span>
            <span class="success--text" style="font-size:24px">{{ `Php ${money_format(parseFloat(confirmation_data.amount))}`
            }}</span>
          </div>

          <div class="d-flex justify-end mt-4">
            <v-btn depressed text class="mr-1 px-2" color="grey" @click="close_dialog">
              Cancel
            </v-btn>

            <v-btn depressed type="submit" color="primary" class="px-2" :loading="payouts_loading">
              Confirm
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import responsePost from '@/helpers/api_request_post'

export default {
  props: {
    confirmation_data: {
      type: Object
    }
  },

  data: () => ({
    payouts_loading: false
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    async do_approve() {
      this.payouts_loading = true

      const response = await responsePost(this.set_form_data(), 'payouts/approve')

      if (response.status == 200) {
        this.$emit("set_close_confirmation_dialog", { id: this.confirmation_data.id })
      } else alert('Something went wrong, Please refresh the page!')

      this.payouts_loading = false
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('id', this.confirmation_data.id)
      form_data.append('investor_type_id', this.confirmation_data.investor_type_id)
      form_data.append('payout_id', this.confirmation_data.payout_id)

      return form_data
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    close_dialog() {
      this.$emit("set_close_confirmation_dialog", {})
    }
  }
}
</script>