<template>
  <v-card class="shadow">
    <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
      <span>Sales</span>

      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text small v-bind="attrs" v-on="on"
            class="text-capitalize font-weight-regulary px-2 grey--text text--darken-2" :disabled="records_loading">
            {{ filter }}
            <v-icon small class="ps-1">mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="item in filters" :key="item" @click="changeFilter(item)"
            :class="(item == filter) ? 'primary lighten-5 primary--text' : ''">
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-card-text>
      <div v-if="!records_loading" class="d-flex flex-column" style="gap: .75rem;">
        <div class="d-flex flex-column pa-4 rounded white--text" style="gap: .50rem; background: #191542;">
          <span>Total Bets</span>
          <span>{{ number_format(parseInt(records.bets)) }}</span>

          <v-divider style="width: 100%; background: white;"></v-divider>

          <div class="d-flex justify-space-between" style="width: 100%;">
            <span>{{ `SR ${number_format(parseInt(records.sales_representative))}`
            }}</span>
            <span>{{ `RP ${number_format(parseInt(records.regular_player))}` }}</span>
          </div>
        </div>

        <div class="d-flex flex-column pa-4 rounded white--text" style="gap: .50rem; background: #d86717;">
          <span>Total Bet Amount</span>
          <span>
            {{ `Php ${money_format(parseFloat(records.total_amount))}` }}
          </span>

          <v-divider style="width: 100%; background: white;"></v-divider>

          <div class="d-flex justify-space-between" style="width: 100%;">
            <span>
              {{ `SR Php ${money_format(parseFloat(records.sales_representative_amount))}` }}
            </span>
            <span>
              {{ `RP Php ${money_format(parseFloat(records.regular_player_amount))}` }}
            </span>
          </div>
        </div>
      </div>

      <div v-else class="d-flex flex-column pa-2" style="gap: .75rem;">
        <div class="d-flex flex-column align-center shadow py-6 px-2 rounded"
          style="border:1px solid #CFD8DC; gap: .50rem" v-for="index in 2" :key="index">

          <v-skeleton-loader class="mx-1" type="text" light height="13px" width="50%"></v-skeleton-loader>
          <v-skeleton-loader class="mx-1" type="text" light height="14px" width="50%"></v-skeleton-loader>

          <v-divider style="width: 100%;" />

          <div class="d-flex justify-space-around" style="width: 100%;">
            <v-skeleton-loader class="mx-1" type="text" light height="13px" width="30%"></v-skeleton-loader>
            <v-skeleton-loader class="mx-1" type="text" light height="14px" width="30%"></v-skeleton-loader>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-dialog ref="filterDialog" v-model="filterDialog" :return-value.sync="filterDate" persistent width="290px">
      <v-date-picker v-model="filterDate" scrollable range>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelFilter">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="saveFilter">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-card>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import formatDatev2 from "@/helpers/formatDatev2"
import { startOfWeek } from 'date-fns'
import { endOfWeek } from 'date-fns'
import { startOfMonth } from 'date-fns'
import { endOfMonth } from 'date-fns'
import { startOfYear } from 'date-fns'
import { endOfYear } from 'date-fns'

export default {
  data: () => ({
    records: [],
    records_loading: false,
    today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    filter: 'Today',
    filters: ['Today', 'This Week', 'This Month', 'This Year', 'Custom'],
    filterFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    filterTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    filterDialog: false,
    filterDate: [],
    filterDateText: '',
  }),

  mounted() {
    this.doGetSalesBets()
  },

  methods: {
    async doGetSalesBets() {
      this.records_loading = !this.records_loading

      const response = await responseGet(this.set_form_data(), 'sales/bets')

      switch (response.status) {
        case 200:
          this.records = response.data.records
          break
        case 401:
          localStorage.removeItem("userToken")
          this.$router.push({ name: "Home" })
          location.reload()
          break
      }

      this.records_loading = !this.records_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('date_from', this.filterFrom)
      form_data.append('date_to', this.filterTo)

      return new URLSearchParams(form_data)
    },

    changeFilter(val) {
      if (val == 'Today') {
        this.filterFrom = this.today
        this.filterTo = this.today
        this.filter = 'Today'
        this.filterDate = []
        this.doGetSalesBets()
      }

      if (val == 'This Week') {
        const startWeek = startOfWeek(new Date(this.today), { weekStartsOn: 1 }),
          endWeek = endOfWeek(new Date(this.today), { weekStartsOn: 1 })

        this.filterFrom = formatDatev2(startWeek, 'format', 'yyyy-MM-dd')
        this.filterTo = formatDatev2(endWeek, 'format', 'yyyy-MM-dd')
        this.filter = 'This Week'
        this.filterDate = []
        this.doGetSalesBets()
      }

      if (val == 'This Month') {
        const startMonth = startOfMonth(new Date(this.today)),
          endMonth = endOfMonth(new Date(this.today))

        this.filterFrom = formatDatev2(startMonth, 'format', 'yyyy-MM-dd')
        this.filterTo = formatDatev2(endMonth, 'format', 'yyyy-MM-dd')
        this.filter = 'This Month'
        this.filterDate = []
        this.doGetSalesBets()
      }

      if (val == 'This Year') {
        const startYear = startOfYear(new Date(this.today)),
          endYear = endOfYear(new Date(this.today))

        this.filterFrom = formatDatev2(startYear, 'format', 'yyyy-MM-dd')
        this.filterTo = formatDatev2(endYear, 'format', 'yyyy-MM-dd')
        this.filter = 'This Year'
        this.filterDate = []
        this.doGetSalesBets()
      }

      if (val == 'Custom') {
        this.filterDialog = true
      }
    },

    cancelFilter() {
      this.filterDialog = false
    },

    saveFilter() {
      if (!this.filterDate.length) {
        // nothing to do
      } else {
        this.$refs.filterDialog.save(this.filterDate)

        const minDate = new Date(this.filterDate[0])

        if (typeof this.filterDate[1] != 'undefined') {
          const maxDate = new Date(this.filterDate[1])

          if (minDate > maxDate) {
            this.filterFrom = formatDatev2(maxDate, 'format', 'yyyy-MM-dd')
            this.filterTo = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
          } else {
            this.filterFrom = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
            this.filterTo = formatDatev2(maxDate, 'format', 'yyyy-MM-dd')
          }

          this.filterDateText = formatDatev2(this.filterFrom, 'date') + ' to ' + formatDatev2(this.filterTo, 'date')
          this.doGetSalesBets()
        } else {
          this.filterFrom = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
          this.filterTo = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
          this.filterDateText = formatDatev2(this.filterFrom, 'date')
          this.doGetSalesBets()
        }

        this.filter = 'Custom'
      }
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
  }
}
</script>