<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" v-model="dialog" max-width="500">
    <v-card class="shadow">
      <div class="pa-4 pa-md-6">
        <h3 class="font-weight-bold mb-2">Please Confirm</h3>

        <v-alert v-if="alert_dialog" dense
          :class="approval_status ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
          {{ alert_message }}
        </v-alert>

        <div class="grey--text mb-3 mb-md-5">
          You're about to approve this
          {{ `request${records > 1 ? 's' : ''}` }}, do you really want to proceed?
        </div>

        <v-alert dense text class="blue lighten-5 blue--text">
          <div class="d-flex flex-column" style="gap:.75rem; font-size: 14px;">
            <span>—Tagging this as approved means that you will be paying the requestor.</span>
            <span>—This will require a mode of payment and a reference number.</span>
          </div>
        </v-alert>

        <div class="rounded overflow-hidden" style="border: 1px solid rgba(0, 0, 0, 0.12)">
          <v-simple-table style="max-height: 400px; overflow-y: auto;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">Requested By</th>
                  <th class="text-uppercase text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in records" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td class="text-right">
                    {{ `Php ${money_format(parseFloat(item.amount))}` }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>

      <v-form ref="form" @submit.prevent="doApproveDownlineCommission"
        class="d-flex flex-column px-3 px-md-6 pb-4 pb-md-6" style="gap:1rem">
        <v-text-field v-model="mode_of_payment" autofocus outlined dense label="Mode of payment (Optional)"
          placeholder="eg. GCash, Paymaya or any banks" hide-details="auto" />

        <v-text-field v-model="reference" outlined dense label="Reference (Optional)" placeholder="eg. 9812345678"
          hide-details="auto" />

        <v-textarea v-model="remarks" auto-grow outlined label="Remarks (Optional)" rows="2" row-height="20"
          placeholder="For additional info purposes" hide-details="auto" />

        <div class="d-flex justify-end" style="gap: 0.5rem">
          <v-btn plain @click="close_dialog" :disabled="approval_loading || approval_status">
            No
          </v-btn>

          <v-btn color="primary" depressed type="submit" :loading="approval_loading"
            :disabled="approval_loading || approval_status">
            {{ "Yes — Php " + money_format(parseFloat(total_commission)) }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formatDate from "@/helpers/formatDatev2"
import responsePost from "@/helpers/api_request_post"

export default {
  props: {
    records: {
      type: Array
    }
  },

  data: () => ({
    mode_of_payment: '',
    reference: '',
    remarks: '',
    approval_status: false,
    approval_loading: false,
    alert_dialog: false,
    alert_message: ''
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    },

    total_commission() {
      if (this.records) {
        const sum = this.records.reduce(
          (acc, curr) => acc + parseFloat(curr.amount), 0
        );

        return sum;
      }

      return 0;
    },
  },

  methods: {
    async doApproveDownlineCommission() {
      if (this.$refs.form.validate()) {
        this.approval_loading = true;

        let selected_ids = [];

        this.records.forEach((element) => {
          selected_ids.push({
            'id': element.id,
            'type': element.type
          });
        });

        const form_data = new FormData();

        form_data.append("request_ids", JSON.stringify(selected_ids))
        form_data.append("total_amount", this.total_commission)
        form_data.append("mode_of_payment", this.mode_of_payment)
        form_data.append("reference", this.reference)
        form_data.append("remarks", this.remarks)

        const res = await responsePost(form_data, "commissions/update_commission_downline");
        this.alert_dialog = true

        switch (res.status) {
          case 200:
            this.approval_status = true
            this.alert_message = "Your request has been successfully approved.";
            break;
          case 409:
            this.alert_message = "Your request has failed.";

            break;
          case 401:
            this.alert_message = "Your request is unauthorized, you will be logout.";

            setTimeout(() => {
              localStorage.removeItem("userToken");
              this.$router.push({ name: "Home" });
              location.reload();
            }, 3000);

            break;
        }

        this.approval_loading = false;
      }
    },

    money_format(val) {
      return val.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    date_format(date) {
      return formatDate(date, "datetime");
    },

    close_dialog() {
      this.$emit("set_close_confirmation_dialog", false)
    }
  },

  watch: {
    approval_status(val) {
      if (val) {
        setTimeout(() => {
          this.$emit("set_close_confirmation_dialog", true)
        }, 2000);
      }
    }
  }
}
</script>