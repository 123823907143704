var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"d-flex justify-space-between text-h6 font-weight-bold"},[_vm._v(" Agents ")]),_c('v-card-text',{staticClass:"d-flex flex-column"},[(_vm.appData)?_c('v-data-table',{attrs:{"headers":_vm.get_headers(),"items":_vm.agents,"items-per-page":_vm.$vuetify.breakpoint.mdAndUp ? 10 : 5,"search":_vm.search,"loading":_vm.agents_loading || _vm.appDataLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{ref:"search",attrs:{"label":"Search","single-line":"","outlined":"","dense":"","disabled":_vm.agents_loading,"autofocus":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"dense":"","icon":"","small":"","color":"primary","disabled":_vm.is_allow_to_add_token(item)},on:{"click":function($event){return _vm.set_add_token_dialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus-circle-multiple ")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Token")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-list',{staticClass:"py-0 my-0",staticStyle:{"background":"transparent"}},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":"42px"}},[_c('v-icon',{attrs:{"dark":"","color":"grey","size":"42px"}},[_vm._v(" mdi-account-circle ")])],1),_c('v-list-item-content',{staticClass:"text-left"},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.type))])],1)],1)],1)]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("+63" + (item.mobile_number)))+" ")]}},{key:"item.commission",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end align-center"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(((item.commission) + "%")))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2",attrs:{"depressed":"","small":"","outlined":"","color":"primary","disabled":_vm.is_allow_to_add_token(item)},on:{"click":function($event){return _vm.set_edit_commission_dialog(item)}}},'v-btn',attrs,false),on),[_c('span',{staticClass:" mr-1"},[_vm._v("Edit")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Commission")])])],1)]}},{key:"item.wallet",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:parseFloat(item.wallet) < 1000 ? 'red lighten-5 red--text text--lighten-1' : parseFloat(item.wallet) < 5000 ? 'orange lighten-5 orange--text text--lighten-1' : 'green lighten-5 green--text text--lighten-1',attrs:{"small":""}},[_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.wallet)))))+" ")])]}},{key:"item.sales_supervisor",fn:function(ref){
var item = ref.item;
return [(parseInt(_vm.appData.account_details.investor_type_id) == 1)?_c('a',{staticStyle:{"font-size":"13px"},attrs:{"disabled":!parseInt(item.sales_representativesales_representative)},on:{"click":function($event){$event.preventDefault();return _vm.set_accounts_dialog(1, item)}}},[_vm._v(_vm._s(("View (" + (_vm.number_format(parseInt(item.sales_supervisor))) + ")")))]):_vm._e()]}},{key:"item.sales_representative",fn:function(ref){
var item = ref.item;
return [(!parseInt(item.sales_representative))?_c('span',[_vm._v("0")]):_c('div',[_c('a',{staticStyle:{"font-size":"13px"},attrs:{"disabled":!parseInt(item.sales_representativesales_representative)},on:{"click":function($event){$event.preventDefault();return _vm.set_accounts_dialog(2, item)}}},[_vm._v(_vm._s(("View (" + (_vm.number_format(parseInt(item.sales_representative))) + ")")))])])]}},{key:"item.regular_player",fn:function(ref){
var item = ref.item;
return [(!parseInt(item.regular_player))?_c('span',[_vm._v(" 0 ")]):_c('div',[(parseInt(_vm.appData.account_details.investor_type_id) != 3)?_c('a',{class:!parseInt(item.regular_player) ? 'disabled' : '',staticStyle:{"font-size":"13px"},attrs:{"disabled":""},on:{"click":function($event){$event.preventDefault();return _vm.set_accounts_dialog(3, item)}}},[_vm._v(_vm._s(("View (" + (_vm.number_format(parseInt(item.regular_player))) + ")")))]):_vm._e()])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",class:item.status == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)}):_vm._e(),(_vm.add_token_dialog)?_c('AddToken',{attrs:{"account_details":_vm.account_details},on:{"set_close_add_token_dialog":_vm.set_close_add_token_dialog}}):_vm._e(),(_vm.accounts_dialog)?_c('Accounts',{attrs:{"accounts_data":_vm.accounts_data},on:{"set_close_account_dialog":_vm.set_close_account_dialog}}):_vm._e()],1)],1),(_vm.edit_commission_dialog)?_c('EditCommission',{attrs:{"commission_data":_vm.edit_commission_data},on:{"set_close_edit_commission_dialog":_vm.set_close_edit_commission_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }