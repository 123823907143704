<template>
  <div>
    <v-btn icon small @click="darkmode = false" v-if="darkmode">
      <v-icon>
        mdi-white-balance-sunny
      </v-icon>
    </v-btn>
    <v-btn icon small @click="darkmode = true" v-else>
      <v-icon>
        mdi-moon-waning-crescent
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    darkmode: false
  }),
  watch: {
    darkmode() {
      this.handledarkmode()
    }
  },
  created() {
    if (process.browser) {
      if (localStorage.getItem('darkMode')) {
        const cookieValue = localStorage.getItem('darkMode') === 'true'
        this.darkmode = cookieValue
      } else {
        this.handledarkmode()
      }
    }
  },
  methods: {
    handledarkmode() {
      if (process.browser) {
        if (this.darkmode === true) {
          this.$vuetify.theme.dark = true
          localStorage.setItem('darkMode', true)
        } else if (this.darkmode === false) {
          this.$vuetify.theme.dark = false
          localStorage.setItem('darkMode', false)
        }
      }
    }
  }
}
</script>
