<template>
  <v-container fluid class="px-md-7">
    <v-row v-if="appData && !records_loading" justify="center" class="mx-auto">
      <v-col
        cols="12"
        md
        align="center"
        v-if="appData.account_details.investor_type_id == 1"
      >
        <SalesManagerLink
          @refresh_link="refreshPage"
          :referral_link="get_referral_link_base_on_type('SM')"
        />
      </v-col>

      <v-col
        cols="12"
        md
        align="center"
        v-if="appData.account_details.investor_type_id == 2"
      >
        <SalesSupervisorLink
          @refresh_link="refreshPage"
          :referral_link="get_referral_link_base_on_type('SS')"
        />
      </v-col>

      <v-col
        cols="12"
        md
        align="center"
        v-if="
          (appData.account_details.investor_type_id == 2 ||
            appData.account_details.investor_type_id == 3) &&
          appData.category == 'Regular'
        "
      >
        <SalesRepresentativeLink
          @refresh_link="refreshPage"
          :referral_link="get_referral_link_base_on_type('SR')"
        />
      </v-col>

      <v-col cols="12" md align="center">
        <PlayerLink :referral_link="get_referral_link_base_on_type('RP')" />
      </v-col>
    </v-row>

    <!-- <v-row v-else justify="center" class="mx-auto">
      <v-col cols="12" md lg="4" align="center">
        <v-skeleton-loader type="image" />
      </v-col>

      <v-col cols="12" lg="4" md align="center">
        <v-skeleton-loader type="image" />
      </v-col>

      <v-col cols="12" lg="4" md align="center">
        <v-skeleton-loader type="image" />
      </v-col>

      <v-col cols="12" lg="4" md align="center">
        <v-skeleton-loader type="image" />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import SalesManagerLink from "@/components/app/referral_links/SalesManagerLink";
import SalesSupervisorLink from "@/components/app/referral_links/SalesSupervisor";
import SalesRepresentativeLink from "@/components/app/referral_links/SalesRepresentativeLink";
import PlayerLink from "@/components/app/referral_links/PlayerLink";
import responseGet from "@/helpers/api_request_get";
import { mapState } from "vuex";

export default {
  components: {
    SalesManagerLink,
    SalesSupervisorLink,
    SalesRepresentativeLink,
    PlayerLink,
  },

  data: () => ({
    records: [],
    records_loading: false,
  }),

  computed: {
    ...mapState(["appData", "appDataLoading"]),
  },

  created() {
    this.get_referral_links();
  },

  methods: {
    refreshPage() {
      location.reload();
    },
    async get_referral_links() {
      this.records_loading = !this.records_loading;

      const response = await responseGet("", "referral_links");

      switch (response.status) {
        case 200:
          // console.log(JSON.stringify(response.data.records));
          this.records = response.data.records;
          break;
      }

      this.records_loading = !this.records_loading;
    },

    get_referral_link_base_on_type(type) {
      return this.records.filter((record) => record.link_type == type);
    },
  },
};
</script>
