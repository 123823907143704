var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[_c('div',{staticClass:"d-flex flex-sm-row flex-column justify-space-between pa-4 pa-md-6",class:_vm.$vuetify.breakpoint.mdAndUp ? 'align-center' : 'align-start',staticStyle:{"gap":"1.5rem"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column align-center",class:_vm.$vuetify.breakpoint.mdAndUp ? 'align-center' : 'align-start',staticStyle:{"gap":"1.5rem"}},[_c('v-avatar',{staticClass:"blue",attrs:{"rounded":"","size":"40"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-stairs-down ")])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('small',{staticClass:"grey--text"},[_vm._v("TOTAL")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(_vm.total_commission)))))+" ")])]),_c('div',{staticClass:"d-flex flex-column"},[_c('small',{staticClass:"grey--text"},[_vm._v("SELECTED")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(_vm.total_selected)))))+" ")])])],1),_c('div',{staticClass:"d-inline-flex align-center ms-auto",staticStyle:{"gap":"1rem"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.doGetMyCommission}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cached ")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-text-field',{attrs:{"label":"Search","single-line":"","outlined":"","dense":"","clearable":"","disabled":_vm.records_loading || !_vm.records.length,"autofocus":"","placeholder":"Search","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.set_filter_dialog}},[_c('v-icon',[_vm._v("mdi-filter")])],1)],1)]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":5,"loading":_vm.records_loading,"hide-default-footer":_vm.records && _vm.records.length <= 5 ? true : false,"loading-text":"Loading, Please wait","search":_vm.search,"item-key":"created_at","show-select":""},scopedSlots:_vm._u([{key:"item.bet_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bet_count)+" ")]}},{key:"item.bet_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.bet_amount)))))+" ")]}},{key:"item.commission_percentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.commission_percentage + "%")+" ")]}},{key:"item.total_commission_amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.total_commission_amount)))))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.date_format(item.created_at))+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.records)?_c('div',[_c('div',{staticClass:"d-flex justify-end pa-4 pa-md-6"},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.selected.length == 0,"depressed":""},on:{"click":function($event){_vm.confirmation_dialog = true}}},[_vm._v(" Request ")])],1)]):_vm._e(),(_vm.filter_dialog)?_c('DateFilter',{attrs:{"dates":_vm.dates},on:{"set_close_date_filter_dialog":_vm.set_close_date_filter_dialog}}):_vm._e(),(_vm.confirmation_dialog)?_c('MyCommissionConfirmation',{attrs:{"records":_vm.selected},on:{"set_close_confirmation_dialog":_vm.set_close_confirmation_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }