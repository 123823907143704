<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" v-model="dialog" max-width="700">
    <v-card class="shadow">
      <div class="pa-4 pa-md-6">
        <h3 class="font-weight-bold mb-2">Please Confirm</h3>

        <v-alert v-if="alert_dialog" dense
          :class="submit_status ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
          {{ alert_message }}
        </v-alert>

        <div class="grey--text mb-3 mb-md-5">
          You're about to approve this {{ `request${records && records.length > 1 ? 's' : ''}` }}, do you really want to
          proceed?
        </div>

        <div class="rounded overflow-hidden" style="border: 1px solid rgba(0, 0, 0, 0.12)">
          <v-simple-table style="max-height: 400px; overflow-y: auto;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase text-right">Bet Count</th>
                  <th class="text-uppercase text-right">Bet Amount</th>
                  <th class="text-uppercase text-right">Commission %</th>
                  <th class="text-uppercase text-right">Commission Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in records" :key="item.id">
                  <td class="text-right">
                    {{ `Php ${money_format(parseFloat(item.bet_count))}` }}
                  </td>
                  <td class="text-right">
                    {{ `Php ${money_format(parseFloat(item.bet_amount))}` }}
                  </td>
                  <td class="text-right">
                    {{ item.commission_percentage + "%" }}
                  </td>
                  <td class="text-right">
                    {{ `Php ${money_format(parseFloat(item.total_commission_amount))}` }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
      <div class="d-flex justify-end px-3 px-md-6 pb-4 pb-md-6" style="gap: 0.5rem">
        <v-btn plain @click="close_dialog" :disabled="submit_status || submit_loading">
          No
        </v-btn>
        <v-btn color="primary" depressed @click="request_master_agent_sub_agent_commission()"
          :disabled="submit_status || submit_loading" :loading="submit_loading">
          {{ `Yes — Php ${money_format(parseFloat(total_commission))}` }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import responsePost from '@/helpers/api_request_post'

export default {
  props: {
    records: {
      type: Array
    }
  },

  data: () => ({
    submit_status: false,
    submit_loading: false,
    alert_dialog: false,
    alert_message: ''
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    },

    total_commission() {
      if (this.records) {
        const sum = this.records.reduce(
          (acc, curr) => acc + parseFloat(curr.total_commission_amount),
          0
        );

        return sum;
      }

      return 0;
    },
  },

  methods: {
    async request_master_agent_sub_agent_commission() {
      this.submit_loading = true;

      let selected_dates = [];

      this.records.forEach((element) => {
        selected_dates.push(
          element.formatted_created_at
        );
      });

      const form_data = new FormData();

      form_data.append("dates", JSON.stringify(selected_dates));
      form_data.append("total_amount", this.total_commission);

      const res = await responsePost(form_data, "commission/master_agent/update_commission");
      this.alert_dialog = true

      switch (res.status) {
        case 200:
          this.submit_status = true
          this.alert_message = "Your request has been successfully requested.";
          break;
        case 409:
          this.alert_message = "Your request has failed.";

          break;
        case 401:
          this.alert_message = "Your request is unauthorized, you will be logout.";

          setTimeout(() => {
            localStorage.removeItem("userToken");
            this.$router.push({ name: "Home" });
            location.reload();
          }, 3000);

          break;
      }

      this.submit_loading = false;
    },

    money_format(val) {
      return val.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    close_dialog() {
      this.$emit("set_close_confirmation_dialog", false)
    }
  },

  watch: {
    submit_status(val) {
      if (val) {
        setTimeout(() => {
          this.$emit("set_close_confirmation_dialog", true)
        }, 2000);
      }
    }
  }
}
</script>