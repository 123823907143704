<template>
  <v-container fluid class="d-flex flex-column" style="gap:1rem">
    <v-row>
      <v-col cols="12" md="3">
        <SalesBets />
      </v-col>

      <v-col cols="12" md="9">
        <SalesStatistics />
      </v-col>
    </v-row>

    <div v-if="appData && !appDataLoading">
      <!-- DASHBOARD FOR REGULAR INVESTOR -->
      <div v-if="appData.category == 'Regular'">
        <v-card class="shadow" v-if="appData.account_details.investor_type_id == '1'">
          <v-card-title class="d-flex flex-sm-row flex-column justify-space-between">
            <span>For the settlement of LCGi to your account</span>

            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text small v-bind="attrs" v-on="on"
                  class="text-capitalize font-weight-regulary px-2 grey--text text--darken-2">
                  {{ filter }}
                  <v-icon small class="ps-1">mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="item in filters" :key="item" @click="changeFilter(item)"
                  :class="item == filter ? 'primary lighten-5 primary--text' : ''">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>

          <v-card-text class="d-flex flex-column" style="gap:1rem">
            <LCGiCommissionSettlement :dates="dates" />
          </v-card-text>
        </v-card>

        <v-card class="shadow">
          <v-card-title>
            <span>Dowlines commissions and payouts</span>
          </v-card-title>

          <v-card-text class="d-flex flex-column" style="gap:2rem">
            <Commissions />
            <Payouts />
          </v-card-text>
        </v-card>
      </div>

      <div v-else class="d-flex flex-column" style="gap:1rem">
        <!-- DASHBOARD FOR SPECIAL INVESTOR -->
        <MyCommission/>
      </div>
    </div>

    <v-row>
      <v-col cols="12" md="9">
        <div class="d-flex flex-column" style="gap: 1.5rem">
          <Accounts />

          <BetsAndCommissionsDaily />

          <BetsAndCommissionsMonthly />
        </div>
      </v-col>

      <v-col cols="12" md="3">
        <MoneyMovements />
      </v-col>
    </v-row>

    <v-dialog ref="filterDialog" v-model="filterDialog" :return-value.sync="filterDate" persistent width="290px">
      <v-date-picker v-model="filterDate" scrollable range>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelFilter">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="saveFilter">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import formatDatev2 from "@/helpers/formatDatev2";
import { startOfWeek } from "date-fns";
import { endOfWeek } from "date-fns";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns";
import { startOfYear } from "date-fns";
import { endOfYear } from "date-fns";
import MoneyMovements from '@/components/app/dashboard/MoneyMovements'
import SalesBets from '@/components/app/dashboard/SalesBets'
import SalesStatistics from '@/components/app/dashboard/SalesStatistics'
import MyCommission from '@/components/app/dashboard/SpecialMyCommission'
import Commissions from '@/components/app/dashboard/Commissions'
import Payouts from '@/components/app/dashboard/Payouts'
import Accounts from '@/components/app/dashboard/Accounts'
import BetsAndCommissionsDaily from '@/components/app/dashboard/BetsAndCommissionsDaily'
import BetsAndCommissionsMonthly from '@/components/app/dashboard/BetsAndCommissionsMonthly'
import LCGiCommissionSettlement from '../../../components/app/dashboard/LCGiCommissionSettlement.vue'

export default {
  components: {
    MoneyMovements,
    SalesBets,
    SalesStatistics,
    MyCommission,
    Commissions,
    Accounts,
    BetsAndCommissionsDaily,
    BetsAndCommissionsMonthly,
    Payouts,
    LCGiCommissionSettlement
  },

  data: () => ({
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filter: "This Month",
    filters: ["Today", "This Week", "This Month", "This Year", "Custom"],
    filterDialog: false,
    dates: {},
    month: formatDatev2((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 'format', 'yyyy-MM'),
    filterDate: [],
    filterFrom: formatDatev2(
      startOfMonth(
        new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        )
      ),
      "format",
      "yyyy-MM-dd"
    ),
    filterTo: formatDatev2(
      endOfMonth(
        new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        )
      ),
      "format",
      "yyyy-MM-dd"
    ),
  }),

  computed: {
    ...mapState(["appData", "appDataLoading"])
  },

  methods: {
    changeFilter(val) {
      if (val == "Today") {
        this.filterFrom = this.today;
        this.filterTo = this.today;
        this.filter = "Today";
        this.filterDate = [];
        //this.doGetLCGiCommission();
        this.dates = { 'date_from': this.filterFrom, 'date_to': this.filterTo }
      }

      if (val == "This Week") {
        const startWeek = startOfWeek(new Date(this.today), { weekStartsOn: 1 }),
          endWeek = endOfWeek(new Date(this.today), { weekStartsOn: 1 });

        this.filterFrom = formatDatev2(startWeek, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endWeek, "format", "yyyy-MM-dd");
        this.filter = "This Week";
        this.filterDate = [];
        //this.doGetLCGiCommission();
        this.dates = { 'date_from': this.filterFrom, 'date_to': this.filterTo }
      }

      if (val == "This Month") {
        const startMonth = startOfMonth(new Date(this.today)),
          endMonth = endOfMonth(new Date(this.today));

        this.filterFrom = formatDatev2(startMonth, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endMonth, "format", "yyyy-MM-dd");
        this.filter = "This Month";
        this.filterDate = [];
        //this.doGetLCGiCommission();
        this.dates = { 'date_from': this.filterFrom, 'date_to': this.filterTo }
      }

      if (val == "This Year") {
        const startYear = startOfYear(new Date(this.today)),
          endYear = endOfYear(new Date(this.today));

        this.filterFrom = formatDatev2(startYear, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endYear, "format", "yyyy-MM-dd");
        this.filter = "This Year";
        this.filterDate = [];
        //this.doGetLCGiCommission();
        this.dates = { 'date_from': this.filterFrom, 'date_to': this.filterTo }
      }

      if (val == "Custom") {
        this.filterDialog = true;
      }
    },

    cancelFilter() {
      this.filterDialog = false;
    },

    saveFilter() {
      if (!this.filterDate.length) {
        // nothing to do
      } else {
        this.$refs.filterDialog.save(this.filterDate);

        const minDate = new Date(this.filterDate[0]);

        if (typeof this.filterDate[1] != "undefined") {
          const maxDate = new Date(this.filterDate[1]);

          if (minDate > maxDate) {
            this.filterFrom = formatDatev2(maxDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          } else {
            this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(maxDate, "format", "yyyy-MM-dd");
          }

          this.filterDateText =
            formatDatev2(this.filterFrom, "date") +
            " to " +
            formatDatev2(this.filterTo, "date");

          //this.doGetLCGiCommission();
          this.dates = { 'date_from': this.filterFrom, 'date_to': this.filterTo }
        } else {
          this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterDateText = formatDatev2(this.filterFrom, "date");
          //this.doGetLCGiCommission();
          this.dates = { 'date_from': this.filterFrom, 'date_to': this.filterTo }
        }

        this.filter = "Custom";
        // this.filterDialog = false
      }
    },
  }
}
</script>