<template>
  <v-card class="shadow">
    <v-card-title class="d-flex justify-center text-h6 font-weight-bold">
      <span>REGULAR PLAYER LINK</span>
    </v-card-title>

    <v-card-text class="d-flex flex-column" style="gap: 0.75rem">
      <QRCodeVue :value="url_link" size="100" level="H" />

      <v-text-field
        ref="url_link"
        v-model="url_link"
        outlined
        dense
        readonly
        label="Referral Link"
        hide-details="auto"
      >
        <template v-slot:append>
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="copy_referral_link"
                class="mb-2"
              >
                <v-icon small :color="click_status ? 'green' : 'grey'">
                  {{ `mdi-${click_status ? "check" : "content-copy"}` }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ click_status ? "Copied!" : "Copy" }}</span>
          </v-tooltip>
        </template>
      </v-text-field>

      <!-- <v-row class="my-4" dense>
        <v-col cols="12" md="6">
          <QRCodeVue :value="url_link" size="100" level="H" />
        </v-col>

        <v-col cols="12" md="6">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          Placeat id ratione repellat veritatis voluptatem optio officiis.
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-row>
            <v-col>
              <v-text-field ref="url_link" v-model="url_link" outlined dense readonly label="Referral Link" class="mt-1"
                hide-details="auto">
                <template v-slot:append>
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on" @click="copy_referral_link" class="mb-2">
                        <v-icon small :color="click_status ? 'green' : 'grey'">
                          {{ `mdi-${click_status ? 'check' : 'content-copy'}` }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ click_status ? 'Copied!' : 'Copy' }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
    </v-card-text>
  </v-card>
</template>

<script>
import Hashids from "hashids";
import QRCodeVue from "qrcode.vue";
import { mapState } from "vuex";

export default {
  components: { QRCodeVue },

  props: {
    referral_link: {
      type: Array,
    },
  },

  data: () => ({
    url_link: "",
    click_status: false,
    sub_agent_commission: null,
  }),

  mounted() {
    this.set_link();
  },

  computed: {
    ...mapState(["appData"]),
  },

  methods: {
    set_link() {
      if (this.appData) {
        const hashids = new Hashids("referral_code", 5); // pad to length 5
        // const referral_code = `${hashids.encode(2)}/${hashids.encode(2)}/${hashids.encode(this.appData.account_details.id)}`

        // this.url_link = `https://gamexsports.com.ph/${referral_code}`
        // this.url_link = `https://partners.gamexsports.com.ph/referral_code=${this.referral_link[0].referral_link}`

 const referral_code =
          this.referral_link.length == 0 || this.referral_link[0].link_setup == "Old"
            ? `${hashids.encode(2)}/${hashids.encode(2)}/${hashids.encode(
                this.appData.account_details.id
              )}`
            : `?referral_code=${this.referral_link[0].referral_link}`;

        this.url_link = `https://gamexsports.com.ph/${referral_code}`;
        this.sub_agent_commission = `${this.appData.account_details.sub_commission}%`;
      }
    },

    copy_referral_link() {
      if (!this.click_status) {
        this.click_status = true;
        let textToCopy = this.$refs.url_link.$el.querySelector("input");
        textToCopy.select();
        document.execCommand("copy");
      }
    },
  },

  watch: {
    click_status(val) {
      if (val) {
        setTimeout(() => {
          this.click_status = false;
        }, 2000);
      }
    },

    // appData(val) {
    //   if (val) this.set_link()
    // }
  },
};
</script>
