<template>
  <v-list dense class="py-4">
    <div class="grey--text mb-2 px-4">Home</div>

    <v-list-item v-for="dashboard in dashboards" :key="dashboard.route" link router :to="{ name: dashboard.route }"
      color="primary" class="px-4">
      <v-list-item-icon>
        <v-icon>{{ `mdi-${dashboard.icon}` }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ dashboard.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="grey--text mb-2 mt-4 px-4">Account</div>

    <v-list-item v-for="account in accounts" :key="account.route" color="primary" link :to="{ name: account.route }"
      class="px-4">
      <v-list-item-icon>
        <v-icon>{{ `mdi-${account.icon}` }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ account.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="grey--text mb-2 mt-4 px-4">Reports</div>

    <v-list-item v-for="report in reports" :key="report.route" color="primary" link :to="{ name: report.route }"
      class="px-4">
      <v-list-item-icon>
        <v-icon>{{ `mdi-${report.icon}` }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ report.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="grey--text mb-2 mt-4 px-4">Monitoring</div>

    <v-list-item v-for="item in monitoring" :key="item.route" color="primary" link :to="{ name: item.route }"
      class="px-4">
      <v-list-item-icon>
        <v-icon>{{ `mdi-${item.icon}` }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div v-if="appData">
      <div v-if="appData.category == 'Regular'">
        <div class="grey--text mb-2 mt-4 px-4">Approvals</div>

        <v-list-item v-for="approval in approvals" :key="approval.route" color="primary" link
          :to="{ name: approval.route }" class="px-4">
          <v-list-item-icon>
            <v-icon>{{ `mdi-${approval.icon}` }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ approval.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-else>
        <div class="grey--text mb-2 mt-4 px-4">Commissions</div>

        <v-list-item v-for="commission in commissions" :key="commission.route" color="primary" link
          :to="{ name: commission.route }" class="px-4">
          <v-list-item-icon>
            <v-icon>{{ `mdi-${commission.icon}` }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ commission.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>

    <div class="grey--text mb-2 mt-4 px-4">Settings</div>

    <v-list-item v-for="setting in settings" :key="setting.route" color="primary" link :to="{ name: setting.route }"
      class="px-4">
      <v-list-item-icon>
        <v-icon>{{ `mdi-${setting.icon}` }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ setting.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item color="primary" link @click="logout" class="px-4">
      <v-list-item-icon>
        <v-icon>mdi-logout-variant</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(["appData"])
  },

  // mounted() {
  //   if (this.appData) {
  //     if (this.appData.account_details.investor_type_id == '1') {
  //       this.reports = [
  //         ...this.reports,
  //         { route: "SettledCommissions", icon: "handshake", title: "Settled Commissions" },
  //         { route: "SettledPayouts", icon: "handshake", title: "Settled Payouts" },
  //       ]
  //     }
  //   }
  // },

  data: () => ({
    dashboards: [{ route: "Dashboard", icon: "home", title: "Dashboard" }],

    accounts: [
      { route: "Agents", icon: "face-agent", title: "Agents" },
      { route: "Players", icon: "face-man", title: "Players" },
    ],

    reports: [
      { route: "BetsAndCommissions", icon: "finance", title: "Bets & Commission" },
      { route: "BetsAndCommissionsProgressive", icon: "finance", title: "Progressive" }
    ],

    monitoring: [
      { route: "TokenMovements", icon: "hand-coin", title: "Token Movements" }
    ],

    commissions: [
      { route: "MyCommissions", icon: "tag-check-outline", title: "My Commissions" },
    ],

    approvals: [
      { route: "ApprovePayouts", icon: "tag-check-outline", title: "Payouts Approval" },
      { route: "ApproveCommissions", icon: "tag-check", title: "Commissions Approval" },
    ],

    settings: [
      {
        route: "ReferralLinks",
        icon: "link-box-variant-outline",
        title: "Referral Links",
      },
      { route: "ChangePassword", icon: "lock-reset", title: "Change Password" },
    ],
  }),

  methods: {
    logout() {
      this.$store.commit("setUserData", null, { root: true });
      this.$store.commit("setUserToken", {}, { root: true });
      localStorage.removeItem("userToken");
      this.$router.push({ name: "Home" });
    },
  },

  watch: {
    appData(val) {
      if (val) {
        if (this.appData.category == 'Regular') {
          let settled_commission_status = false
          let settled_payouts_status = false

          this.reports.forEach(element => {
            if (element.route == 'SettledCommissions') {
              settled_commission_status = true
            }

            if (element.route == 'SettledPayouts') {
              settled_payouts_status = true
            }
          });

          if (this.appData.account_details.investor_type_id == '1') {
            if (!settled_commission_status) {
              this.reports = [
                ...this.reports,
                { route: "SettledCommissions", icon: "handshake", title: "Settled Commissions" }
              ]
            }

            if (!settled_payouts_status) {
              this.reports = [
                ...this.reports,
                { route: "SettledPayouts", icon: "handshake", title: "Settled Payouts" },
              ]
            }
          }
        }
      }
    }
  }
};
</script>
