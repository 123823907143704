<template>
  <div class="d-block">
    <div class="d-flex flex-sm-row flex-column justify-space-between pa-4 pa-md-6"
      :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : 'align-start'" style="gap: 1.5rem">
      <div class="d-flex flex-sm-row flex-column align-center"
        :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : 'align-start'" style="gap: 1.5rem">
        <v-avatar class="blue" rounded size="40">
          <v-icon dark> mdi-history </v-icon>
        </v-avatar>

        <div class="d-flex flex-column">
          <small class="grey--text">TOTAL</small>
          <span class="font-weight-bold">
            {{ `Php ${money_format(parseFloat(total_commission))}` }}
          </span>
        </div>
      </div>

      <div class="d-inline-flex align-center ms-auto" style="gap: 1rem">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="doGetTransactionLogs">
              <v-icon>
                mdi-cached
              </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>

        <v-text-field v-model="search" label="Search" single-line outlined dense clearable
          :disabled="!records.length || records_loading" autofocus placeholder="Search" hide-details="auto" />

        <v-btn icon @click="set_filter_dialog">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </div>
    </div>

    <v-divider></v-divider>

    <v-data-table :headers="headers" :items="records" :items-per-page="5" :loading="records_loading"
      :hide-default-footer="records.length <= 5 ? true : false" loading-text="Loading, Please wait" :search="search">

      <template v-slot:[`item.request_type`]="{ item }">
        <v-chip small style="line-height: 0.8; padding: 4px 10px !important"
          :class="item.request_type == 'Commission Request' ? 'green lighten-5 green--text' : 'orange lighten-5 orange--text'">
          {{ item.request_type }}
        </v-chip>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip small :class="item.type == 'Agent' ? 'blue lighten-5 primary--text' : 'green lighten-5 green--text'">
          {{ item.type }}
        </v-chip>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        <span class="font-weight-bold">
          {{ `Php ${money_format(parseFloat(item.amount))}` }}
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip small style="line-height: 0.8; padding: 4px 10px !important"
          :class="item.status == 'Settled' ? 'green lighten-5 green--text' : 'orange lighten-5 orange--text'">
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:[`item.requested_at`]="{ item }">
        <span class="text-uppercase">
          {{ date_format(item.requested_at) }}
        </span>
      </template>

      <template v-slot:[`item.settled_at`]="{ item }">
        <span class="text-uppercase">
          {{ date_format(item.settled_at) }}
        </span>
      </template>
    </v-data-table>

    <DateFilter v-if="filter_dialog" :dates="dates" @set_close_date_filter_dialog="set_close_date_filter_dialog" />
  </div>
</template>

<script>
import responseGet from "@/helpers/api_request_get"
import formatDate from "@/helpers/formatDatev2"
import { startOfWeek } from "date-fns";
import { endOfWeek } from "date-fns";
import DateFilter from '@/components/app/approve_commissions/tab_items/DateFilter'

export default {
  components: { DateFilter },

  data: () => ({
    records: [],
    records_loading: false,
    search: "",
    dates: {},
    filter_dialog: false,
    date_from: formatDate(startOfWeek(new Date(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)), { weekStartsOn: 1 }), "format", "yyyy-MM-dd"),
    date_to: formatDate(endOfWeek(new Date(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)), { weekStartsOn: 1 }), "format", "yyyy-MM-dd"),
    headers: [
      {
        text: "Requestor",
        value: "requestor",
        class: "text-uppercase",
      },
      {
        text: "Request type",
        value: "request_type",
        class: "text-uppercase",
      },
      {
        text: "Type",
        value: "type",
        class: "text-uppercase",
      },
      {
        text: "Commission Amount",
        value: "amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase"
      },
      {
        text: "Date Requested",
        value: "requested_at",
        class: "text-uppercase"
      },
      {
        text: "Date Settled",
        value: "settled_at",
        class: "text-uppercase"
      }
    ]
  }),

  computed: {
    total_commission() {
      if (this.records) {
        return this.records.reduce(
          (acc, curr) => acc + parseFloat(curr.amount), 0
        );
      }

      return 0;
    },
  },

  mounted() {
    this.doGetTransactionLogs()
  },

  methods: {
    async doGetTransactionLogs() {
      this.records_loading = true;

      const form_data = new FormData();

      form_data.append("date_from", (this.date_from) ?? '');
      form_data.append("date_to", (this.date_to) ?? (this.date_from) ?? '');

      const res = await responseGet(new URLSearchParams(form_data), "commissions/commission_history");

      switch (res.status) {
        case 200:
          this.records = res.data.data;
          break;
        case 401:
          setTimeout(() => {
            localStorage.removeItem("userToken");
            this.$router.push({ name: "Home" });
            location.reload();
          }, 3000)
          break;
      }

      this.records_loading = false;
    },

    set_filter_dialog() {
      this.dates = {
        'date_from': this.date_from,
        'date_to': this.date_to
      }

      this.filter_dialog = true
    },

    set_close_date_filter_dialog(data) {
      if (Object.keys(data).length) {
        this.date_from = data.date_from
        this.date_to = data.date_to

        this.doGetTransactionLogs()
      }

      this.filter_dialog = !this.filter_dialog
    },

    money_format(val) {
      return val.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    date_format(date) {
      return formatDate(date, "datetime");
    },
  }
}
</script>