<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card class="shadow">
      <v-card-title class="font-weight-bold">
        <span>Filter</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" class="d-flex flex-column pt-2" style="gap:.75rem">
          <v-menu v-model="date_from_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :rules="[rules.required]" v-model="date_from" label="Date From" clearable readonly outlined
                dense hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date_from" @input="date_from_menu = false"></v-date-picker>
          </v-menu>

          <v-menu v-model="date_to_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date_to" label="Date To (Optional)" clearable readonly outlined dense
                hide-details="auto" v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date_to" :min="date_from" @input="date_to_menu = false"></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex justify-end" style="gap:.30rem; width: 100%;">
          <v-btn text depressed class="px-2" @click="close_dialog">Cancel</v-btn>
          <v-btn color="primary" depressed class="px-2" @click="set_filter">Filter</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import form_rules from '@/helpers/formRules'

export default {
  props: {
    dates: {
      type: Object
    }
  },

  data: () => ({
    rules: form_rules,
    date_from_menu: false,
    date_from: null,
    date_to_menu: false,
    date_to: null
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  mounted() {
    if (Object.keys(this.dates).length) {
      this.date_from = this.dates.date_from
      this.date_to = this.dates.date_to
    }
  },

  methods: {
    set_filter() {

      if (this.$refs.form.validate()) {
        this.$emit("set_close_date_filter_dialog", {
          'date_from': this.date_from,
          'date_to': (this.date_to) ?? this.date_from
        })
      }
    },

    close_dialog() {
      this.$emit("set_close_date_filter_dialog", {})
    }
  }
}
</script>