var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":_vm.records_loading,"max-width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(("Items — Php " + (_vm.money_format(parseFloat(_vm.total_commission))))))]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close_dialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pb-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"loading":_vm.records_loading,"items-per-page":5,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.bet_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.bet_amount)))))+" ")]}},{key:"item.commission_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.money_format(parseFloat(item.commission_percent))) + "%"))+" ")]}},{key:"item.commission_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.commission_amount)))))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }