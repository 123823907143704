<template>
  <v-dialog persistent :fullscreen="!$vuetify.breakpoint.mdAndUp" v-model="dialog" max-width="400">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        <span>Edit Commission</span>

        <v-btn plain icon @click="close_dialog" :disabled="update_status_loading || update_status">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" class="d-flex flex-column" style="gap: 1rem" v-if="appData"
          :disabled="update_status_loading || update_status" @submit.prevent="doUpdateCommission">
          <v-alert text v-if="alert_dialog" dense class="my-0" :color="update_status ? 'success' : 'error'">
            {{ alert_message }}
          </v-alert>

          <v-simple-table class="mb-4">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td style="width: 30%;">Name</td>
                  <td>{{ commission_data.name }}</td>
                </tr>

                <tr>
                  <td style="width: 30%;">Maximum commission</td>
                  <td>
                    <span class="font-weight-bold primary--text">
                      {{ `${appData.account_details.base_commission}%` }}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td style="width: 30%;">Current Commission</td>
                  <td>
                    <span class="font-weight-bold">{{ commission_data.commission }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-text-field ref="commission" v-model="commission" autofocus outlined dense label="Commission"
            placeholder="0.00" hide-details="auto" suffix="%"
            :rules="submit_status ? [rules.required, rules.decimal, is_exceed_to_max] : []">
          </v-text-field>

          <div class="d-flex justify-end" style="gap:.30rem">
            <v-btn depressed text color="grey" class="px-2" @click="close_dialog"
              :disabled="update_status_loading || update_status">
              Cancel
            </v-btn>

            <v-btn depressed type="submit" color="primary" class="px-2" :loading="update_status_loading"
              :disabled="update_status">
              Update
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import responsePost from '@/helpers/api_request_post'
import formRules from '@/helpers/formRules'

export default {
  props: {
    commission_data: {
      type: Object
    }
  },

  data: () => ({
    rules: formRules,
    submit_status: false,
    commission: 0,
    update_status: false,
    update_status_loading: false,
    alert_dialog: false,
    alert_message: ''
  }),

  mounted() {
    this.commission = this.commission_data.commission
  },

  computed: {
    ...mapState(['appData', 'appDataLoading']),

    dialog: {
      get: function () {
        return true
      },

      set: function () {
        this.close_dialog()
      }
    },

    is_exceed_to_max() {
      return parseFloat(this.commission) > parseFloat(this.appData.account_details.base_commission) ? `Commissionn must not exceed to ${this.appData.account_details.base_commission}%` : true
    }
  },

  methods: {
    async doUpdateCommission() {
      this.submit_status = true
      this.update_status_loading = !this.update_status_loading
      await new Promise(r => setTimeout(r, 200));

      if (this.$refs.form.validate()) {
        const response = await responsePost(this.set_form_data(), 'agents/update_commission')

        this.alert_dialog = !this.alert_dialog

        switch (response.status) {
          case 200:
            this.commission = this.money_format(parseFloat(this.commission))
            this.update_status = true
            this.alert_message = "Successfully updated."
            break
          case 401:
            localStorage.removeItem("userToken")
            this.$router.push({ name: "Home" })
            location.reload()
            break
          case 409:
            this.alert_message = "Failed to update commission."
            break
          default:
            this.alert_message = "Something went wrong."
            break
        }
      }

      this.update_status_loading = !this.update_status_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('account_id', this.commission_data.id)
      form_data.append('commission', this.commission)
      form_data.append('type', this.commission_data.type != 'Sales Representative' ? 'Agent' : 'Sales Representative')

      return form_data
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    close_dialog() {
      this.$emit("set_close_edit_commission_dialog", {})
    }
  },

  watch: {
    update_status(val) {
      if (val) {
        setTimeout(() => {
          this.$emit("set_close_edit_commission_dialog", {
            id: this.commission_data.id,
            commission: this.commission,
            type: this.commission_data.type
          })
        }, 3000)
      }
    }
  }
}
</script>