<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" :persistent="records_loading" v-model="dialog" max-width="700">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular">
        <span>History</span>

        <v-btn icon plain @click="close_dialog" :disabled="records_loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-data-table :headers="headers" :items="records" :items-per-page="5" :loading="records_loading"
          loading-text="Loading... Please wait">

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="set_logs_dialog(item)">
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>Logs</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="item.status == 'Requested' ? 'blue-grey lighten-5 blue-grey--text' : item.status == 'Verified' ? 'orange lighten-5 orange--text' : 'green lighten-5 green--text'">
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <span class="font-weight-bold">{{ `Php ${money_format(parseFloat(item.amount))}` }}</span>
          </template>

          <template v-slot:[`item.requested_date`]="{ item }">
            {{ format_date(item.requested_date) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <Logs v-if="logs_dialog" :data="logs_data" @set_close_logs_dialog="set_close_logs_dialog" />
  </v-dialog>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import formatDate from '@/helpers/formatDate'
import Logs from '@/components/app/commissions/HistoryLogs'

export default {
  components: { Logs },

  data: () => ({
    records: [],
    records_loading: false,
    logs_dialog: false,
    logs_data: [],
    headers: [
      {
        text: "Action",
        value: "action",
        class: "text-uppercase",
        align: "center"
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase",
        align: "start",
      },
      {
        text: "Amount",
        value: "amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Requested Date",
        value: "requested_date",
        class: "text-uppercase",
      }
    ]
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  mounted() {
    this.doGetHistory()
  },

  methods: {
    async doGetHistory() {
      this.records_loading = !this.records_loading

      const response = await responseGet('', 'my_commission/history')

      switch (response.status) {
        case 200:
          this.records = response.data.records
          break;
        case 401:
          localStorage.removeItem("userToken")
          this.$router.push({ name: "Home" })
          location.reload()
          break;
      }

      this.records_loading = !this.records_loading
    },

    set_logs_dialog(data) {
      this.logs_data = {
        'amount': data.amount,
        'logs': JSON.parse(data.logs)
      }
      this.logs_dialog = !this.logs_dialog
    },

    set_close_logs_dialog() {
      this.logs_dialog = !this.logs_dialog
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    format_date(date) {
      return formatDate(date)
    },

    close_dialog() {
      this.$emit("set_close_history_dialog")
    }
  }
}
</script>