var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[_c('div',{staticClass:"d-flex flex-sm-row flex-column justify-space-between pa-4 pa-md-6",class:_vm.$vuetify.breakpoint.mdAndUp ? 'align-center' : 'align-start',staticStyle:{"gap":"1.5rem"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column align-center",class:_vm.$vuetify.breakpoint.mdAndUp ? 'align-center' : 'align-start',staticStyle:{"gap":"1.5rem"}},[_c('v-avatar',{staticClass:"blue",attrs:{"rounded":"","size":"40"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-clock ")])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('small',{staticClass:"grey--text"},[_vm._v("TOTAL")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(_vm.total_commission)))))+" ")])])],1),_c('div',{staticClass:"d-inline-flex align-center ms-auto",staticStyle:{"gap":"1rem"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.doGetPendingForApproval}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cached ")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-text-field',{attrs:{"label":"Search","single-line":"","outlined":"","dense":"","clearable":"","disabled":!_vm.records.length || _vm.records_loading,"autofocus":"","placeholder":"Search","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.set_filter_dialog}},[_c('v-icon',[_vm._v("mdi-filter")])],1)],1)]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":5,"loading":_vm.records_loading,"hide-default-footer":_vm.records.length <= 5 ? true : false,"loading-text":"Loading, Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.type == 'Agent' ? 'blue lighten-5 primary--text' : 'green lighten-5 green--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.amount)))))+" ")])]}},{key:"item.request_type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.request_type == 'Commission Request' ? 'green lighten-5 green--text' : 'orange lighten-5 orange--text',staticStyle:{"line-height":"0.8","padding":"4px 10px !important"},attrs:{"small":""}},[_vm._v(" "+_vm._s(item.request_type)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.status == 'Settled' ? 'green lighten-5 green--text' : 'orange lighten-5 orange--text',staticStyle:{"line-height":"0.8","padding":"4px 10px !important"},attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.requested_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.date_format(item.requested_at))+" ")])]}}],null,true)}),(_vm.filter_dialog)?_c('DateFilter',{attrs:{"dates":_vm.dates},on:{"set_close_date_filter_dialog":_vm.set_close_date_filter_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }