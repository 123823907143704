var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1100"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"d-flex justify-space-between text-h6 font-weight-bold"},[_c('span',[_vm._v(_vm._s(this.module_header))]),_c('v-btn',{attrs:{"plain":"","icon":""},on:{"click":_vm.close_dialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticClass:"pb-0"},[(_vm.appData && !_vm.appDataLoading)?_c('v-data-table',{staticClass:"mb-1 mt-2",attrs:{"headers":_vm.get_headers(),"items":_vm.players,"items-per-page":_vm.$vuetify.breakpoint.mdAndUp ? 10 : 5,"search":_vm.search,"loading":_vm.players_loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{ref:"search",attrs:{"hide-details":"auto","label":"Search","single-line":"","outlined":"","dense":"","disabled":_vm.players_loading,"autofocus":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.action",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"dense":"","icon":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-face-man ")])],1)]}}],null,false,4289236282)},[_c('span',[_vm._v("View Sales Representative")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-face-man ")])],1)]}}],null,false,739736665)},[_c('span',[_vm._v("View Regular Player")])])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-list',{staticClass:"py-0 my-0",staticStyle:{"background":"transparent"}},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":"42px"}},[_c('v-icon',{attrs:{"dark":"","color":"grey","size":"42px"}},[_vm._v(" mdi-account-circle ")])],1),_c('v-list-item-content',{staticClass:"text-left"},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1)],1)]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("+63" + (item.mobile_number)))+" ")]}},{key:"item.commission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.commission) + "%"))+" ")]}},{key:"item.token",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:parseFloat(item.token) < 1000 ? 'red lighten-5 red--text text--lighten-1' : parseFloat(item.token) < 5000 ? 'orange lighten-5 orange--text text--lighten-1' : 'green lighten-5 green--text text--lighten-1',attrs:{"small":""}},[_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.token)))))+" ")])]}},{key:"item.sales_representative",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (_vm.number_format(parseInt(item.sales_representative)))))+" ")]}},{key:"item.regular_player",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (_vm.number_format(parseInt(item.regular_player)))))+" ")]}},{key:"item.account_verified",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",class:item.status == 'Verified' ? 'green lighten-5 green--text' : 'orange lighten-5 orange--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.account_verified)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",class:item.status == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")]}}],null,true)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }