<template>
  <div v-if="userData">
    <v-navigation-drawer v-model="drawer" app>
      <v-list class="py-4">
        <v-list-item class="ma-0">
          <v-list-item-avatar v-if="appDataLoading">
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
          </v-list-item-avatar>

          <v-list-item-avatar v-else color="primary" size="50">
            <v-icon dark style="transform: none !important"> mdi-account </v-icon>
          </v-list-item-avatar>

          <v-list-item-content v-if="appData && userData">
            <v-list-item-title class="font-weight-bold">{{
              userData.sub_account_uid ? userData.sub_account_name : userData.name
            }}</v-list-item-title>
            <v-list-item-subtitle>
              <span class="caption me-1"
                >{{ get_investor_type(appData.account_details.investor_type_id) }}
              </span>
              <span class="caption font-weight-bold primary--text">
                {{ appData.account_details.base_commission + "%" }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-progress-circular indeterminate color="#E0E0E0" :size="25" v-else />
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <NavbarLinks @logout="logout" />
    </v-navigation-drawer>

    <v-app-bar dense app class="px-2">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer />

      <v-skeleton-loader type="button" height="20" class="me-2" v-if="appDataLoading" />

      <v-skeleton-loader type="button" height="20" v-if="appDataLoading" />

      <div v-if="!appDataLoading">
        <v-list-item class="text-right">
          <v-list-item-content>
            <v-list-item-title class="mb-0 pb-0 font-weight-bold">{{
              theActualPoints
            }}</v-list-item-title>
            <small style="font-size: 8px" class="text-uppercase grey--text"
              >Available Token{{ parseInt(actualPoints) > 1 ? "s" : "" }}</small
            >
          </v-list-item-content>
        </v-list-item>
      </div>

      <DarkModeToggle class="ms-2" />
    </v-app-bar>

    <!-- Error -->
    <v-dialog v-model="unauthorizedModal" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          <span class="red--text text--darken-1">Warning</span>
        </v-card-title>

        <v-card-text
          >Your account has been logged into another device, you will be logged
          out.</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="logout" :disabled="logoutLoading">
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Refresh Errror -->
    <v-dialog v-model="refreshErrorModal" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          <span class="red--text text--darken-1">Warning</span>
        </v-card-title>

        <v-card-text>{{ refreshError }}</v-card-text>

        <v-card-text>This page will be refreshed.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="refreshPage"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NavbarLinks from "@/components/app/NavbarLinks";
import { mapState, mapActions } from "vuex";
import DarkModeToggle from "@/components/DarkModeToggle";

export default {
  components: { NavbarLinks, DarkModeToggle },

  data: () => ({
    drawer: false,
    unauthorizedModal: false,
    refreshErrorModal: false,
    scrollPosition: null,
  }),

  computed: {
    ...mapState([
      "userData",
      "appData",
      "actualPoints",
      "appDataLoading",
      "error",
      "refreshError",
    ]),
    ...mapState("logout", ["logoutLoading"]),
    theActualPoints() {
      if (this.actualPoints) {
        return this.moneyFormat(parseFloat(this.actualPoints));
      } else {
        return 0;
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);

    if (this.$vuetify.breakpoint.mdAndUp) {
      this.drawer = true;
    }

    // if (!this.userData && !this.appData) {
    //     this.doGetAppData()
    // }

    if (this.userData) {
      this.doGetAppData();
    }

    this.$root.$on("logoutGlobal", () => {
      this.logout();
    });
  },

  methods: {
    ...mapActions("logout", ["doLogout"]),
    ...mapActions(["getAppdata"]),

    get_investor_type(type_id) {
      switch (parseInt(type_id)) {
        case 1:
          return "Master Agent";
        case 2:
          return "Sales Manager";
        case 3:
          return "Sales Supervisor";
      }
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    async doGetAppData() {
      await this.getAppdata();
    },

    async logout(isForce) {
      if (!this.logoutLoading) {
        await this.doLogout();

        if (isForce) {
          this.$router.push({ name: "Home", query: { is_force: 1 } });
        } else {
          this.$router.push({ name: "Home" });
        }
      }
    },

    update_scroll() {
      this.scrollPosition = window.scrollY;
    },

    refreshPage() {
      location.reload();
    },
  },

  watch: {
    error(val) {
      if (val) {
        this.logout(1);
      }
    },
    refreshError(val) {
      if (val) {
        this.refreshErrorModal = true;
      }
    },
  },
};
</script>
