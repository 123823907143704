<template>
  <v-container fluid>
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        Players
      </v-card-title>

      <v-card-text>
        <v-data-table :headers="headers" :items="players" :items-per-page="$vuetify.breakpoint.mdAndUp ? 10 : 5"
          :search="search" :loading="players_loading || appDataLoading" loading-text="Loading... Please wait">

          <template v-slot:top>
            <v-text-field ref="search" v-model="search" label="Search" single-line outlined dense
              :disabled="players_loading" autofocus placeholder="Search" />
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dense v-bind="attrs" v-on="on" icon small color="primary" class="mr-1"
                  :disabled="is_allow_to_add_token(item)" @click="set_add_token_dialog(item)">
                  <v-icon small> mdi-plus-circle-multiple </v-icon>
                </v-btn>
              </template>
              <span>Add Token</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dense v-bind="attrs" v-on="on" icon small color="primary" class="mr-1">
                  <v-icon small> mdi-face-agent </v-icon>
                </v-btn>
              </template>
              <span>View Sales Representative</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dense v-bind="attrs" v-on="on" icon small color="primary">
                  <v-icon small> mdi-face-man </v-icon>
                </v-btn>
              </template>
              <span>View Regular Player</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <v-list class="py-0 my-0" style="background:transparent">
              <v-list-item>
                <v-list-item-avatar size="42px">
                  <v-icon dark color="grey" size="42px">
                    mdi-account-circle
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>

          <template v-slot:[`item.mobile_number`]="{ item }">
            {{ `+63${item.mobile_number}` }}
          </template>

          <template v-slot:[`item.account_verified`]="{ item }">
            <v-chip small class="font-weight-bold"
              :class="item.status == 'Verified' ? 'green lighten-5 green--text' : 'orange lighten-5 orange--text'">
              {{ item.account_verified }}
            </v-chip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip small class="font-weight-bold"
              :class="item.status == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.created_at) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import formatDate from '@/helpers/formatDate'
import responseGet from '@/helpers/api_request_get'

export default {
  data: () => ({
    players: [],
    search: '',
    players_loading: false,
    headers: [
      {
        text: "Player",
        value: "name",
        class: "text-uppercase",
        align: "center"
      },
      {
        text: "Mobile Number",
        value: "mobile_number",
        class: "text-uppercase",
      },
      {
        text: "Account Status",
        value: "account_verified",
        class: "text-uppercase"
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase"
      },
      {
        text: "Registered Date",
        value: "created_at",
        class: "text-uppercase"
      },
    ]
  }),

  computed: {
    ...mapState(['appData', 'appDataLoading'])
  },

  mounted() {
    if (this.appData) {
      this.doGetPlayers()
    }
  },

  methods: {
    async doGetPlayers() {
      this.players_loading = true

      const response = await responseGet('', 'players')

      if (response.status == 200) {
        this.players = response.data.records
      } else if (response.status == 401) {
        localStorage.removeItem("userToken")
        this.$router.push({ name: "Home" })
        location.reload()
      }

      this.players_loading = false
    },

    format_date(date) {
      return formatDate(date)
    }
  },

  watch: {
    appData(val) {
      if (val) {
        this.doGetPlayers()
      }
    },
  }
}
</script>
