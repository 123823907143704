import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    return next();
  } else {
    return next({ name: "Dashboard" });
  }
};
const auth = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    return next();
  } else {
    return next({ name: "Home" });
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  // REGISTER
  {
    path: "/register/:type/:id",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (register.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/app/register/Register.vue"
      ),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/register/:referral_code",
    name: "RegisterNewFormat",
    // route level code-splitting
    // this generates a separate chunk (registernewformat.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "registernewformat" */ "../views/app/register/RegisterNewFormat.vue"
      ),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  // RESET PASSWORD
  {
    path: "/reset_password/:id",
    name: "ResetPassword",
    // route level code-splitting
    // this generates a separate chunk (reset_password.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "reset_password" */ "../views/app/reset_password/ResetPassword.vue"
      ),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  // DASHBOARD
  {
    path: "/app/dashboard",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/app/dashboard/Dashboard.vue"
      ),
    beforeEnter: auth,
  },

  // ACCOUNT
  {
    path: "/app/agents",
    name: "Agents",
    // route level code-splitting
    // this generates a separate chunk (agents.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "agents" */ "../views/app/account/Agents.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/app/players",
    name: "Players",
    // route level code-splitting
    // this generates a separate chunk (players.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "players" */ "../views/app/account/Players.vue"
      ),
    beforeEnter: auth,
  },

  // REPORTS
  {
    path: "/app/bets_and_commissions",
    name: "BetsAndCommissions",
    // route level code-splitting
    // this generates a separate chunk (bets_and_commissions.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "bets_and_commissions" */ "../views/app/reports/BetsAndCommissions.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/app/progressive",
    name: "BetsAndCommissionsProgressive",
    // route level code-splitting
    // this generates a separate chunk (progressive.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "progressive" */ "../views/app/reports/BetsAndCommissionsProgressive.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/app/settled_commissions",
    name: "SettledCommissions",
    // route level code-splitting
    // this generates a separate chunk (settledcommissions.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "settledcommissions" */ "../views/app/reports/SettledCommissions.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/app/settled_payouts",
    name: "SettledPayouts",
    // route level code-splitting
    // this generates a separate chunk (settledpayouts.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "settledpayouts" */ "../views/app/reports/SettledPayouts.vue"
      ),
    beforeEnter: auth,
  },

  // MONITORING
  {
    path: "/app/token_movements",
    name: "TokenMovements",
    // route level code-splitting
    // this generates a separate chunk (token_movements.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "token_movements" */ "../views/app/monitoring/TokenMovements.vue"
      ),
    beforeEnter: auth,
  },

  // REFERRAL LINKS
  {
    path: "/app/referral_links",
    name: "ReferralLinks",
    // route level code-splitting
    // this generates a separate chunk (referral_links.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "referral_links" */ "../views/app/referral_links/ReferralLinks.vue"
      ),
    beforeEnter: auth,
  },
  // APPROVE PAYOUTS
  {
    path: "/app/approve_payouts",
    name: "ApprovePayouts",
    // route level code-splitting
    // this generates a separate chunk (approve_payouts.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "approve_payouts" */ "../views/app/approve_payouts/ApprovePayouts.vue"
      ),
    beforeEnter: auth,
  },
  // APPROVE COMMISSIONS
  {
    path: "/app/approve_commissions",
    name: "ApproveCommissions",
    // route level code-splitting
    // this generates a separate chunk (approve_commissions.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "approve_commissions" */ "../views/app/approve_commissions/ApproveCommissions.vue"
      ),
    beforeEnter: auth,
  },
  // COMMISSIONS
  {
    path: "/app/my_commissions",
    name: "MyCommissions",
    // route level code-splitting
    // this generates a separate chunk (mycommissions.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "mycommissions" */ "../views/app/commissions/MyCommissions.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/app/change_password",
    name: "ChangePassword",
    // route level code-splitting
    // this generates a separate chunk (change_password.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "change_password" */ "../views/app/change_password/ChangePassword.vue"
      ),
    beforeEnter: auth,
  },
  // catchall 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "pagenotfound" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes /* ,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  } */,
});

export default router;
