<template>
  <div>
    <div v-if="appData && !records_loading">
      <div class="d-flex flex-sm-row flex-column" style="gap:2rem">
        <div class="d-flex flex-column py-6 px-2 rounded" style="gap: 1rem; width:100%">
          <span style="font-size:16px">PAYOUTS PENDING</span>

          <span class="text-h5 py-0 grey--text text--darken-3">
            <small class="grey--text">Php </small> {{ money_format(parseFloat(records.pendings)) }}
          </span>

          <span>
            <v-icon small class="mr-1 mb-1">mdi-calendar-range</v-icon>
            {{ `${format_date(records.date_min_pending)} - ${format_date(records.date_max_pending)}` }}
          </span>
        </div>

        <div class="d-flex flex-column py-6 px-2 rounded" style="gap: 1rem; width:100%">
          <span style="font-size:16px">PAYOUTS REQUESTED</span>

          <span class="text-h5 py-0 grey--text text--darken-3">
            <small class="grey--text">Php </small> {{ money_format(parseFloat(records.requested)) }}
          </span>

          <span>
            <v-icon small class="mr-1 mb-1">mdi-calendar-range</v-icon>
            {{ `${format_date(records.date_min_requested)} - ${format_date(records.date_max_requested)}` }}
          </span>
        </div>

        <div class="d-flex flex-column py-6 px-2 rounded" style="gap: 1rem; width:100%">
          <span style="font-size:16px">PAYOUTS APPROVED</span>

          <span class="text-h5 py-0 grey--text text--darken-3">
            <small class="grey--text">Php </small> {{ money_format(parseFloat(records.approved)) }}
          </span>

          <span>
            <v-icon small class="mr-1 mb-1">mdi-calendar-range</v-icon>
            {{ `${format_date(records.date_min_approved)} - ${format_date(records.date_max_approved)}` }}
          </span>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="appData" class="d-flex flex-column py-1" style="gap:.60rem">
        <div v-if="appData.account_details.investor_type_id == 1" class="d-flex" style="gap:.60rem">
          <div v-for="index in 3" :key="index" style="width: 100%;">
            <div v-if="appData.account_details.investor_type_id == 1" class="d-flex flex-column align-center py-8 rounded"
              style="gap:2rem; border:1px solid #CFD8DC">
              <v-skeleton-loader class="mx-1" type="text" light height="20px" width="50%"></v-skeleton-loader>
              <v-skeleton-loader class="mx-1" type="text" light height="20px" width="50%"></v-skeleton-loader>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="d-flex justify-center" style="gap:.50rem">
        <v-progress-circular :size="20" :width="3" color="grey" indeterminate></v-progress-circular>
        <span>Fetching information...</span>
      </div>
    </div>
  </div>

  <!-- <div class="d-flex" style="gap:1rem">
    <v-card class="elevation-0">
      <v-card-text>
        <div v-if="appData && !records_loading">
          <v-row v-if="Object.keys(records).length">
            <v-col cols="12" md="4">
              <div class="d-flex flex-column shadow py-6 px-2 rounded" style="border: 1px solid #ECEFF1; gap: 2rem">
                <span class="text-center pa-0 ma-0" style="font-size:16px">TOTAL PENDING</span>

                <span class="text-h5 py-0 grey--text text--darken-3 text-center">{{
                  `₱${money_format(parseFloat(records.pendings))}` }}</span>

                <span class="text-center">
                  <v-icon small class="mr-1 mb-1">mdi-calendar-range</v-icon>
                  {{ `${format_date(records.date_min_pending)} - ${format_date(records.date_max_pending)}` }}
                </span>
              </div>
            </v-col>

            <v-col cols="12" md="4">
              <div class="d-flex flex-column shadow py-6 px-2 rounded" style="border: 1px solid #ECEFF1; gap: 2rem">
                <span class="text-center pa-0 ma-0" style="font-size:16px">TOTAL REQUESTED</span>

                <span class="text-h5 py-0 grey--text text--darken-3 text-center">
                  {{ `₱${money_format(parseFloat(records.requested))}` }}
                </span>

                <span class="text-center">
                  <v-icon small class="mr-1 mb-1">mdi-calendar-range</v-icon>
                  {{ `${format_date(records.date_min_requested)} - ${format_date(records.date_max_requested)}` }}
                </span>
              </div>
            </v-col>

            <v-col cols="12" md="4">
              <div class="d-flex flex-column shadow py-6 px-2 rounded" style="border: 1px solid #ECEFF1; gap: 2rem">
                <span class="text-center pa-0 ma-0" style="font-size:16px">TOTAL APPROVED</span>

                <span class="text-h5 py-0 grey--text text--darken-3 text-center">
                  {{ `₱${money_format(parseFloat(records.approved))}` }}
                </span>

                <span class="text-center">
                  <v-icon small class="mr-1 mb-1">mdi-calendar-range</v-icon>
                  {{ `${format_date(records.date_min_approved)} - ${format_date(records.date_max_approved)}` }}
                </span>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <div v-if="appData" class="d-flex flex-column py-1" style="gap:.60rem">
            <div v-if="appData.account_details.investor_type_id == 1" class="d-flex" style="gap:.60rem">
              <div v-for="index in 3" :key="index" style="width: 100%;">
                <div v-if="appData.account_details.investor_type_id == 1"
                  class="d-flex flex-column align-center py-8 rounded" style="gap:2rem; border:1px solid #CFD8DC">
                  <v-skeleton-loader class="mx-1" type="text" light height="20px" width="50%"></v-skeleton-loader>
                  <v-skeleton-loader class="mx-1" type="text" light height="20px" width="50%"></v-skeleton-loader>
                </div>
              </div>
            </div>

            <div v-if="appData.account_details.investor_type_id == 1" class="d-flex justify-end">
              <v-skeleton-loader class="mb-0" type="text" light height="20px"
                :width="$vuetify.breakpoint.mdAndUp ? '30%' : '40%'"></v-skeleton-loader>
            </div>

            <div class="d-flex" style="gap:.60rem">
              <div v-for="index in 3" :key="index" style="width: 100%;">
                <div class="d-flex flex-column align-center py-8 rounded" style="gap:2rem; border:1px solid #CFD8DC">
                  <v-skeleton-loader class="mx-1" type="text" light height="20px" width="50%"></v-skeleton-loader>
                  <v-skeleton-loader class="mx-1" type="text" light height="20px" width="50%"></v-skeleton-loader>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="d-flex justify-center" style="gap:.50rem">
            <v-progress-circular :size="20" :width="3" color="grey" indeterminate></v-progress-circular>
            <span>Fetching information...</span>
          </div>
        </div>
      </v-card-text>

      <v-dialog ref="filterDialog" v-model="filterDialog" :return-value.sync="filterDate" persistent width="290px">
        <v-date-picker v-model="filterDate" scrollable range>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="cancelFilter">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="saveFilter">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-card>
  </div> -->
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import formatDate from '@/helpers/formatDate'
import { mapState } from 'vuex';
import formatDatev2 from "@/helpers/formatDatev2"
import { startOfWeek } from 'date-fns'
import { endOfWeek } from 'date-fns'
import { startOfMonth } from 'date-fns'
import { endOfMonth } from 'date-fns'
import { startOfYear } from 'date-fns'
import { endOfYear } from 'date-fns'

export default {
  data: () => ({
    records: [],
    records_loading: false,
    payout_status: [],
    payout_status_loading: false,
    today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    filter: 'This Month',
    filters: ['Today', 'This Week', 'This Month', 'This Year', 'Custom'],
    filterFrom: formatDatev2(startOfMonth(new Date((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))), 'format', 'yyyy-MM-dd'),
    filterTo: formatDatev2(endOfMonth(new Date((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))), 'format', 'yyyy-MM-dd'),
    filterDialog: false,
    filterDate: [],
    filterDateText: '',
  }),

  computed: {
    ...mapState(["appData"]),
  },

  mounted() {
    if (this.appData) {
      if (this.appData.account_details.investor_type_id == 1) this.get_payout_status()
      this.get_pending()
    }
  },

  methods: {
    async get_payout_status() {
      this.payout_status_loading = !this.payout_status_loading

      const response = await responseGet(this.set_payout_status_form_data(), 'payouts/status')

      if (response.status == 200) {
        this.payout_status = response.data.records
      }

      this.payout_status_loading = !this.payout_status_loading
    },

    set_payout_status_form_data() {
      const form_data = new FormData()

      form_data.append('date_from', this.filterFrom)
      form_data.append('date_to', this.filterTo)

      return new URLSearchParams(form_data)
    },

    async get_pending() {
      this.records_loading = !this.records_loading

      const response = await responseGet(this.set_form_data(), 'payouts/pending')

      if (response.status == 200) {
        this.records = response.data.records
      } else if (response.status == 401) {
        localStorage.removeItem("userToken")
        this.$router.push({ name: "Home" })
        location.reload()
      }

      this.records_loading = !this.records_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('investor_type_id', this.appData.account_details.investor_type_id)

      return new URLSearchParams(form_data)
    },

    changeFilter(val) {
      if (val == 'Today') {
        this.filterFrom = this.today
        this.filterTo = this.today
        this.filter = 'Today'
        this.filterDate = []
        this.get_payout_status()
      }

      if (val == 'This Week') {
        const startWeek = startOfWeek(new Date(this.today), { weekStartsOn: 1 }),
          endWeek = endOfWeek(new Date(this.today), { weekStartsOn: 1 })

        this.filterFrom = formatDatev2(startWeek, 'format', 'yyyy-MM-dd')
        this.filterTo = formatDatev2(endWeek, 'format', 'yyyy-MM-dd')
        this.filter = 'This Week'
        this.filterDate = []
        this.get_payout_status()
      }

      if (val == 'This Month') {
        const startMonth = startOfMonth(new Date(this.today)),
          endMonth = endOfMonth(new Date(this.today))

        this.filterFrom = formatDatev2(startMonth, 'format', 'yyyy-MM-dd')
        this.filterTo = formatDatev2(endMonth, 'format', 'yyyy-MM-dd')
        this.filter = 'This Month'
        this.filterDate = []
        this.get_payout_status()
      }

      if (val == 'This Year') {
        const startYear = startOfYear(new Date(this.today)),
          endYear = endOfYear(new Date(this.today))

        this.filterFrom = formatDatev2(startYear, 'format', 'yyyy-MM-dd')
        this.filterTo = formatDatev2(endYear, 'format', 'yyyy-MM-dd')
        this.filter = 'This Year'
        this.filterDate = []
        this.get_payout_status()
      }

      if (val == 'Custom') {
        this.filterDialog = true
      }
    },

    cancelFilter() {
      this.filterDialog = false
    },

    saveFilter() {
      if (!this.filterDate.length) {
        // nothing to do
      } else {
        this.$refs.filterDialog.save(this.filterDate)

        const minDate = new Date(this.filterDate[0])

        if (typeof this.filterDate[1] != 'undefined') {
          const maxDate = new Date(this.filterDate[1])

          if (minDate > maxDate) {
            this.filterFrom = formatDatev2(maxDate, 'format', 'yyyy-MM-dd')
            this.filterTo = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
          } else {
            this.filterFrom = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
            this.filterTo = formatDatev2(maxDate, 'format', 'yyyy-MM-dd')
          }

          this.filterDateText = formatDatev2(this.filterFrom, 'date') + ' to ' + formatDatev2(this.filterTo, 'date')

          this.get_payout_status()
        } else {
          this.filterFrom = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
          this.filterTo = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
          this.filterDateText = formatDatev2(this.filterFrom, 'date')
          this.get_payout_status()
        }

        this.filter = 'Custom'
      }
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    format_date(date) {
      return formatDate(date, 'custom_date')
    },
  },

  watch: {
    appData(val) {
      if (val) {
        if (this.appData.account_details.investor_type_id == 1) this.get_payout_status()
        this.get_pending()
      }
    }
  }
}
</script>