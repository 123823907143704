<template>
  <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width"
    :height="height" />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'area'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    },
    theChartData: {
      required: true
    }
  },
  data() {
    return {
      chartData: {
        labels: this.theChartData.data_labels,
        datasets: [
          {
            label: 'Bets',
            data: this.theChartData.data_bets_statistics,
            hidden: true,
            backgroundColor: "rgba(224, 242, 241,.3)",
            pointBackgroundColor: "#00897B",
            borderColor: "#00897B",
            borderWidth: 1,
            radius: 2,
            fill: true
          },
          {
            label: 'Commissions',
            data: this.theChartData.data_commissions_statistics,
            backgroundColor: "rgba(232, 245, 233,.3)",
            pointBackgroundColor: "#43A047",
            borderColor: "#43A047",
            hidden: false,
            borderWidth: 1,
            radius: 2,
            fill: true
          },
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        lineTension: .3,
        plugins: {
          datalabels: {
            display: false,
          }
        },
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        stroke: {
          curve: 'straight'
        },
      }
    }
  }
}
</script>
