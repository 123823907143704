<template>
  <div>
    <div class="d-flex flex-column" style="gap:1rem">
      <div v-if="!commission_loading" class="d-flex flex-sm-row flex-column" style="gap:2rem">
        <div class="d-flex flex-column pa-4 rounded" style="gap: 1rem; width: 100%"
          v-for="(commission, index) in commissions" :key="index">
          <div class="d-flex justify-space-between">
            <v-icon large :class="commission.icon_left_color">
              {{ `mdi-${commission.icon_left}` }}
            </v-icon>

            <v-icon large class="blue--text text--lighten-2">
              {{ `mdi-${commission.icon_right}` }}
            </v-icon>
          </div>

          <span class="text-h5 py-0 my-0 grey--text text--darken-3">
            <small class="grey--text">Php </small> {{ money_format(parseFloat(commission.amount)) }}
          </span>

          <div class="d-flex flex-column" style="gap:.60rem">
            <v-divider class="my-1" />

            <div class="d-flex justify-space-between">
              <span>{{ `${commission.title} Commission` }}</span>

              <a v-if="index == 3" @click.prevent="set_commission_dialog">View</a>

              <!-- <v-btn v-if="index == 3" text class="px-2" style="height: 100px" @click="set_commission_dialog">
              View
            </v-btn> -->
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="d-flex" :class="!$vuetify.breakpoint.mdAndUp ? 'flex-column' : 'flex-row'" style="gap: 0.6rem">
          <div v-for="i in 4" :key="i" style="width: 100%; border: 1px solid #ECEFF1" class="rounded">
            <v-card class="shadow pa-4">
              <div class="d-flex justify-space-between px-2">
                <v-skeleton-loader class="mx-1" type="avatar" light></v-skeleton-loader>
                <v-skeleton-loader class="mx-1" type="avatar" light></v-skeleton-loader>
              </div>

              <div class="d-flex flex-column px-2">
                <v-skeleton-loader class="mx-1 mt-10" type="text" light height="20px" width="50%"></v-skeleton-loader>
                <v-divider class="py-2" />
                <v-skeleton-loader class="mx-1" type="text" light height="20px" width="50%"></v-skeleton-loader>
              </div>
            </v-card>
          </div>
        </div>
      </div>

      <DownlineCommissionDialog v-if="downline_commission_dialog" @set_commission_dialog="set_commission_dialog" />
    </div>
  </div>
</template>

<script>
import formatDatev2 from "@/helpers/formatDatev2";
import { startOfWeek } from "date-fns";
import { endOfWeek } from "date-fns";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns";
import { startOfYear } from "date-fns";
import { endOfYear } from "date-fns";
import responseGet from "@/helpers/api_request_get";
import DownlineCommissionDialog from "@/components/app/dashboard/DownlineCommission";
import { mapState } from "vuex";

export default {
  components: { DownlineCommissionDialog },

  data: () => ({
    commission_lcgi: [],
    commission_lcgi_loading: false,
    commissions: [],
    commission_loading: false,
    downline_commission_dialog: false,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filter: "This Month",
    filters: ["Today", "This Week", "This Month", "This Year", "Custom"],
    filterFrom: formatDatev2(
      startOfMonth(
        new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        )
      ),
      "format",
      "yyyy-MM-dd"
    ),
    filterTo: formatDatev2(
      endOfMonth(
        new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        )
      ),
      "format",
      "yyyy-MM-dd"
    ),
    filterDialog: false,
    filterDate: [],
    filterDateText: "",
  }),

  mounted() {
    this.doGetLCGiCommission();
    this.doGetCommission();
  },

  computed: {
    ...mapState(["appData"]),

    paid_percentage() {
      if (this.commission_lcgi) {
        if (
          parseFloat(this.commission_lcgi.total_commission) == 0 ||
          parseFloat(this.commission_lcgi.paid_commission) == 0
        )
          return 0.0;
        else
          return this.money_format(
            (parseFloat(this.commission_lcgi.paid_commission) /
              parseFloat(this.commission_lcgi.total_commission)) *
            100
          );
      }

      return 0.0;
    },

    unpaid_percentage() {
      if (this.commission_lcgi) {
        if (
          parseFloat(this.commission_lcgi.total_commission) == 0 ||
          parseFloat(this.commission_lcgi.unpaid_commission) == 0
        )
          return 0.0;
        else
          return this.money_format(
            (parseFloat(this.commission_lcgi.unpaid_commission) /
              parseFloat(this.commission_lcgi.total_commission)) *
            100
          );
      }

      return 0.0;
    },
  },

  methods: {
    async doGetCommission() {
      this.commission_loading = true;

      const response = await responseGet("", "total_commissions");

      if (response.status == 200) {
        this.commissions = [];
        this.commissions.push({
          title: "Total",
          icon_left: "sack-percent",
          icon_left_color: "orange--text text--lighten-2",
          icon_right: "finance",
          amount: response.data.total,
        });
        this.commissions.push({
          title: "Sales Based -",
          icon_left: "set-center",
          icon_left_color: "indigo--text text--lighten-2",
          icon_right: "finance",
          amount: response.data.sales_base,
        });
        this.commissions.push({
          title: "Referral Based -",
          icon_left: "account-group",
          icon_left_color: "green--text text--lighten-2",
          icon_right: "finance",
          amount: response.data.referral_base,
        });
        this.commissions.push({
          title: "Agents",
          icon_left: "face-agent",
          icon_left_color: "red--text text--lighten-2",
          icon_right: "finance",
          amount: response.data.agent_base,
        });
      } else if (response.status == 401) {
        localStorage.removeItem("userToken");
        this.$router.push({ name: "Home" });
        location.reload();
      }

      this.commission_loading = false;
    },

    async doGetLCGiCommission() {
      this.commission_lcgi_loading = !this.commission_lcgi_loading;

      const response = await responseGet(
        this.set_form_data_commission_lcgi(),
        "commision/lcgi"
      );

      switch (response.status) {
        case 200:
          this.commission_lcgi = response.data.records;
          break;
        case 401:
          {
            localStorage.removeItem("userToken");
            this.$router.push({ name: "Home" });
            location.reload();
          }
          break;
      }

      this.commission_lcgi_loading = !this.commission_lcgi_loading;
    },

    set_form_data_commission_lcgi() {
      const form_data = new FormData();

      form_data.append("date_from", this.filterFrom);
      form_data.append("date_to", this.filterTo);

      return new URLSearchParams(form_data);
    },

    changeFilter(val) {
      if (val == "Today") {
        this.filterFrom = this.today;
        this.filterTo = this.today;
        this.filter = "Today";
        this.filterDate = [];
        this.doGetLCGiCommission();
      }

      if (val == "This Week") {
        const startWeek = startOfWeek(new Date(this.today), { weekStartsOn: 1 }),
          endWeek = endOfWeek(new Date(this.today), { weekStartsOn: 1 });

        this.filterFrom = formatDatev2(startWeek, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endWeek, "format", "yyyy-MM-dd");
        this.filter = "This Week";
        this.filterDate = [];
        this.doGetLCGiCommission();
      }

      if (val == "This Month") {
        const startMonth = startOfMonth(new Date(this.today)),
          endMonth = endOfMonth(new Date(this.today));

        this.filterFrom = formatDatev2(startMonth, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endMonth, "format", "yyyy-MM-dd");
        this.filter = "This Month";
        this.filterDate = [];
        this.doGetLCGiCommission();
      }

      if (val == "This Year") {
        const startYear = startOfYear(new Date(this.today)),
          endYear = endOfYear(new Date(this.today));

        this.filterFrom = formatDatev2(startYear, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endYear, "format", "yyyy-MM-dd");
        this.filter = "This Year";
        this.filterDate = [];
        this.doGetLCGiCommission();
      }

      if (val == "Custom") {
        this.filterDialog = true;
      }
    },

    cancelFilter() {
      this.filterDialog = false;
    },

    saveFilter() {
      if (!this.filterDate.length) {
        // nothing to do
      } else {
        this.$refs.filterDialog.save(this.filterDate);

        const minDate = new Date(this.filterDate[0]);

        if (typeof this.filterDate[1] != "undefined") {
          const maxDate = new Date(this.filterDate[1]);

          if (minDate > maxDate) {
            this.filterFrom = formatDatev2(maxDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          } else {
            this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(maxDate, "format", "yyyy-MM-dd");
          }

          this.filterDateText =
            formatDatev2(this.filterFrom, "date") +
            " to " +
            formatDatev2(this.filterTo, "date");

          this.doGetLCGiCommission();
        } else {
          this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterDateText = formatDatev2(this.filterFrom, "date");
          this.doGetLCGiCommission();
        }

        this.filter = "Custom";
      }
    },

    set_commission_dialog() {
      this.downline_commission_dialog = !this.downline_commission_dialog;
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
