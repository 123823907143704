<template>
  <!-- <div class="d-flex flex-column" style="gap: 0.5rem"> -->
  <!-- <div class="d-flex justify-space-between align-end">
      <span>LCGi to MA by Game schedule</span>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined small v-bind="attrs" v-on="on"
            class="text-capitalize font-weight-regulary px-2 grey--text text--darken-2"
            :disabled="commission_lcgi_loading">
            {{ filter }}
            <v-icon small class="ps-1">mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="item in filters" :key="item" @click="changeFilter(item)"
            :class="item == filter ? 'primary lighten-5 primary--text' : ''">
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div> -->
  <div class="d-flex flex-column" style="gap:1.5rem">
    <!-- COMMISSION -->
    <div class="d-flex flex-sm-row flex-column justify-space-around" style="gap: 1rem">
      <v-card class="d-flex flex-column pa-4 elevation-0" style="width: 100%;">
        <span>TOTAL COMMISSION</span>
        <span v-if="!commission_lcgi_loading" class="text-h5 mt-2">
          <small class="grey--text">Php </small> {{ money_format(parseFloat(commission_lcgi.total_commission)) }}
        </span>
        <div v-else>
          <v-skeleton-loader class="mx-1 mt-4" type="text" light height="20px" width="50%"></v-skeleton-loader>
        </div>
      </v-card>

      <v-divider vertical />

      <v-card class="d-flex flex-column pa-4 elevation-0" style="width: 100%;gap:.30rem">
        <span>PAID COMMISSION</span>

        <div v-if="!commission_lcgi_loading" class="d-flex flex-sm-row flex-column justify-space-between">
          <span class="text-h5">
            <small class="grey--text">Php </small>
            {{ money_format(parseFloat(commission_lcgi.paid_commission)) }}
          </span>

          <div class="d-flex align-center rounded" :style="$vuetify.breakpoint.mdAndUp ? 'width: 35%' : 'width: 100%'"
            style="overflow: hidden;gap:.30rem">
            <div class="rounded" style="overflow: hidden; width: 100%">
              <v-progress-linear v-model="paid_percentage" height="20" color="success">
                <small class="white--text font-weight-bold">{{
                  `${paid_percentage}% of total`
                }}</small>
              </v-progress-linear>
            </div>
          </div>
        </div>

        <div v-else class="d-flex justify-space-between">
          <v-skeleton-loader class="mx-1 mt-4" type="text" light height="20px" width="40%"></v-skeleton-loader>
          <v-skeleton-loader class="mx-1 mt-4" type="text" light height="20px" width="40%"></v-skeleton-loader>
        </div>
      </v-card>

      <v-divider vertical />

      <v-card class="d-flex flex-column pa-4 elevation-0" style="width: 100%;gap:.30rem">
        <span>UNPAID COMMISSION</span>

        <div v-if="!commission_lcgi_loading" class="d-flex flex-sm-row flex-column justify-space-between">
          <span class="text-h5">
            <small class="grey--text">Php </small>{{ money_format(parseFloat(commission_lcgi.unpaid_commission)) }}
          </span>

          <div class="d-flex align-center rounded" :style="$vuetify.breakpoint.mdAndUp ? 'width: 35%' : 'width: 100%'"
            style="overflow: hidden;gap:.30rem">
            <div class="rounded" style="overflow: hidden; width: 100%">
              <v-progress-linear v-model="unpaid_percentage" height="20" color="red">
                <small class="white--text font-weight-bold">{{
                  `${unpaid_percentage}% of total`
                }}</small>
              </v-progress-linear>
            </div>
          </div>
        </div>

        <div v-else class="d-flex justify-space-between">
          <v-skeleton-loader class="mx-1 mt-4" type="text" light height="20px" width="40%"></v-skeleton-loader>
          <v-skeleton-loader class="mx-1 mt-4" type="text" light height="20px" width="40%"></v-skeleton-loader>
        </div>
      </v-card>
    </div>

    <!-- PAYOUTS -->
    <div class="d-flex flex-sm-row flex-column justify-space-around" style="gap: 1rem">
      <v-card class="d-flex flex-column pa-4 elevation-0" style="width: 100%;">
        <span>TOTAL PAYOUTS</span>
        <span v-if="!commission_lcgi_loading" class="text-h5 mt-4">
          <small class="grey--text">Php </small>{{ money_format(parseFloat(payout_status.total_payouts)) }}
        </span>
        <div v-else>
          <v-skeleton-loader class="mx-1 mt-4" type="text" light height="20px" width="50%"></v-skeleton-loader>
        </div>
      </v-card>

      <v-divider vertical />

      <v-card class="d-flex flex-column pa-4 elevation-0" style="width: 100%;">
        <span>PAID PAYOUTS</span>
        <span v-if="!commission_lcgi_loading" class="text-h5 mt-4">
          <small class="grey--text">Php</small> {{ money_format(parseFloat(payout_status.paid)) }}
        </span>
        <div v-else>
          <v-skeleton-loader class="mx-1 mt-4" type="text" light height="20px" width="50%"></v-skeleton-loader>
        </div>
      </v-card>

      <v-divider vertical />

      <v-card class="d-flex flex-column pa-4 elevation-0" style="width: 100%;">
        <span>UNPAID PAYOUTS</span>
        <span v-if="!commission_lcgi_loading" class="text-h5 mt-4">
          <small class="grey--text">Php </small> {{ money_format(parseFloat(payout_status.unpaid)) }}
        </span>
        <div v-else>
          <v-skeleton-loader class="mx-1 mt-4" type="text" light height="20px" width="50%"></v-skeleton-loader>
        </div>
      </v-card>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import formatDatev2 from "@/helpers/formatDatev2";
import { startOfWeek } from "date-fns";
import { endOfWeek } from "date-fns";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns";
import { startOfYear } from "date-fns";
import { endOfYear } from "date-fns";

export default {
  props: {
    dates: {
      type: Object
    }
  },

  data: () => ({
    commission_lcgi: [],
    commission_lcgi_loading: false,
    payout_status: [],
    payout_status_loading: false,
    filter: "This Month",
    filters: ["Today", "This Week", "This Month", "This Year", "Custom"],
    filterFrom: formatDatev2(
      startOfMonth(
        new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        )
      ),
      "format",
      "yyyy-MM-dd"
    ),
    filterTo: formatDatev2(
      endOfMonth(
        new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        )
      ),
      "format",
      "yyyy-MM-dd"
    ),
  }),

  computed: {
    paid_percentage() {
      if (this.commission_lcgi) {
        if (
          parseFloat(this.commission_lcgi.total_commission) == 0 ||
          parseFloat(this.commission_lcgi.paid_commission) == 0
        )
          return 0.0;
        else
          return this.money_format(parseFloat((parseFloat(this.commission_lcgi.paid_commission) / parseFloat(this.commission_lcgi.total_commission)) * 100));
      }

      return 0.0;
    },

    unpaid_percentage() {
      if (this.commission_lcgi) {
        if (
          parseFloat(this.commission_lcgi.total_commission) == 0 ||
          parseFloat(this.commission_lcgi.unpaid_commission) == 0
        )
          return 0.0;
        else
          return this.money_format(parseFloat((parseFloat(this.commission_lcgi.unpaid_commission) / parseFloat(this.commission_lcgi.total_commission)) * 100))
      }

      return 0.0;
    },
  },

  mounted() {
    this.doGetLCGiCommission()
    this.doGetPayoutStatus()
  },

  methods: {
    async doGetLCGiCommission() {
      this.commission_lcgi_loading = !this.commission_lcgi_loading;

      const response = await responseGet(this.set_form_data_commission_lcgi(), "commision/lcgi");

      switch (response.status) {
        case 200:
          this.commission_lcgi = response.data.records;
          break;
        case 401:
          {
            localStorage.removeItem("userToken");
            this.$router.push({ name: "Home" });
            location.reload();
          }
          break;
      }

      this.commission_lcgi_loading = !this.commission_lcgi_loading;
    },

    set_form_data_commission_lcgi() {
      const form_data = new FormData();

      form_data.append("date_from", this.filterFrom);
      form_data.append("date_to", this.filterTo);

      return new URLSearchParams(form_data);
    },

    async doGetPayoutStatus() {
      this.payout_status_loading = !this.payout_status_loading

      const response = await responseGet(this.set_payout_status_form_data(), 'payouts/status')

      if (response.status == 200) {
        this.payout_status = response.data.records
      }

      this.payout_status_loading = !this.payout_status_loading
    },

    set_payout_status_form_data() {
      const form_data = new FormData()

      form_data.append('date_from', this.filterFrom)
      form_data.append('date_to', this.filterTo)

      return new URLSearchParams(form_data)
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    changeFilter(val) {
      if (val == "Today") {
        this.filterFrom = this.today;
        this.filterTo = this.today;
        this.filter = "Today";
        this.filterDate = [];
        this.doGetLCGiCommission();
      }

      if (val == "This Week") {
        const startWeek = startOfWeek(new Date(this.today), { weekStartsOn: 1 }),
          endWeek = endOfWeek(new Date(this.today), { weekStartsOn: 1 });

        this.filterFrom = formatDatev2(startWeek, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endWeek, "format", "yyyy-MM-dd");
        this.filter = "This Week";
        this.filterDate = [];
        this.doGetLCGiCommission();
      }

      if (val == "This Month") {
        const startMonth = startOfMonth(new Date(this.today)),
          endMonth = endOfMonth(new Date(this.today));

        this.filterFrom = formatDatev2(startMonth, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endMonth, "format", "yyyy-MM-dd");
        this.filter = "This Month";
        this.filterDate = [];
        this.doGetLCGiCommission();
      }

      if (val == "This Year") {
        const startYear = startOfYear(new Date(this.today)),
          endYear = endOfYear(new Date(this.today));

        this.filterFrom = formatDatev2(startYear, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endYear, "format", "yyyy-MM-dd");
        this.filter = "This Year";
        this.filterDate = [];
        this.doGetLCGiCommission();
      }

      if (val == "Custom") {
        this.filterDialog = true;
      }
    },

    cancelFilter() {
      this.filterDialog = false;
    },

    saveFilter() {
      if (!this.filterDate.length) {
        // nothing to do
      } else {
        this.$refs.filterDialog.save(this.filterDate);

        const minDate = new Date(this.filterDate[0]);

        if (typeof this.filterDate[1] != "undefined") {
          const maxDate = new Date(this.filterDate[1]);

          if (minDate > maxDate) {
            this.filterFrom = formatDatev2(maxDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          } else {
            this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(maxDate, "format", "yyyy-MM-dd");
          }

          this.filterDateText =
            formatDatev2(this.filterFrom, "date") +
            " to " +
            formatDatev2(this.filterTo, "date");

          this.doGetLCGiCommission();
        } else {
          this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterDateText = formatDatev2(this.filterFrom, "date");
          this.doGetLCGiCommission();
        }

        this.filter = "Custom";
      }
    },
  },

  watch: {
    dates(val) {
      this.filterFrom = val.date_from
      this.filterTo = val.date_to

      this.doGetLCGiCommission()
    }
  }
}
</script>