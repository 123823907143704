import { format, getWeekOfMonth, parseISO } from "date-fns";

const getOrdinalNum = (n) =>
  n +
  (n > 0
    ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
    : "");

const formatDate = (date, type = "datetime", customFormat = "") => {
  if (date) {
    // Pag mga string yung date dapat naka parseISO
    // Example ng string '2022-01-01 10:00:00'
    // Example ng hindi string 'Wed Jun 29 2022 14:07:14 GMT+0800 (Philippine Standard Time)'

    if (type == "datetime") {
      return format(parseISO(date), "MMM dd, yyyy hh:mm a");
    }

    /* if (type=='datetimetraditional') {
      return format(parseISO(date), 'yyyy-MM-dd HH:mm:ss')
    }
 */
    if (type == "date") {
      return format(parseISO(date), "MMM dd, yyyy");
    }

    if (type == "date_normal") {
      return format(parseISO(date), "yyyy-MM-dd");
    }

    if (type == "monthweek") {
      return (
        format(parseISO(date), "MMM, yyyy") +
        " (" +
        getOrdinalNum(getWeekOfMonth(parseISO(date), { weekStartsOn: 2 })) +
        " week)"
      );
    }

    if (type == "monthyear") {
      return format(parseISO(date), "MMM, yyyy");
    }

    if (type == "format") {
      return format(date, customFormat);
    }

    if (type == "custom_date") {
      return format(parseISO(date), "MMM dd, yy");
    }
  } else {
    return "-";
  }
};

export default formatDate;
