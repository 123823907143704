<template>
  <v-container fluid>
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        Approve Payouts
      </v-card-title>

      <v-card-text class="d-flex flex-column" style="gap: 0.6rem">
        <v-row no-gutters dense>
          <v-col cols="12" md="3">
            <v-card class="shadow text-center">
              <span class="text-overline">Total For Approval</span>

              <v-divider class="mx-2" />

              <v-card-text class="py-4">
                <span style="font-size: 22px">{{
                  `Php ${money_format(parseFloat(total_for_approval))}`
                }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="payouts"
          :items-per-page="$vuetify.breakpoint.mdAndUp ? 10 : 5"
          :search="search"
          :loading="payouts_loading || appDataLoading"
          loading-text="Loading... Please wait"
          class="mt-4"
        >
          <template v-slot:top>
            <div class="d-flex justify-space-between" style="gap: 0.3rem">
              <v-text-field
                ref="search"
                v-model="search"
                label="Search"
                single-line
                outlined
                dense
                :disabled="payouts_loading"
                autofocus
                placeholder="Search"
              />

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    :color="filter_status ? 'primary' : '#424242'"
                    v-on="on"
                    class="mx-0 grey lighten-4"
                    @click="set_filter_dialog()"
                  >
                    <v-icon>{{
                      `mdi-${
                        filter_status ? "filter-check-outline" : "filter"
                      }`
                    }}</v-icon>
                  </v-btn>
                </template>
                Filter
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  class="mr-1"
                  @click="set_confirmation_dialog(item)"
                  :disabled="item.status == 'Approved'"
                >
                  <v-icon small> mdi-tag-outline </v-icon>
                </v-btn>
              </template>
              <span>Tag as approve</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <v-list class="py-0 my-0" style="background: transparent">
              <v-list-item>
                <v-list-item-avatar size="42px">
                  <v-icon dark color="grey" size="42px"> mdi-account-circle </v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            {{ `Php ${money_format(parseFloat(item.amount))}` }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              small
              class="font-weight-bold"
              :class="
                item.status == 'Approved'
                  ? 'green lighten-5 green--text'
                  : 'orange lighten-5 orange--text'
              "
            >
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.created_at) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <FilterDate
      v-if="filter_dialog"
      :filter_dialog_data="filter_dialog_data"
      @set_close_filter_dialog="set_close_filter_dialog"
    />
    <Confirmation
      v-if="confirmation_dialog"
      :confirmation_data="confirmation_data"
      @set_close_confirmation_dialog="set_close_confirmation_dialog"
    />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import formatDate from "@/helpers/formatDate";
import responseGet from "@/helpers/api_request_get";
import FilterDate from "@/components/app/approve_payouts/FilterDate";
import Confirmation from "@/components/app/approve_payouts/Confirmation";

export default {
  components: { FilterDate, Confirmation },

  data: () => ({
    payouts: [],
    search: "",
    payouts_loading: false,
    date_from: null,
    date_to: null,
    filter_dialog: false,
    filter_dialog_data: {},
    filter_status: false,
    query_filter: {},
    status: "Requested",
    confirmation_data: {},
    confirmation_dialog: false,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Player",
        value: "name",
        class: "text-uppercase",
      },
      {
        text: "Amount",
        value: "amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase",
      },
      {
        text: "Created Date",
        value: "created_at",
        class: "text-uppercase",
      },
    ],
    id: null,
  }),

  computed: {
    ...mapState(["appData", "appDataLoading"]),

    total_for_approval() {
      if (this.payouts) {
        return this.payouts.reduce(
          (total, obj) =>
            (obj.status == "Requested" ? parseFloat(obj.amount) : 0) + total,
          0
        );
      }

      return 0;
    },
  },

  mounted() {
    if (this.appData) {
      this.check_query_string();
      this.doGetPayouts();
    }
  },

  methods: {
    async doGetPayouts() {
      this.payouts_loading = true;

      const response = await responseGet(this.set_form_data_requested(), "payouts");

      if (response.status == 200) {
        this.payouts = response.data.records;
      }

      this.payouts_loading = false;
    },

    set_form_data_requested() {
      const form_data = new FormData();

      form_data.append("date_from", this.date_from ?? "");
      form_data.append("date_to", this.date_to ?? "");
      form_data.append("status", this.status);

      return new URLSearchParams(form_data);
    },

    set_confirmation_dialog(item) {
      this.confirmation_data = {
        id: item.id,
        investor_type_id: this.appData.account_details.investor_type_id,
        payout_id: item.payout_id,
        amount: item.amount,
      };

      this.confirmation_dialog = !this.confirmation_dialog;
    },

    set_close_confirmation_dialog(data) {
      if (Object.keys(data).length) {
        const selectedPayout = this.payouts.find((payout) => payout.id == data.id);

        selectedPayout.status = "Approved";
      }

      this.confirmation_dialog = !this.confirmation_dialog;
    },

    set_filter_dialog() {
      this.filter_dialog_data = {
        date_from: this.date_from,
        date_to: this.date_to,
        status: this.status,
      };

      this.filter_dialog = !this.filter_dialog;
    },

    set_close_filter_dialog(filter_data) {
      if (Object.keys(filter_data).length) {
        this.date_from = filter_data.date_from;
        this.date_to = filter_data.date_to;
        this.status = filter_data.status;

        this.doGetPayouts();

        this.set_query_string();
      }

      this.filter_dialog = !this.filter_dialog;
    },

    format_date(date) {
      return formatDate(date);
    },

    set_query_string() {
      const urlQuery = this.$route.query;
      this.query_filters = {};

      if (
        urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to ||
        urlQuery.status != this.status
      ) {
        if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
        if (this.date_to != null) this.query_filters["date_to"] = this.date_to;
        if (this.status != null) this.query_filters["status"] = this.status;

        this.$router.replace({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    check_query_string() {
      const urlQuery = this.$route.query;

      if (Object.keys(urlQuery).length) {
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
          this.date_from = urlQuery.date_from;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
          this.date_to = urlQuery.date_to;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "status"))
          this.status = urlQuery.status;
      }
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
  watch: {
    appData(val) {
      if (val) {
        this.check_query_string();
        this.doGetPayouts();
      }
    },
  },
};
</script>
