<template>
  <v-container fluid class="px-md-7">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        <span>Change Password</span>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters v-if="userData">
          <v-col cols="12" md="4">
            <v-form ref="form" class="d-flex flex-column" @submit.prevent="doChangePassword" style="gap: 1rem"
              :disabled="change_status || change_status_loading || parseInt(userData.sub_account_uid) != 0">
              <v-alert text v-if="alert_dialog" dense :color="change_status ? 'success' : 'error'" class="my-0">
                {{ alert_message }}
              </v-alert>

              <span>Enter your new password</span>

              <v-text-field ref="password" v-model="password"
                :rules="submit_status ? [rules.required, rules.minimum(6), is_password_matches] : []"
                :append-icon="password_visibility ? 'mdi-eye' : 'mdi-eye-off'"
                :type="password_visibility ? 'text' : 'password'" label="New Password" outlined dense
                @click:append="password_visibility = !password_visibility" hide-details="auto" />

              <v-text-field ref="confirm_password" v-model="confirm_password"
                :rules="submit_status ? [rules.required, rules.minimum(6), is_password_matches] : []"
                :append-icon="confirm_visibility ? 'mdi-eye' : 'mdi-eye-off'"
                :type="confirm_visibility ? 'text' : 'password'" label="Confirm New Password" outlined dense
                @click:append="confirm_visibility = !confirm_visibility" hide-details="auto" />

              <v-btn block depressed color="primary" type="submit" :loading="change_status_loading"
                :disabled="change_status || parseInt(userData.sub_account_uid) != 0">
                Reset Password
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import responsePost from '@/helpers/api_request_post'
import { mapState } from 'vuex';

export default {
  data: () => ({
    submit_status: false,
    alert_dialog: false,
    alert_message: '',
    change_status: false,
    change_status_loading: false,
    password_visibility: true,
    confirm_visibility: true,
    password: '',
    confirm_password: ''
  }),

  computed: {
    ...mapState(["userData"])
  },

  mounted() {
    if (this.userData.sub_account_uid) {
      this.alert_dialog = !this.alert_dialog
      this.alert_message = "This module is not available for sub users."
    }
  },

  methods: {
    async doChangePassword() {
      this.change_status_loading = !this.change_status_loading

      const response = await responsePost(this.set_form_data(), 'change_password')

      this.alert_dialog = true

      switch (response.status) {
        case 200:
          this.change_status = true
          this.alert_message = "Successfully changed"
          break
        case 401:
          localStorage.removeItem("userToken")
          this.$router.push({ name: "Home" })
          location.reload()
          break
        case 409:
          this.alert_message = response.data.messages.message
          break
        default:
          this.alert_message = "Something went wrong."
          break
      }

      this.change_status_loading = !this.change_status_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('password', this.password)
      form_data.append('confirm_password', this.confirm_password)

      return form_data
    }
  }
}
</script>