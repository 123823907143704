<template>
  <v-container fluid>
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-bold">
        Agents
      </v-card-title>

      <v-card-text class="d-flex flex-column">
        <v-data-table v-if="appData" :headers="get_headers()" :items="agents" :items-per-page="$vuetify.breakpoint.mdAndUp ? 10 : 5"
          :search="search" :loading="agents_loading || appDataLoading" loading-text="Loading... Please wait">

          <template v-slot:top>
            <v-text-field ref="search" v-model="search" label="Search" single-line outlined dense
              :disabled="agents_loading" autofocus placeholder="Search" />
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dense v-bind="attrs" v-on="on" icon small color="primary" class="mr-1"
                  :disabled="is_allow_to_add_token(item)" @click="set_add_token_dialog(item)">
                  <v-icon small> mdi-plus-circle-multiple </v-icon>
                </v-btn>
              </template>
              <span>Add Token</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <v-list class="py-0 my-0" style="background:transparent">
              <v-list-item>
                <v-list-item-avatar size="42px">
                  <v-icon dark color="grey" size="42px">
                    mdi-account-circle
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>

          <template v-slot:[`item.mobile_number`]="{ item }">
            {{ `+63${item.mobile_number}` }}
          </template>

          <template v-slot:[`item.commission`]="{ item }">
            <div class="d-flex justify-end align-center">
              <span class="mr-2">{{ `${item.commission}%` }}</span>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" depressed small outlined class="px-2" color="primary"
                    :disabled="is_allow_to_add_token(item)" @click="set_edit_commission_dialog(item)">
                    <span class=" mr-1">Edit</span>
                    <v-icon color="primary">mdi-pencil-box</v-icon>
                  </v-btn>
                </template>
                <span>Edit Commission</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.wallet`]="{ item }">
            <v-chip small
              :class="parseFloat(item.wallet) < 1000 ? 'red lighten-5 red--text text--lighten-1' : parseFloat(item.wallet) < 5000 ? 'orange lighten-5 orange--text text--lighten-1' : 'green lighten-5 green--text text--lighten-1'">
              {{ `Php ${money_format(parseFloat(item.wallet))}` }}
            </v-chip>
          </template>

          <template v-slot:[`item.sales_supervisor`]="{ item }">
            <a @click.prevent="set_accounts_dialog(1, item)" style="font-size: 13px;"
              v-if="parseInt(appData.account_details.investor_type_id) == 1"
              :disabled="!parseInt(item.sales_representativesales_representative)">{{
                `View (${number_format(parseInt(item.sales_supervisor))})` }}</a>
          </template>

          <template v-slot:[`item.sales_representative`]="{ item }">
            <span v-if="!parseInt(item.sales_representative)">0</span>
            <div v-else>
              <a @click.prevent="set_accounts_dialog(2, item)" style="font-size: 13px;" 
                :disabled="!parseInt(item.sales_representativesales_representative)">{{
                  `View (${number_format(parseInt(item.sales_representative))})` }}</a>
            </div>
          </template>

          <template v-slot:[`item.regular_player`]="{ item }">
            <span v-if="!parseInt(item.regular_player)">
              0
            </span>
            <div v-else>
              <a @click.prevent="set_accounts_dialog(3, item)" disabled style="font-size: 13px;"
                v-if="parseInt(appData.account_details.investor_type_id) != 3"
                :class="!parseInt(item.regular_player) ? 'disabled' : ''">{{
                  `View (${number_format(parseInt(item.regular_player))})` }}</a>
            </div>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip small class="font-weight-bold"
              :class="item.status == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
              {{ item.status }}
            </v-chip>
          </template>
        </v-data-table>

        <AddToken v-if="add_token_dialog" :account_details="account_details"
          @set_close_add_token_dialog="set_close_add_token_dialog" />
        <Accounts v-if="accounts_dialog" :accounts_data="accounts_data"
          @set_close_account_dialog="set_close_account_dialog" />
      </v-card-text>
    </v-card>

    <EditCommission v-if="edit_commission_dialog" :commission_data="edit_commission_data"
      @set_close_edit_commission_dialog="set_close_edit_commission_dialog" />
  </v-container>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import AddToken from '@/components/app/agents/AddToken'
import Accounts from '@/components/app/agents/Accounts'
import EditCommission from '@/components/app/agents/EditCommission'
import { mapState } from 'vuex'

export default {
  components: { AddToken, Accounts, EditCommission },

  data: () => ({
    agents: [],
    search: '',
    agents_loading: false,
    add_token_dialog: false,
    account_details: {},
    accounts_data: {},
    accounts_dialog: false,
    edit_commission_dialog: false,
    edit_commission_data: {}
  }),

  computed: {
    ...mapState(['appData', 'appDataLoading'])
  },

  mounted() {
    if (this.appData) this.doGetAgents()
  },

  methods: {
    async doGetAgents() {
      this.agents_loading = true

      const response = await responseGet(this.set_form_data(), 'agents')

      if (response.status == 200) {
        this.agents = response.data.records
      } else if (response.status == 401) {
        localStorage.removeItem("userToken")
        this.$router.push({ name: "Home" })
        location.reload()
      }

      this.agents_loading = false
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('investor_type_id', this.appData.account_details.investor_type_id)

      return new URLSearchParams(form_data)
    },

    is_allow_to_add_token(item) {
      switch (parseInt(this.appData.account_details.investor_type_id)) {
        case 1:
          if (parseInt(item.investor_type_id) == 2) return false
          return true
        case 2:
          if ((parseInt(item.investor_type_id) == 3 || parseInt(item.investor_type_id) == 2) && parseInt(item.investor_sub_type_id) == 1) return false
          return true
        case 3:
          if (parseInt(item.investor_sub_type_id) == 1) return false
          return true
        default:
          return false
      }
    },

    get_headers() {
      let headers = []

      switch (parseInt(this.appData.account_details.investor_type_id)) {
        case 1:
          headers = [
            {
              text: "Action",
              sortable: false,
              value: "action",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Agent",
              value: "name",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Mobile Number",
              value: "mobile_number",
              class: "text-uppercase",
            },
            {
              text: "Commission",
              value: "commission",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Token",
              value: "wallet",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Supervisor",
              value: "sales_supervisor",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Representative",
              value: "sales_representative",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Player",
              value: "regular_player",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Status",
              value: "status",
              class: "text-uppercase"
            },
          ]
          break
        case 2:
          headers = [
            {
              text: "Action",
              sortable: false,
              value: "action",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Agent",
              value: "name",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Mobile Number",
              value: "mobile_number",
              class: "text-uppercase",
            },
            {
              text: "Commission",
              value: "commission",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Token",
              value: "wallet",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Representative",
              value: "sales_representative",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Player",
              value: "regular_player",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Status",
              value: "status",
              class: "text-uppercase"
            },
          ]
          break
        case 3:
          headers = [
            {
              text: "Action",
              sortable: false,
              value: "action",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Agent",
              value: "name",
              class: "text-uppercase",
              align: "center",
            },
            {
              text: "Mobile Number",
              value: "mobile_number",
              class: "text-uppercase",
            },
            {
              text: "Commission",
              value: "commission",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Token",
              value: "wallet",
              class: "text-uppercase",
              align: "right"
            },
            {
              text: "Status",
              value: "status",
              class: "text-uppercase"
            },
          ]
          break
      }

      return headers
    },

    set_add_token_dialog(item) {
      this.account_details = item
      this.add_token_dialog = true
    },

    set_close_add_token_dialog(investor_data) {
      if (Object.keys(investor_data).length) {
        const investor = this.agents.find((agent) => agent.id == investor_data.investor_id);

        investor.wallet = parseFloat(investor.wallet) + parseFloat(investor_data.token);
      }
      this.add_token_dialog = false
    },

    set_accounts_dialog(type_id, item) {
      this.accounts_data = {
        id: item.id,
        access_type: type_id
      }
      this.accounts_dialog = !this.accounts_dialog
    },

    set_close_account_dialog() {
      this.accounts_dialog = !this.accounts_dialog
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },

    set_edit_commission_dialog(item) {
      this.edit_commission_data = item
      this.edit_commission_dialog = !this.edit_commission_dialog
    },

    set_close_edit_commission_dialog(updated_commission_data) {
      if (Object.keys(updated_commission_data).length) {
        const agent_data = this.agents.find((agent) => agent.id == updated_commission_data.id && agent.type == updated_commission_data.type);

        agent_data.commission = this.money_format(parseFloat(updated_commission_data.commission))
      }
      this.edit_commission_dialog = !this.edit_commission_dialog
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
  },

  watch: {
    appData(val) {
      if (val) {
        this.doGetAgents()
      }
    },
  }
}
</script>

<style scoped>
.disabled {
  cursor: not-allowed;
  color: gray
}
</style>
