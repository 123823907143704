<template>
  <v-dialog :persistent="request_loading || request_status" v-model="dialog" max-width="500">
    <v-card class="shadow">
      <v-card-title class="text-h6 font-weight-regular">
        Confirm
      </v-card-title>

      <v-card-text class="d-flex flex-column" style="gap:1rem">
        <v-alert v-if="alert_dialog" dense :class="request_status ? 'green lighten-5 green--text' : 'red lighten-5 red--text'" class="mb-0">
          {{ alert_message }}
        </v-alert>

        <span>
          You're about to request this commission, do you really want to proceed?
        </span>

        <v-simple-table>
          <tbody>
            <tr class="font-weight-bold">
              <td class="px-0">TOTAL</td>
              <td class="text-end px-0">{{ `Php ${data.commission}` }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-divider />
      </v-card-text>

      <v-card-actions>
        <div class="d-flex justify-end" style="gap:.30rem; width: 100%;">
          <v-btn depressed text color="grey" class="px-2" @click="close_dialog"
            :disabled="request_loading || request_status">
            Cancel
          </v-btn>

          <v-btn depressed color="primary" class="px-2" @click="doRequest" :loading="request_loading"
            :disabled="request_status">
            Request
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import responsePost from '@/helpers/api_request_post'

export default {
  props: {
    data: {
      type: Object
    }
  },

  data: () => ({
    request_status: false,
    request_loading: false,
    alert_dialog: false,
    alert_message: ''
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    async doRequest() {
      this.request_loading = !this.request_loading

      const response = await responsePost(this.set_form_data(), 'my_commission/request')
      this.alert_dialog = true

      switch (response.status) {
        case 200:
          this.request_status = true
          this.alert_message = "Successfully requested."
          break;
        case 409:
          this.alert_message = response.data.commission
          break;
        default:
          this.alert_message = "Failed to update"
          break;
      }

      this.request_loading = !this.request_loading
    },

    set_form_data() {
      const form_data = new FormData()

      // form_data.append('date_from', this.data.date_from)
      // form_data.append('date_to', this.data.date_to)
      form_data.append('total_commission', this.data.commission)
      form_data.append('dates', JSON.stringify(this.data.selected))

      return form_data
    },

    close_dialog() {
      this.$emit("set_close_confirmation_dialog", false)
    }
  },

  watch: {
    request_status(val) {
      if (val) {
        setTimeout(() => {
          this.$emit("set_close_confirmation_dialog", true)
        }, 2000);
      }
    }
  }
}
</script>