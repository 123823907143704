var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"1100"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"d-flex justify-space-between text-h6 font-weight-bold pa-2"},[_c('span',[_vm._v("Downline Commission")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close_dialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex flex-column pa-2",staticStyle:{"gap":".60rem"}},[(_vm.records)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"shadow px-1",staticStyle:{"width":"100%"}},[_c('v-card-title',{staticClass:"d-flex justify-center text-h6 font-weight-regular py-2"},[_c('span',{staticClass:"text-overline"},[_vm._v("TOTAL COMMISSION")])]),_c('v-divider',{staticClass:"my-1"}),_c('v-card-text',{staticClass:"d-flex justify-center px-2 py-4"},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(("Php " + (_vm.money_format(parseFloat(_vm.total_commission_amount))))))])])],1)],1)],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":_vm.$vuetify.breakpoint.mdAndUp ? 10 : 5,"search":_vm.search,"loading":_vm.records_loading,"item-key":((_vm.records.id) + "-" + (_vm.records.type)),"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{ref:"search",attrs:{"label":"Search","single-line":"","outlined":"","dense":"","disabled":_vm.records_loading,"autofocus":"","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("+63" + (item.mobile_number)))+" ")]}},{key:"item.bets",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.number_format(parseInt(item.bets)))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.amount)))))+" ")]}},{key:"item.commission_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.money_format(parseFloat(item.commission_percent))) + "%"))+" ")]}},{key:"item.commission_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.commission_amount)))))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",class:item.status == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }